import React, { useState } from 'react';
import { Button, Menu, Icon, Grid, Segment, Header, Tab, Form } from 'semantic-ui-react';
import DijfizetesiErtesitokComponent from './DijfizetesiErtesitokComponent'

/**
 * Hova bontsuk az adott osszeget?
 */
const BontasContainer = ({ total, dayOfPayment }) => {

    const [ertesitok, setErtesitok] = useState(0);
    const [egyenleg, setEgyenleg] = useState(0);
    const [vegrahajtas, setVegrahajtas] = useState(0);
    const [egyeb, setEgyeb] = useState(0);
    const [tetelek, setTetelek] = useState([]);

    const setVegrahajtasFt = () => {
        const subTotal = parseInt(document.getElementById('form.vegrehajtas').value);
        setVegrahajtas(subTotal);
        document.getElementById('form.vegrehajtas').value = '';
        setTetelek([...tetelek, { subTotal, name: 'Végrehajtási költség' }])
    }
    
    const setEgyenlegFt = () => {
        const subTotal = parseInt(document.getElementById('form.egyenleg').value);
        setEgyenleg(parseInt(document.getElementById('form.egyenleg').value));
        document.getElementById('form.egyenleg').value = '';
        setTetelek([...tetelek, { subTotal, name: 'Egyenleg (XXX felhasználó)' }])
    }    

    const setEgyebFt = () => {
        const subTotal = parseInt(document.getElementById('form.egyeb').value);
        setEgyeb(parseInt(document.getElementById('form.egyeb').value));
        document.getElementById('form.egyeb').value = '';
        setTetelek([...tetelek, { subTotal, name: 'Egyéb példa jogcím' }])
    }

    const panes = [
        {
            menuItem: <Menu.Item><span>Díjfizetési értesítőre</span></Menu.Item>,
            render: () => <DijfizetesiErtesitokComponent setTetelek={ setTetelek } tetelek={ tetelek } setErtesitok={ setErtesitok } />,
        },
        {
            menuItem: <Menu.Item><span> Egyenlegbe</span></Menu.Item>,
            render: () => <Segment style={{ height: '200px' }}>
                <Form>
                    <Form.Group>
                        <Form.Dropdown placeholder='felhasználó vagy háttérzene szolgáltató neve' selection label='Felhasználó / háttérzene szolgáltató' options={[{ key: 1, value: 1, text: 'Felhasználó 1'}, { key: 2, value: 2, text: 'Felhasználó 2'}]} />
                        <Form.Input id='form.egyenleg' placeholder='0' type='number' label='Egyenlegbe kerülő összeg' width={6} />
                        <span style={{ paddingTop: '32px'}}> Ft</span>
                    </Form.Group>
                    <Form.Input label='Megjegyzés' width={16} />
                    <Form.Button onClick={ (e) => { setEgyenlegFt(); e.preventDefault(); } } primary icon><Icon name='plus'/></Form.Button>
                </Form>
            </Segment>
        },
        {
            menuItem: <Menu.Item><span> Végrehajtási költségre</span></Menu.Item>,
            render: () => <Segment style={{ height: '200px' }}>
                  <Form>
                    <Form.Group>
                        <Form.Input id='form.vegrehajtas' placeholder='0' type='number' label='Végrehajtási költségként rögzített összeg' width={6} />
                        <span style={{ paddingTop: '32px'}}> Ft</span>
                    </Form.Group>
                    <Form.Input label='Megjegyzés' width={16} />
                    <Form.Button onClick={ (e) => { setVegrahajtasFt(); e.preventDefault(); } } primary icon><Icon name='plus'/></Form.Button>
                </Form>
            </Segment>
        },
        {
            menuItem: <Menu.Item><span> Egyéb jogcímre</span></Menu.Item>,
            render: () => <Segment style={{ height: '200px' }}>
                  <Form>
                    <Form.Group>
                        <Form.Dropdown placeholder='Válasszon jogcímet' selection label='Jogcím' options={[{ key: 1, value: 1, text: 'Példa jogcím 1'}, { key: 2, value: 2, text: 'Példa jogcím 2'}]} />
                        <Form.Input id='form.egyeb' placeholder='0' type='number' label='Összeg' width={6} />
                        <span style={{ paddingTop: '32px'}}> Ft</span>
                    </Form.Group>
                    <Form.Input label='Megjegyzés' width={16} />
                    <Form.Button onClick={ (e) => { setEgyebFt(); e.preventDefault(); } } primary icon><Icon name='plus'/></Form.Button>
                </Form>
            </Segment>
        },
    ]

    const summed = tetelek.map(item => item.subTotal).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0);

    return <>
        <Grid columns={2}>
            <Grid.Column width={11}>
                <Header size='large' style={{ marginBottom: '-8px', marginTop: '12px' }}>
                    Teljes összeg: { total } Ft
                </Header>
                <Header size='small'>Részösszegek</Header>
                <Tab menu={{ secondary: true, color: 'blue' }} panes={panes} style={{ marginTop: '0px' }} />
            </Grid.Column>
            <Grid.Column width={5}>
                <Header size='large' style={{ marginBottom: '-8px', marginTop: '12px' }}>
                    {
                        dayOfPayment === undefined
                        ?
                        <></>
                        :
                        <span style={{ color: '#aaa' }}>Befizetés napja: { dayOfPayment }</span>
                    }
                </Header>
                <Header size='small'>Rögzített tételek</Header>
                    {
                        tetelek.length === 0
                        ?
                        <center style={{ color: '#aaa' }}>Nincsen rögzített tétel.</center>
                        :
                        <>
                        {
                            tetelek.map( (item, index) => <Segment color='green' key={ index }>
                                <Button onClick={() => setTetelek([...tetelek.filter( (item, index2) => index !== index2 )]) } floated='right' icon><Icon name='trash' /></Button>
                                { item.name }
                                <br/>
                                <b>{ item.subTotal } Ft</b>
                            </Segment>)
                        }
                        </>
                    }
            </Grid.Column>
        </Grid>
        <Header size='small'>Tételek összesen</Header>
        <Header size='large' style={{ marginTop: '-8px' }}>         
            {
                <span style={{ color: total === undefined ? '#aaa' : summed > total.replace('.','') ? '#dd4433' : '#000' }}>
                    {
                        total !== undefined
                        ?
                        parseInt(summed) === parseInt(total.replace('.', ''))
                        ?
                        <Icon name='check' color='green'/>
                        :
                        ''
                        :
                        ''
                    }                       
                    { summed } Ft
                </span>
            }
        </Header>
    </>
}

export default BontasContainer;