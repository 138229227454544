import React from 'react';
import { Button, TextArea, Dropdown, Form, Grid, Header, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

/**
 * Kapcsolattartok
 */
const Szerzodes = () => {

    return <Segment basic>
        <h3><Icon name='handshake outline' /> Szerződés</h3>
        <Message warning><b><Icon name='lightbulb outline' />Nem rendelkezik szerződéssel</b>. A kedvezmények igénybevétele érdekében igényelje meg most.</Message>
        <p>
            Szerződés kötése esetén lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </p>
        <p>
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        </p>
        <p>
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        <Form>
            <Form.Group widths='equal'>
                <Form.Field
                    id='form-input-control-first-name'
                    control={Input}
                    label='Cég neve'
                    placeholder='Cég neve'
                />
                <Form.Field
                    id='form-input-control-last-name'
                    control={Input}
                    label='Adószáma'
                    placeholder='XXXXXX-XX'
                />
            </Form.Group>
            <Form.Group>
                <Form.Field
                    id='form-input-control-first-name'
                    control={Input}
                    label='Cég neve'
                    width={5}
                    placeholder='Város'
                />
                <Form.Field
                    id='form-input-control-last-name'
                    control={Input}
                    width={2}
                    label='Irányítószám'
                    placeholder='XXXX'
                />
                <Form.Field
                    id='form-input-control-last-name'
                    control={Input}
                    label='Utca, házszám'
                    width={9}
                    placeholder=''
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field
                    id='form-input-control-first-name'
                    control={Input}
                    label='Kapcsolattartó neve'
                    width={5}
                    placeholder=''
                />
                <Form.Field
                    id='form-input-control-first-name'
                    control={Input}
                    label='Kapcsolattartó telefonszáma'
                    width={5}
                    placeholder=''
                />                
                <Form.Field
                    id='form-input-control-error-email'
                    control={Input}
                    label='Kapcsolattartó e-mail címe'
                    placeholder='xxx@xxx.xx'
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field control={Input} label='Csatorna neve'></Form.Field>
                <Form.Field control={Input} label='Csatorna weboldala'></Form.Field>
            </Form.Group>
            <Form.Checkbox toggle label='Több csatornát adok meg' onClick={ () => alert('Mockup módban nem elérhető.') } />
            <Form.Field
                id='form-textarea-control-opinion'
                control={TextArea}
                label='Egyéb megjegyzés'
                placeholder='Egyéb megjegyzés'
            />
        </Form>
        <Segment placeholder>
            <Header icon>
                <Icon name='handshake outline' />
                Az alábbi gombra kattintva igényelheti szerződését, melyet aláírás előtt megküldünk önnek.
            </Header>
            <Button onClick={() => alert('Mockup módban nem elérhető')} primary>Szerződés igénylése</Button>
        </Segment>
    </Segment>
};

export default Szerzodes;