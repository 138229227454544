import React, { useState } from 'react';
import { Button, Segment, Header, Form, Icon, Accordion, Divider, Message, Table } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import NyugtaLista from '../szamlazas/subcomponents/NyugtaLista'
import TevekenysegekComponent from '../jatszasilistak/adatszolgaltatas/subcomponents/TevekenysegekComponent'
import KedvezmenyekComponent from '../jatszasilistak/adatszolgaltatas/subcomponents/KedvezmenyekComponent'

/**
 * Felhasznal (jog)utodlas.
 */
const FelhasznaloSuccession = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex);
    }

    return <Segment basic>
        <p>
            <Message info>
                <Icon name='info circle' />A folyamat során meghatározhatja, hogy a Felhasználó mely adatait migráljuk az új Felhasználó alá. <b>A művelet az értesítők és az adatszolgáltatási időszakok esetében mozgatás</b>, tehát a migrációra jelölt elemek a jelenlegi Felhasználó alól eltűnnek és az új Felhasználó alatt jelennek meg.
            </Message>
        </p>
        <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}><Icon name='dropdown' /> Cél Felhasználó kijelölése</Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <Form>
                    <Form.Dropdown width={6} placeholder='Cél felhasználó kiválasztása' selection options={[{key:1, value:1, text: 'Felhasználó 2'}]} label='Felhasználó neve' />
                </Form>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 1} index={1} onClick={handleClick}><Icon name='dropdown' /> Alapadatok</Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
                <Form>
                    <Form.Checkbox toggle label='Címek másolása'/>
                    <Form.Checkbox toggle label='Adminisztrátorok másolása'/>
                </Form>
            </Accordion.Content>            

            <Accordion.Title active={activeIndex === 2} index={2} onClick={handleClick}><Icon name='dropdown' /> Értesítők</Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
                <NyugtaLista/>
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 3} index={3} onClick={handleClick}><Icon name='dropdown' /> Adatszolgáltatási időszakok</Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
                <Form>
                    <Form.Checkbox toggle label='2022/Q2'/>
                    <Form.Checkbox toggle label='2022/Q1'/>
                    <Form.Checkbox toggle label='2021/Q4'/>
                    <Form.Checkbox toggle label='2021/Q3'/>
                    <Form.Checkbox toggle label='2021/Q2'/>
                    <Form.Checkbox toggle label='2021/Q1'/>
                </Form>                
            </Accordion.Content>

            <Accordion.Title active={activeIndex === 4} index={4} onClick={handleClick}><Icon name='dropdown' /> Jogdíj paraméterek</Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
                <TevekenysegekComponent/>
            </Accordion.Content>            

            <Accordion.Title active={activeIndex === 5} index={5} onClick={handleClick}><Icon name='dropdown' /> Kedvezmények</Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
                <KedvezmenyekComponent/>
            </Accordion.Content>                        

            <Accordion.Title active={activeIndex === 6} index={6} onClick={handleClick}><Icon name='dropdown' /> Összefoglalás</Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
                <Table striped inverted color='blue'>
                    <Table.Row>
                        <Table.Cell>Cél felhasználó</Table.Cell>
                        <Table.Cell><b>Felhasználó 2</b></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Másolt alapadatok</Table.Cell>
                        <Table.Cell><b>Címek</b><br/><b>Adminisztrátorok</b></Table.Cell>
                    </Table.Row>                    
                    <Table.Row>
                        <Table.Cell>Értesítők</Table.Cell>
                        <Table.Cell><b>2 db</b></Table.Cell>
                    </Table.Row>    
                    <Table.Row>
                        <Table.Cell>Jogdíj paraméterek</Table.Cell>
                        <Table.Cell><b>Meglevő beállítások másolása</b></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Kedvezmény paraméterek</Table.Cell>
                        <Table.Cell><b>Meglevő beállítások másolása</b></Table.Cell>
                    </Table.Row>                    
                    <Table.Row>
                        <Table.Cell>Adatszolgáltatási időszakok</Table.Cell>
                        <Table.Cell><b>2 db</b></Table.Cell>
                    </Table.Row>                                                            
                </Table>            
            </Accordion.Content>
                     
        </Accordion>
        <Divider/>
        <NavLink to='/sugarzasi/felhasznalok'>
            <Button disabled onClick={() => alert('Mockup verzióban nem elérhető.')} icon primary>Migráció</Button>
        </NavLink>
        <NavLink to='/sugarzasi/felhasznalok/1'>
            <Button floated='right'>Vissza</Button>
        </NavLink>
    </Segment>

};

export default FelhasznaloSuccession;