import React, { } from 'react';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Felhasznal megszuntetes.
 */
const FelhasznaloTermination = () => {

    return <>
        <Segment placeholder basic>
            <Header icon>
                <Icon name='user delete' color='red' />
                Felhasználó megszüntetése
            </Header>
        </Segment>
        <Segment basic>
            <p>
                <b>Biztosan megszűnteti a felhasználót?</b> A felhasználó adatait a rendszerből nem töröljük, azonban minden külső hozzáférését megszűntetjük. A megszüntetés emelt adminisztrátori jogkörrel visszavonható a későbbiekben.
                <br/>
                <br/>
                <Form>
                    <Form.Input width={2} placeholder='ÉÉÉÉ.HH.NN' label='Hatálybalépés napja'/>
                </Form>
            </p>
            <NavLink to='/sugarzasi/felhasznalok'>
                <Button onClick={() => alert('Mockup verzióban nem elérhető.')} icon primary>Igen, megerősítem a döntést</Button>
            </NavLink>
            <NavLink to='/sugarzasi/felhasznalok/1'>
                <Button floated='right'>Vissza</Button>
            </NavLink>
        </Segment>
    </>
};

export default FelhasznaloTermination;