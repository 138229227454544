import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Bank import lista history.
 */
const ImportHistory = () => {

    return <div style={{ marginTop: '-16px' }}>
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Dátum</Table.HeaderCell>
                    <Table.HeaderCell>Összeg</Table.HeaderCell>
                    <Table.HeaderCell>Partner</Table.HeaderCell>
                    <Table.HeaderCell>Partner bankszámlaszám</Table.HeaderCell>
                    <Table.HeaderCell>Közlemény</Table.HeaderCell>
                    <Table.HeaderCell>Státusz</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Dátum' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Összeg' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Parnter' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Partner bankszámlaszám' labeled selection fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Közlemény' labeled selection fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Státusz' labeled selection fluid />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            </Table.Body>
        </Table>
    </div>
}

export default ImportHistory;