import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';
import CimekComponent from './subcomponents/cimekComponent';
import SugarzasComponent from './subcomponents/sugarzasComponent';
import BeallitasokComponent from './subcomponents/beallitasokComponent';
import CsatolmanyokComponent from './subcomponents/csatolmanyokComponent';
import PenzgyukComponent from './subcomponents/penzugyComponent';
import AdminokComponent from './subcomponents/adminokComponent'
import CsatornaAdminokComponent from './subcomponents/csatornaAdminokComponent'

/**
 * Felhasznal adatlap.
 */
const FelhasznaloAdatlap = () => {

    const history = useHistory();

    const [channels, setChannels] = useState([{ name: 'Répa Rádió', web: 'repa.hu' }]);

    const panes = [
        {
            menuItem: <Menu.Item><Icon name='home' /> Címek & Kapcsolat</Menu.Item>,
            render: () => <CimekComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='users' /> Csatorna adminisztrátorok</Menu.Item>,
            render: () => <CsatornaAdminokComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='rss' /> Sugárzási jogdíj</Menu.Item>,
            render: () => <SugarzasComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='bars' /> Pénzügy</Menu.Item>,
            render: () => <PenzgyukComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='file alternate' /> Egyéb beállítás</Menu.Item>,
            render: () => <BeallitasokComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='paperclip' /> Csatolmányok</Menu.Item>,
            render: () => <CsatolmanyokComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='users' /> Adminisztrátorok</Menu.Item>,
            render: () => <AdminokComponent />,
        },
    ]

    const statuszok = [
        {
            key: 'Elfogadásra vár',
            text: 'Elfogadásra vár',
            value: 'Elfogadásra vár'
        },
        {
            key: 'Elutasítva',
            text: 'Elutasítva',
            value: 'Elutasítva'
        },
        {
            key: 'Elfogadva',
            text: 'Elfogadva',
            value: 'Elfogadva'
        },
    ]

    const options = [
        { key: 'addint', icon: 'calendar plus', text: 'Új időszak hozzáadása', value: 'addint' },
        { key: 'clone', icon: 'user plus', text: 'Migráció', value: 'clone' },
        { key: 'suspend', icon: 'user delete', text: 'Felfüggesztés', value: 'suspend' },
        { key: 'remove', icon: 'user delete', text: 'Megszüntetés', value: 'remove' },
    ]

    const openAdvancedScreen = (target) => {
        switch (target.textContent)
        {
            case 'Új időszak hozzáadása': history.push('/sugarzasi/felhasznalok/1/idoszak'); break;
            case 'Migráció': history.push('/sugarzasi/felhasznalok/1/succession'); break;
            case 'Felfüggesztés': history.push('/sugarzasi/felhasznalok/1/suspend'); break;
            case 'Megszüntetés': history.push('/sugarzasi/felhasznalok/1/termination'); break;
            default: break;
        }
    }

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='yellow'>
            <b><Icon name='clock outline' />Elfogadásra vár</b> a felhasználó, mint <b>Zöldség kft.</b>.
        </Segment>
        <Divider />
        <Grid style={{ paddingTop: '8px' }}>
            <Grid.Row style={{ marginBottom: '-8px' }}>
                <Grid.Column width={4}>
                    Státusz
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button.Group color='yellow' style={{ marginTop: '-8px' }} >
                        <Button compact>Elfogadásra vár</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={4}>
                    Szerződéses jogviszony
                </Grid.Column>
                <Grid.Column width={3}>
                    <Dropdown style={{ marginTop: '-8px' }} fluid selection defaultValue={1} options={[{ key: 1, value: 1, text: 'Nincs' }, { key: 2, value: 2, text: 'Van' }]} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    Felhasználó neve
                </Grid.Column>
                <Grid.Column width={4}>
                    <Input defaultValue='Zöldség Kft.' style={{ marginTop: '-8px' }} />
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={4}>
                    Felhasználás kezdete
                </Grid.Column>
                <Grid.Column width={3}>
                    <b>2022.05.01</b>
                </Grid.Column>
            </Grid.Row>
            {
                channels.length === 1
                    ?
                    <>
                    <h3 style={{ marginTop: '0px' }}>Csatorna</h3>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            Neve
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Input defaultValue={channels[0].name} style={{ marginTop: '-8px' }} />
                        </Grid.Column>
                        <Grid.Column width={1}>
                            Típusa
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Dropdown style={{ marginTop: '-12px' }} value={2} options={[{key:1,value:1,text:'TV'}, {key:2,value:2,text:'Rádió'}]} selection />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            Weboldal
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Input defaultValue={channels[0].web} style={{ marginTop: '-8px' }} />
                        </Grid.Column>
                    </Grid.Row>
                    </>
                    :
                    <>
                        <Grid.Row>
                            <Grid.Column style={{ marginTop: '-12px' }}>
                                <h3><Icon name='signal' /> Csatornák ({channels.length})</h3>
                            </Grid.Column>
                        </Grid.Row>
                        {
                            channels.map((channel, index) => <Grid.Row key={index}>
                                <Grid.Column width={2}>
                                    Neve
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Input defaultValue={channel.name} style={{ marginTop: '-8px' }} />
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    Típusa
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Dropdown style={{ marginTop: '-12px' }} value={2} options={[{key:1,value:1,text:'TV'}, {key:2,value:2,text:'Rádió'}]} selection />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    Weboldal
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Input defaultValue={channel.web} style={{ marginTop: '-8px' }} />
                                </Grid.Column>
                                <Grid.Column textAlign='right'>
                                    <Button icon color='red' onClick={() => setChannels([...channels.filter((item, index2) => index2 !== index)])}>
                                        <Icon name='delete' /></Button>
                                </Grid.Column>
                            </Grid.Row>)
                        }
                    </>
            }
        </Grid>
        <Divider />
        <Button icon onClick={() => setChannels([{ name: 'Új Csatorna' + channels.length, web: '-' }, ...channels])} primary><Icon name='plus' /> Új csatorna</Button>
        <Button.Group color='black' floated='right'>
            <Button>Speciális</Button>
            <Dropdown
                onChange={ (e) => openAdvancedScreen(e.target) }
                className='button icon'
                floating
                defaultValue={'0'}
                options={options}
                trigger={<></>}
            />
        </Button.Group>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: '18px' }} />
        <br />
        <NavLink to='/sugarzasi/felhasznalok'>
            <Button onClick={() => alert('Mockup verzióban nem elérhető.')} icon primary>Mentés</Button>
        </NavLink>
        <NavLink to='/sugarzasi/felhasznalok'>
            <Button floated='right'>Vissza</Button>
        </NavLink>
    </Segment>

};

export default FelhasznaloAdatlap;