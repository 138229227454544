import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Kotber adatlap.
 */
const JatszasiListaKotberAdatlap = () => {

    const [egyedi, setEgyedi] = useState(false);
    const [szamitasMutatas, setSzamitasMutatas] = useState(false);

    return <Segment>
        <Form>
            <Form.Field>
                <label>Feltöltési határidő</label>
                2022.03.01 <Label color='red' size='small' circular>!</Label>
            </Form.Field>
            <Form.Field>
                <label>Kötbér számításának első napja</label>
                2022.03.02
            </Form.Field>
            <Form.Field>
                <label>Kötbér kiállításának oka</label>
                <Form.Checkbox toggle label='Hibás teljesítés' />
                <Form.Checkbox toggle label='Késedelmes teljesítés' /> 
            </Form.Field>            
            <Form.Group>
                <Form.Input label='Kötbér számításának utolsó napja' width={3} defaultValue='2022.03.22' />
                <div style={{ marginTop: '32px' }} >
                    <Icon name='calendar outline' /> Hátramaradt kötbéres napok száma: <b>20 nap</b>.
                </div>
            </Form.Group>
        </Form>
        <Button onClick={() => setSzamitasMutatas(true)} secondary><Icon name='calculator' />Számítás</Button>
        <Divider />
        {
            !szamitasMutatas
                ?
                ''
                :
                <>
                    <Form>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Kamatszámítási időszak</label>
                                        2022.03.02 - 2022.03.22
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Kötbér / nap</label>
                                        1000 Ft
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Kötbéres napok száma</label>
                                        20 nap
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Kötbér</label>
                                        <h2 style={{ marginTop: '-0px' }}>20000 Ft</h2>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                    <Divider />
                    <Form>
                        <Form.Group>
                            <Form.Checkbox onChange={() => setEgyedi(!egyedi)} toggle label='Értesítő egyedi tárgya' />
                            <Form.Input width={12} style={{ marginTop: '-12px' }} value='Játszái lista kötbér értesítő' disabled={!egyedi} />
                        </Form.Group>
                        <Form.TextArea label='Megjegyzés' floated></Form.TextArea>

                    </Form>
                    <Divider />
                </>
        }
        <Button disabled={!szamitasMutatas} primary>Értesítő kiállítása</Button>
        <Button disabled={!szamitasMutatas} icon floated='right'><Icon name='file outline' /> Előnézet</Button>
    </Segment>
}

export default JatszasiListaKotberAdatlap;