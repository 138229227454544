import React, {  } from 'react';
import { Segment, Grid, Form } from 'semantic-ui-react';
import TevekenysegekComponent from '../../jatszasilistak/adatszolgaltatas/subcomponents/TevekenysegekComponent'

/**
 * Felhasznalo sugarzasi jogdijjal kapcsolatos adatlapja.
 */
const SugarzasComponent = () => {

    return <>
        <h3 style={{ marginTop: '0px' }}>Szerződés & Műsorszolgáltatás</h3>
        <Segment>
            <Grid columns={2}>
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Műsorszolgáltatás kezdete</label>
                            <Form.Group>
                                <Form.Input width={4} defaultValue='2022' />
                                <Form.Input width={2} defaultValue='02' />
                                <Form.Input width={2} defaultValue='17' />
                            </Form.Group>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Szerződés kelte</label>
                            <Form.Group>
                                <Form.Input width={4} defaultValue='2022' />
                                <Form.Input width={2} defaultValue='02' />
                                <Form.Input width={2} defaultValue='17' />
                            </Form.Group>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid>
        </Segment>
        <h3 style={{ marginTop: '0px' }}>Sugárzás</h3>
        <Segment>
            <TevekenysegekComponent />
            <br/>
            <Form>
                <Form.Input label='Megjegyzés' />
            </Form>
        </Segment>
    </>
};

export default SugarzasComponent;