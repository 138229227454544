import React, {  } from 'react';
import { Button, Table, Icon, Form } from 'semantic-ui-react';

/**
 * Felhasznalo csatolmanyok adatlap.
 */
const CsatolmanyokComponent = () => {

    return <>
            <Table selectable striped>
                <Table.Header>
                    <Table.HeaderCell>
                        Tárgy
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Dátum
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Szerződés
                    </Table.HeaderCell>                    
                    <Table.HeaderCell>
                        Fájl neve
                    </Table.HeaderCell>                    
                    <Table.HeaderCell textAlign='center'>
                        Letöltés
                    </Table.HeaderCell>                    
                </Table.Header>
                <Table.Body>
                    <Table.Row positive>
                        <Table.Cell>
                            Szerződés
                        </Table.Cell>
                        <Table.Cell>
                            2021.02.12 12:55:10
                        </Table.Cell>          
                        <Table.Cell>
                            <Icon name='check'/>
                        </Table.Cell>                                                              
                        <Table.Cell>
                            szerzodes_repa_1.docx
                        </Table.Cell>                                      
                        <Table.Cell textAlign='center'>
                            <Button primary icon><Icon name='file'/></Button>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Egyéni kérelem valamire
                        </Table.Cell>
                        <Table.Cell>
                            2021.02.12 12:15:10
                        </Table.Cell>          
                        <Table.Cell>
                        </Table.Cell>                                                              
                        <Table.Cell>
                            egyenikerelembelatol.pdf
                        </Table.Cell>                                      
                        <Table.Cell textAlign='center'>
                            <Button primary icon><Icon name='file'/></Button>
                        </Table.Cell>
                    </Table.Row> 
                    <Table.Row>
                        <Table.Cell>
                            Szerződés régi
                        </Table.Cell>
                        <Table.Cell>
                            2021.02.12 12:15:10
                        </Table.Cell>          
                        <Table.Cell>
                            <Icon name='check'/>
                        </Table.Cell>                                                              
                        <Table.Cell>
                            szerzodes_repa_0.docx
                        </Table.Cell>                                      
                        <Table.Cell textAlign='center'>
                            <Button primary icon><Icon name='file'/></Button>
                        </Table.Cell>
                    </Table.Row>                                       
                </Table.Body>
            </Table>
            <h3>Új csatolmány</h3>
            <Form>
                <Form.Group>
                    <Form.Input width={8} label='Tárgy'></Form.Input>
                    <Form.Input width={2} label='Dátum' placeholder='ÉÉÉÉ.HH.NN'></Form.Input>
                    <Form.Field>
                        <label>Dokumentum típus</label>
                        <Form.Checkbox style={{ paddingTop: '8px' }} toggle label='Szerződés'/>
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button icon>Fájl megadása</Button>
                </Form.Field>                
                <Form.Input width={16} label='Megjegyzés'></Form.Input>
                <Form.Button secondary>Csatolmány mentése</Form.Button>
            </Form>
    </>
};

export default CsatolmanyokComponent;