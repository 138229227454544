import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Hatterzeneszolgaltato jogdijai.
 */
const SzolgaltatoJogdijAdatlap = () => {

    return <Segment>
        Hiányzó jogdíj adatok.
    </Segment> 
}

export default SzolgaltatoJogdijAdatlap;
