import React, { } from 'react';
import { Segment, Form, Checkbox } from 'semantic-ui-react';

/**
 * Felhasznalo egyeb bellitasai adatlap.
 */
const BeallitasokComponent = () => {

    const kamatok = [
        { key: 1, value: 1, text: 'Jegybanki alapkamat kétszerese (5,4%)' },
        { key: 2, value: 2, text: 'Jegybanki alapkamat (2,7%)' },
        { key: 3, value: 3, text: 'Ptk. szerint' },
        { key: 4, value: 4, text: 'Egyedi fix kamat' },
    ]

    const afas = [
        { key: 1, value: 1, text: 'Alapértelmezett (27%)' },
        { key: 1, value: 1, text: 'Egyedi' },
    ]

    return <>
        <Segment>
            <Form>
                <Form.Checkbox toggle label='Láthatók előre az adatszolgáltatási időszakok' />
                <Form.Checkbox checked toggle label='Nincs automatikus díjszámítás' />
                <Form.Checkbox checked toggle label='Minimum jogdíj' />
                <Form.Checkbox toggle label='DJ fájltípus' />
                <h4>Kedvezményes minimum jogdíj</h4>
                <Form.Group unstackable widths={4}>
                    <Form.Checkbox checked toggle label='2013' />
                    <Form.Checkbox checked toggle label='2014' />
                    <Form.Checkbox checked toggle label='2015' />
                    <Form.Checkbox toggle label='2017' />
                </Form.Group>
                <Form.Group unstackable widths={4}>
                    <Form.Checkbox toggle label='2018' />
                    <Form.Checkbox toggle label='2019' />
                    <Form.Checkbox toggle label='2020' />
                    <Form.Checkbox checked toggle label='2021' />
                </Form.Group>                
            </Form>
        </Segment>
    </>
};

export default BeallitasokComponent;