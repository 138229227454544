import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Finalize import.
 */
const BankFinalizeImport = ({ setPage }) => {

    return <>
        <Table striped style={{ marginTop: '-16px' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>Típus</Table.HeaderCell>
                    <Table.HeaderCell>Partner</Table.HeaderCell>
                    <Table.HeaderCell>Összeg</Table.HeaderCell>
                    <Table.HeaderCell>Értesítő/jogcím</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row positive>
                    <Table.Cell>1.</Table.Cell>
                    <Table.Cell>Díjbekérő</Table.Cell>
                    <Table.Cell><b>Kiskörei Tározó kft.</b></Table.Cell>
                    <Table.Cell>127.000 Ft</Table.Cell>
                    <Table.Cell><b>PL-MAHASZ-2022-034</b></Table.Cell>
                </Table.Row>
                <Table.Row positive>
                    <Table.Cell>2.</Table.Cell>
                    <Table.Cell>Díjbekérő</Table.Cell>
                    <Table.Cell><b>Zöldség kft.</b></Table.Cell>
                    <Table.Cell>15.300</Table.Cell>
                    <Table.Cell><b>PL-MAHASZ-2022-22</b></Table.Cell>
                </Table.Row>
                <Table.Row warning>
                    <Table.Cell>3.</Table.Cell>
                    <Table.Cell>Egyéb jogcím</Table.Cell>
                    <Table.Cell><b>Mókus Egyesület</b></Table.Cell>
                    <Table.Cell>150.300</Table.Cell>
                    <Table.Cell><b>Végrehajtási költség</b></Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        Összesen <b>3</b> tétel
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
        <Segment basic style={{ marginTop: '-16px' }}>
            <Button onClick={() => alert('Mockup módban nem elérhető.')} primary>Tovább</Button>
            <Button floated='right' onClick={() => setPage(1)}>Vissza</Button>
        </Segment>
    </>
}

export default BankFinalizeImport;