import React, { useContext, useEffect, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Statistic, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import lightAsync from 'react-syntax-highlighter/dist/cjs/light-async';

/**
 * Jatszasi lista tartalom adatlap.
 */
const JatszasiListaTartalom = ({ error }) => {

    const [lista, setLista] = useState([
        ['Szécsi Pál', 'Szeretni bolondulásig', '', '', '3:33', 1, 'M', 'Rémségek kicsiny boltja', '', '2022.03.01 12:11:01', '', '','Zsófika szereti'],
        ['Szécsi Pál', 'Szeretni bolondulásig', '1975', '', '3:31', 1, 'M', 'Rémségek kicsiny boltja', '', '2022.03.02 15:51:00', '', '','Zsófika szereti'],
        ['Szécsi Pál', 'Szeretni bolondulásig', '1975', '', '!2:17:13', 1, 'M', 'Rémségek kicsiny boltja', '', '2022.03.03 11:11:20', '', '', 'Zsófika szereti'],
        ['Szécsi Pál', 'Szeretni bolondulásig', '1975', '', '3:13', 1, 'M', 'Rémségek kicsiny boltja', '', '2022.03.01 09:11:00', '', '','Zsófika utálja']
    ]);

    const [magyarToolboxVisible, setMagyarToolboxVisible] = useState(false);

    const lehetsegesMezokOptions = [
        { text: 'Vezető előadóművész neve', value: 1, key: 1 },
        { text: 'Hangfelvétel címe', value: 2, key: 2 },
        { text: 'Kiadó neve', value: 3, key: 3 },
        { text: 'Kiadás éve', value: 4, key: 4 },
        { text: 'Hangfelvétel hossza', value: 5, key: 5 },
        { text: 'Elhangzások száma', value: 6, key: 6 },
        { text: 'Magyar felvétel', value: 7, key: 7 },
        { text: 'Album', value: 8, key: 8 },
        { text: 'Szerző', value: 9, key: 9 },
        { text: 'Lejátszás időpontja', value: 10, key: 10 },
        { text: 'Megjegyzés', value: 11, key: 11 },
        { text: 'Közreműködő', value: 12, key: 12 },
        { text: 'ISMERETLEN OSZLOP', value: 13, key: 13 }
    ]

    const getPercentage = (index) => {
        let sum = 0;
        lista.forEach(item => item[index] !== '' ? sum++ : '');
        return Math.trunc((sum / lista.length) * 100);
    }

    const moveColumn = (index, step) => {

        let tempArray = [];

        lista.forEach(item => {
            const temp = item[index + step];
            item[index + step] = item[index]
            item[index] = temp;

            tempArray.push(item);
        });

        setLista(tempArray);
    }

    return <>
        <div style={{ width: '1280px', marginTop: '16px', overflowY: 'auto', marginBottom: '12px' }}>
            <Table size='small' selectable singleLine celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Vezető előadóművész neve <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Hangfelvétel címe <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Kiadó neve <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Kiadás éve <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Hangfelvétel hossza <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Elhangzások száma <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Magyar felvétel <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Album <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Szerző <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Lejátszás időpontja <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Megjegyzés <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>Közreműködő <Icon name='angle up' color='grey' /><Icon name='angle down' color='grey' /></Table.HeaderCell>
                        <Table.HeaderCell>ISMERETLEN OSZLOP </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' defaultValue='Szécsi Pál' placeholder='Név vagy névtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Cím vagy címtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Név vagy névtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Dátum vagy dátumtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Szám vagy idő' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Szám' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='' action={<Button onClick={ () => setMagyarToolboxVisible(!magyarToolboxVisible) } primary icon compact><Icon name='wrench' /></Button>} />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Név vagy névtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Név vagy névtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Idő' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Szöveg' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input fluid icon='filter' iconPosition='left' placeholder='Név vagy névtöredék' />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }} textAlign='center'>
                            Forrás: <i>FurcsaOszlop</i>
                        </Table.HeaderCell>                        
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(0) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' disabled onClick={() => moveColumn(0, 0)} />
                                {getPercentage(0)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(0, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(1) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(1, -1)} />
                                {getPercentage(1)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(1, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(2) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(2, -1)} />
                                {getPercentage(2)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(2, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(3) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(3, -1)} />
                                {getPercentage(3)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(3, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(4) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(4, -1)} />
                                {getPercentage(4)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(4, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(5) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(5, -1)} />
                                {getPercentage(5)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(5, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(6) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(6, -1)} />
                                {getPercentage(6)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(6, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(7) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(7, -1)} />
                                {getPercentage(7)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(7, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(8) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(8, -1)} />
                                {getPercentage(8)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(8, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(9) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(9, -1)} />
                                {getPercentage(9)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(9, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(10) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(10, -1)} />
                                {getPercentage(10)}% &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(10, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color={getPercentage(11) === 100 ? 'green' : 'red'} style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(11, -1)} />
                                {getPercentage(11)} &nbsp;
                                <Icon name='chevron right' onClick={() => moveColumn(11, 1)} />
                            </Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Label basic color='grey' style={{ width: '100%', textAlign: 'center' }}>
                                <Icon name='chevron left' onClick={() => moveColumn(12, -1)} />
                                <Icon name='trash' onClick={ () => alert('Oszlop törlése mockup módban nem elérhető.') } />
                                <Icon name='chevron right' onClick={() => moveColumn(12, 1)} />
                            </Label>
                        </Table.HeaderCell>                        
                    </Table.Row>
                    {
                                magyarToolboxVisible
                                ?
                                <Table.Row>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell>
                                        <Form>
                                            <Form.Button fluid><Icon name='wrench' />Minden M magyar</Form.Button>
                                            <Form.Button fluid><Icon name='wrench' />Minden nem üres magyar</Form.Button>
                                            <Form.Button fluid><Icon name='wrench' />Minden K külföldi</Form.Button>
                                        </Form>
                                    </Table.Cell>
                                </Table.Row>
                                :
                                ''
                            }                    
                </Table.Header>
                <Table.Body>
                    {
                        lista.map(item => <Table.Row positive={ !item[4].startsWith('!') } negative={ item[4].startsWith('!') }>
                            <Table.Cell>
                                {item[0]}
                            </Table.Cell>
                            <Table.Cell>{item[1]}</Table.Cell>
                            <Table.Cell>{item[2]}</Table.Cell>
                            <Table.Cell>{item[3]}</Table.Cell>
                            <Table.Cell>
                                {
                                    item[4].startsWith('!')
                                        ?
                                        <span>
                                            <Icon style={{ marginRight: '8px' }} color='yellow' name='warning sign' />
                                        </span>
                                        :
                                        ''
                                }
                                {item[4]}
                            </Table.Cell>
                            <Table.Cell>{item[5]}</Table.Cell>
                            <Table.Cell>{item[6]}</Table.Cell>
                            <Table.Cell>{item[7]}</Table.Cell>
                            <Table.Cell>{item[8]}</Table.Cell>
                            <Table.Cell>{item[9]}</Table.Cell>
                            <Table.Cell>{item[10]}</Table.Cell>
                            <Table.Cell>{item[11]}</Table.Cell>
                            <Table.Cell>{item[12]}</Table.Cell>
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
        </div>
        <Button icon><Icon name='fast backward' /></Button>
        <Button icon><Icon name='backward' /></Button>
        &nbsp;
        <b>1000</b> sor
        &nbsp;
        <Button icon><Icon name='fast forward' /></Button>
        <Button icon><Icon name='forward' /></Button>
        <span style={{ marginLeft: '30%' }}>Összesen <b>12,124</b> sor</span>
    </>
};

export default JatszasiListaTartalom;