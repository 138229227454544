import React, { useState } from 'react';
import { Button, Tab, Menu, Label, Icon, Dropdown, Popup, Grid, Segment, Message, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import MainContent from './subcomponents/MainContent'

/**
 * Jatszasi lista adatlap, de hibas.
 */
const FeltoltottJatszasiListaAdatlapAutomataHibas = () => {

    // Editabe?
    const [isEditable, setIsEditable] = useState(false);

    // Wizard step
    const [step, setStep] = useState(2);

    // Visible list?
    const [isListVisible, setIsListVisible] = useState(true);
    
    // Tab
    const [activeIndex, setActiveIndex] = useState(1);

    const panes = [
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='red' basic={ activeIndex !== 1 }>Aut. hibás</Label>&nbsp; Répa Rádió</span></Menu.Item>, render: () => <Tab.Pane><MainContent  step={ step } setStep={ setStep } error={ true } activeIndex={ activeIndex } /></Tab.Pane> },
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='yellow' basic={ activeIndex !== 2 }>Feldolgozás alatt</Label>&nbsp; Kukorica Jancsi Rádió</span></Menu.Item>, render: () => <Tab.Pane><MainContent  step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> },
    ]

    // Possible statuses
    const statuszok = [
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
    ]

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='red'>
            <b><Icon name='delete' />Hibás</b> játszási lista Zöldség kft. <b>2022 / 1.</b> negyedévre sugárzási jogdíjára.
        </Segment>
        <Divider/>
        <Grid columns={5}>
            <Grid.Row style={{ marginBottom: '-8px' }}>
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='red' style={{ marginTop: '-8px' }}>
                        <Button disabled={ !isEditable } compact>Aut. hibás</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            disabled={ !isEditable } 
                            onChange={() => { alert('Mockup módban nem elérhető.') }}
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                    {
                        isEditable
                        ?
                        ''
                        :
                        <Popup trigger={ <Button icon onClick={ () => setIsEditable(true) }><Icon name='undo' /></Button> } content='Kiemelt adminisztrátori szerepkörre lehetséges a "Hibás" státuszra lezárt állapotok megváltoztatása.' />
                    }    
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Feltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>            
            <Grid.Row>
                <Grid.Column>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <b>Zöldség kft.</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Adminisztrátor
                </Grid.Column>
                <Grid.Column>
                    <b>Nagy János</b>
                    <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='exchange' /></Button>} content='Adminisztrátor cseréje' />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó playlist azonosító
                </Grid.Column>
                <Grid.Column>
                    <b>1</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Kapcsolattartó
                </Grid.Column>
                <Grid.Column>
                    <b>Kovács Rozmaringné</b>
                    <Button.Group>
                        <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
                        <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={ <span>Telefon: <b>06 1 555 5555</b></span> } />
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>   
        </Grid>
        <Divider/>
        <Tab onTabChange={ (e, data) => setActiveIndex(data.activeIndex + 1) } panes={panes} />
        <Divider />
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'/> Játszási lista letöltése</Button>
        <NavLink to='/jatszasilistak/naplo'><Button primary icon><Icon name='history'/></Button></NavLink>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='upload'/> Játszási lista visszatöltése</Button>        
        <NavLink to='/jatszasilistak'><Button floated='right'>Vissza</Button></NavLink>
        <NavLink to='/jatszasilistak/naplo'><Button floated='right' icon><Icon name='history' /> Napló</Button></NavLink>        
        <br/>
        <br/>
    </Segment>

};

export default FeltoltottJatszasiListaAdatlapAutomataHibas;