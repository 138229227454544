import ProfileReducer from './ProfileReducer';

/**
 * The main reducer, collector of all other reducers. Entry point.
 * @param {*} state 
 * @param {*} action 
 */
const MainReducer = ((state, action) => {

    // Log PREVIOUS state
    console.log(((new Date()).toLocaleTimeString()) + ': %cPrevious state', 'background: #222; color: #88adad', state);

    const nextState = {
        profile: ProfileReducer(state.profile, action)
    };

    // Log NEXT state
    console.log(((new Date()).toLocaleTimeString()) + ': Action', action);
    console.log(((new Date()).toLocaleTimeString()) + ': %cNext state', 'background: #222; color: #bada55', nextState);
    console.log('');

    return nextState;
});

export default MainReducer;