import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import BontasContainer from '../../jatszasilistak/folyoszamla/subcomponents/BontasContainer'
/**
 * Divide fees before final import.
 */
const BankTetelFelosztas = ({ total }) => {

    return <>
        <BontasContainer total={ total }/>
    </>
}

export default BankTetelFelosztas;