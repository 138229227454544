import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Tevekenysegek komponens.
 */
const TevekenysegekComponent = ({ showWarning }) => {

    const [interaktiv, setInteraktiv] = useState(false);

    const opciok = [
        {
            key: '50% felett',
            text: '50% felett',
            value: '50% felett'
        },
        {
            key: '50%-30%',
            text: '50%-30%',
            value: '50%-30%'
        },
        {
            key: '30%-10%',
            text: '30%-10%',
            value: '30%-10%'
        },
        {
            key: '10%-0%',
            text: '10%-0%',
            value: '10%-0%'
        },
    ]

    const csatornaszam = [
        {
            key: '1',
            text: '1',
            value: '1'
        },
        {
            key: '2',
            text: '2',
            value: '2'
        },
        {
            key: '3',
            text: '3',
            value: '3'
        },
    ]

    return <>
        {
            showWarning
                ?
                <Message warning>
                    <Icon name='warning sign' />Az előző negyedévhez képest a felhasználó <b>most nem jelölte meg</b> a <b>simulcast</b> tevékenységet.
                </Message>
                :
                ''
        }
        <Grid>
            <Grid.Row columns={3} >
                <Grid.Column>
                    <Form>
                        <Form.Checkbox toggle defaultChecked label='Sugárzás' />
                        <Form.Checkbox toggle label='Webcast' />
                        <Form.Checkbox toggle label='Simulcast' />
                        <Form.Checkbox onChange={() => setInteraktiv(!interaktiv)} toggle label='Interaktív webcast' />
                        <Form.Checkbox style={{ paddingLeft: '62px' }} disabled={!interaktiv} toggle label='Műfajok preferálása' />
                        <Form.Checkbox style={{ paddingLeft: '62px' }} disabled={!interaktiv} toggle label='Előadók preferálása' />
                        <Form.Checkbox style={{ paddingLeft: '62px' }} disabled={!interaktiv} toggle label='Hangfelvételek preferálása' />
                        <Form.Checkbox style={{ paddingLeft: '62px' }} disabled={!interaktiv} toggle label='"Pause" funkció' />
                        <Form.Checkbox style={{ paddingLeft: '62px' }} disabled={!interaktiv} toggle label='"Skip" funkció' />
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Form>
                        <Form.Checkbox toggle label='Archívumát hozzáférhetővé teszi' />
                        <Form.Checkbox toggle label='Archívumát letölthetővé teszi' />
                        <Form.Checkbox toggle disabled label='A hangfelvételt egy honlap háttérzenéjeként használja' />
                        <Form.Checkbox toggle label='Más rádió/televízió műsorának egyidejű továbbközvetítését végzi' />
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Form>
                        <Form.Dropdown search selection defaultValue='50% felett' options={opciok} label='Hangfelvételek aránya a teljes műsoridőhöz viszonyítva' />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </>
};

export default TevekenysegekComponent;