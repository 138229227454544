import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Eloleg ertesito komponens.
 */
const ElolegErtesitoAdatlap = () => {

    const [egyedi, setEgyedi] = useState(false);

    return <Segment>
        <Form>
            <Form.Group>
                <Form.Input focus 
                            id='eloleg'
                            type='number' 
                            defaultValue={0} 
                            label='Előleg értesítő összeg (Ft + ÁFA)' 
                            min={0}
                            action={ <Button onClick={ () => document.getElementById('eloleg').value = 254000 }><Icon name='angle double left' /> Automata számítás szerint 254.000 Ft + ÁFA</Button> } />
            </Form.Group>
            <Form.Checkbox defaultChecked={ egyedi } toggle label='Értesítő egyedi tárgya' onChange={ () => setEgyedi(!egyedi) } />
            <Form.Input label='Értesítő tárgya' placeholder='Adja meg az értesítő egyedi tárgyát' disabled={ !egyedi }></Form.Input>
        </Form>
        <Divider/>
        <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } primary>Értesítő kiállítása</Button>
        <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } floated='right'><Icon name='file outline'></Icon>Előnézet</Button>
    </Segment>
}

export default ElolegErtesitoAdatlap;