import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Statistic, Tab, List } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Jatszasi lista adatlap statisztika.
 */
const FeltetlenElutasitasHibas = () => <>
    <Table>
        <Table.Header>
            <Table.HeaderCell width={10}>Vizsgálat tárgya</Table.HeaderCell>
            <Table.HeaderCell width={2}>Érték</Table.HeaderCell>
            <Table.HeaderCell width={2}>Arány</Table.HeaderCell>
            <Table.HeaderCell width={2}>Darabszám</Table.HeaderCell>
        </Table.Header>
        <Table.Row positive>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>vezetőelőadó</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>
        <Table.Row positive>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>magyar hangfelvétel</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                1
            </Table.Cell>
        </Table.Row>
        <Table.Row positive>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>lejátszás</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>
        <Table.Row negative>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>kiadó neve</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                56%
            </Table.Cell>
            <Table.Cell>
                145
            </Table.Cell>
        </Table.Row>
        <Table.Row positive>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>hangfelvétel hossza</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                24
            </Table.Cell>
        </Table.Row>
        <Table.Row positive>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>hangfelvétel címe</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                24
            </Table.Cell>
        </Table.Row>
        <Table.Row positive>
            <Table.Cell>
                Nem azonosítható tag az adatok között: <b>elhangzások száma</b>
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                2
            </Table.Cell>
        </Table.Row>
    </Table>
</>

export default FeltetlenElutasitasHibas;