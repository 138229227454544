import React from 'react';
import { Button, Divider, Dropdown, Grid, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

/**
 * Kapcsolattartok
 */
const Kapcsolatarttok = () => {

    return <Segment basic>
        <h3><Icon name='address card outline' /> Kapcsolattartók</h3>
        <Segment>
            Az ön kapcsolattartója <b>általános ügyekben</b>
            <br/>
            <br/>
            <Grid columns={'equal'} stackable>
                <Grid.Row>
                    <Grid.Column>
                        Név
                    </Grid.Column>
                    <Grid.Column>
                        <b>Empatikus Jolán</b>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                        E-mail
                    </Grid.Column>
                    <Grid.Column>
                        <b>joli.empatika@eji.hu</b>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>                    
                    <Grid.Column>
                        Telefonszám
                    </Grid.Column>
                    <Grid.Column>
                        <b>06 1 555 5555</b>
                    </Grid.Column>                    
                </Grid.Row>
            </Grid>
        </Segment>
        <Segment>
            Az ön kapcsolattartója <b>jogdíj ügyekben</b>
            <br/>
            <br/>
            <Grid columns={'equal'} stackable>
                <Grid.Row>
                    <Grid.Column>
                        Név
                    </Grid.Column>
                    <Grid.Column>
                        <b>S. Stalloné</b>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                        E-mail
                    </Grid.Column>
                    <Grid.Column>
                        <b>stallone.s@eji.hu</b>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>                    
                    <Grid.Column>
                        Telefonszám
                    </Grid.Column>
                    <Grid.Column>
                        <b>06 1 555 5555</b>
                    </Grid.Column>                    
                </Grid.Row>
            </Grid>
        </Segment>        
    </Segment>
};

export default Kapcsolatarttok;