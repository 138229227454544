import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Statistic, Tab, List } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Jatszasi lista adatlap.
 */
const Statisztika = () => <>
    <Table>
        <Table.Header>
            <Table.HeaderCell width={10}>Vizsgálat tárgya</Table.HeaderCell>
            <Table.HeaderCell width={2}>Érték</Table.HeaderCell>
            <Table.HeaderCell width={2}>Arány</Table.HeaderCell>
            <Table.HeaderCell width={2}>Darabszám</Table.HeaderCell>
        </Table.Header>
        <Table.Row positive>
            <Table.Cell>
                Összes lejátszás hossza (elhangzások számával szorozva)
            </Table.Cell>
            <Table.Cell>
                13.231.222 mp.
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
        </Table.Row>        
        <Table.Row positive>
            <Table.Cell>
                Megjegyzés mező 5 leggyakrabban előforduló értéke
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>        
        <Table.Row positive>
            <Table.Cell>
                Magyar felvétel, összes mezőszám
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>
        <Table.Row positive>
            <Table.Cell>
                Lejátszások, összes mezőszám
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>    
        <Table.Row positive>
            <Table.Cell>
                Lájátszás időpontja, összes mezőszám
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>     
        <Table.Row positive>
            <Table.Cell>
                Lájátszás hossza, összes mezőszám
            </Table.Cell>
            <Table.Cell>
                -
            </Table.Cell>
            <Table.Cell>
                100%
            </Table.Cell>
            <Table.Cell>
                245
            </Table.Cell>
        </Table.Row>                    
    </Table>
</>

export default Statisztika;