import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, List, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Jogdij ertesito komponens.
 */
const JogdijErtesitoAdatlap = () => {

    const [egyedi, setEgyedi] = useState(false);

    const options = [
        { key: 1, text: 'Sugárzási jogdíj', value: 1 },
        { key: 2, text: 'Simulcast jogdíj', value: 2 },
        { key: 3, text: 'Webcast jogdíj', value: 3 },
        { key: 4, text: 'Interaktív webcast jogdíj', value: 4 },
        { key: 5, text: 'Archívum jogdíj', value: 5 },
        { key: 6, text: 'Archívum letöltés jogdíj', value: 6 },
        { key: 7, text: 'Webes háttérzene jogdíj', value: 7 },
        { key: 8, text: 'Egyéb jogdíj', value: 8 },
    ]

    const options2 = [
        { key: 1, text: 'Magyar felvétel arány után', value: 1 },
        { key: 2, text: 'Magyar felvétel kiadási éve után', value: 2 },
        { key: 3, text: 'Magyar felvétel sugárzási időpont után', value: 3 },
        { key: 4, text: 'Egyedi', value: 4 },
    ]

    const [dijak, setDijak] = useState([]);
    const [kedvezmenyek, setKedvezmenyek] = useState([]);
    const [dijtipus, setDijtipus] = useState();
    const [kedvezmenytipus, setKedvezmenytipus] = useState();
    const [eloreFizetes, setEloreFizetes] = useState(false);

    const addDij = () => {

        if (dijak.filter(item => item.dijtipus === dijtipus).length > 0) {
            alert('Ez a díjtípus már rögzítve van, törölje először a meg felvett díjak listájából.');
        }
        else {
            const dij = document.getElementById('dij').value;
            if (dij === '' || dijtipus === undefined) {
                alert('Összeg és díjtípu megadása kötelező.')
            }
            else {
                setDijak([...dijak, { dijtipus, dij: parseInt(dij) }]);
                document.getElementById('dij').value = '';
            }
        }
    }

    const addKedvezmeny = () => {

        if (kedvezmenyek.filter(item => item.kedvezmenytipus === kedvezmenytipus).length > 0) {
            alert('Ez a kedvezménytípus már rögzítve van, törölje először a meg felvett kedvezmények listájából.');
        }
        else {
            const kedvezmeny = document.getElementById('kedvezmeny').value;
            if (kedvezmeny === '' || kedvezmenytipus === undefined) {
                alert('Összeg és kedvezménytípus megadása kötelező.')
            }
            else if (kedvezmenytipus === 4) {
                alert('TODO Lehetőséget kell adni az egyedi kedvezmény jogcímének megadásásra.');
            }
            else {
                setKedvezmenyek([...kedvezmenyek, { kedvezmenytipus, kedvezmeny: parseInt(kedvezmeny) }]);
                document.getElementById('kedvezmeny').value = '';
            }
        }
    }

    const removeKedvezmeny = (kedvezmenytipus) => {
        setKedvezmenyek([...kedvezmenyek.filter(item => item.kedvezmenytipus !== kedvezmenytipus)]);
    }

    const removeDij = (dijtipus) => {
        setDijak([...dijak.filter(item => item.dijtipus !== dijtipus)]);
    }

    // Sum
    const osszesen = dijak.reduce((accumulator, item) => accumulator + parseInt(item.dij), 0) - kedvezmenyek.reduce((accumulator, item) => accumulator + parseInt(item.kedvezmeny), 0);

    return <Segment>
        <Label color='green'><Icon name='check' />Nincs aktív értesítő</Label>
        <Label color='blue'><Icon name='warning sign' />Egyenlegben 12.700 Ft</Label>
        <Label color='yellow'><Icon name='warning sign' />Számviteli beszámoló nem érkezett meg</Label>
        <Label color='yellow'><Icon name='warning sign' />Automatikus díjszámítás nem lehetséges</Label>
        <Divider />

        <Grid>
            <Grid.Column width={4}>
                <Header size='small'>Díjak</Header>
                <Segment color='blue'>
                    <Header size='small'>Díjszámítási információk</Header>
                    <List>
                        <List.Item>
                            <List.Header>Kereskedelmi bevétel</List.Header>
                            520.000 Ft
                        </List.Item>
                        <List.Item>
                            <List.Header>Kábel TV bevétel</List.Header>
                            125.000 Ft
                        </List.Item>
                        <List.Item>
                            <List.Header>Közszolgálati hozzájárulás</List.Header>
                            520.000 Ft
                        </List.Item>
                        <List.Item>
                            <List.Header>Egyéb támogatás</List.Header>
                            0 Ft
                        </List.Item>
                    </List>
                    <Header size='small'>Tevékenységek</Header>
                    <List>
                        <List.Item>
                            Sugárzás
                        </List.Item>
                        <List.Item>
                            Webcast
                        </List.Item>
                    </List>
                </Segment>
            </Grid.Column>
            <Grid.Column width={7}>
                <Header size='small'>&nbsp;</Header>
                <Form>
                    <Form.Group>
                        <Form.Dropdown onChange={(e, { value }) => setDijtipus(value)} placeholder='Válasszon díjtípust' options={options} selection />
                        <Form.Input width={6} min={0} id='dij' type='number' placeholder='0' /><span style={{ marginTop: '8px' }}> Ft + ÁFA </span>
                        <Form.Button onClick={addDij} primary icon><Icon name='plus' /></Form.Button>
                    </Form.Group>
                </Form>
            </Grid.Column>
            <Grid.Column width={5}>
                <Header size='small'>Rögzített tételek</Header>
                {
                    dijak.length === 0
                        ?
                        <center style={{ color: '#aaa', marginTop: '8px' }}>Nincsen díj felvéve.</center>
                        :
                        dijak.map((item) => <Segment color='green'>
                            <Button onClick={() => removeDij(item.dijtipus)} floated='right' icon><Icon name='trash' /></Button>
                            <b>{options.filter(d => d.value === item.dijtipus)[0].text}</b>
                            <p>
                                {item.dij} Ft + 27% ÁFA
                                <br />
                                <b>{item.dij * 1.27} Ft</b> br.
                            </p>
                        </Segment>)
                }
            </Grid.Column>
        </Grid>
        <Grid>
            <Grid.Column width={4}>
                <Header size='small'>Kedvezmények</Header>
                <Segment color='yellow'>
                    <Header size='small'>Kedvezmények</Header>
                    <List>
                        <List.Item>
                            <List.Header>Magyar felvételek aránya</List.Header>
                            65%
                        </List.Item>
                        <List.Item>
                            Magyar felvételek kiadási éve utáni kedvezmény
                        </List.Item>
                    </List>
                </Segment>
            </Grid.Column>
            <Grid.Column width={7}>
                <Header size='small'>&nbsp;</Header>
                <Form>
                    <Form.Group>
                        <Form.Dropdown onChange={(e, { value }) => setKedvezmenytipus(value)} placeholder='Válasszon kedvezményt' options={options2} selection />
                        <Form.Input width={6} min={0} id='kedvezmeny' type='number' placeholder='0' /><span style={{ marginTop: '8px' }}> Ft + ÁFA </span>
                        <Form.Button onClick={addKedvezmeny} primary icon><Icon name='plus' /></Form.Button>
                    </Form.Group>
                </Form>
            </Grid.Column>
            <Grid.Column width={5}>
                <Header size='small'>&nbsp;</Header>
                {
                    kedvezmenyek.length === 0
                        ?
                        <center style={{ color: '#aaa', marginTop: '8px' }}>Nincsen kedvezmény felvéve.</center>
                        :
                        kedvezmenyek.map((item) => <Segment color='yellow'>
                            <Button onClick={() => removeKedvezmeny(item.kedvezmenytipus)} floated='right' icon><Icon name='trash' /></Button>
                            <b>{options2.filter(d => d.value === item.kedvezmenytipus)[0].text}</b>
                            <p>
                                {item.kedvezmeny} Ft + 27% ÁFA
                                <br />
                                <b>{item.kedvezmeny * 1.27} Ft</b> br.
                            </p>
                        </Segment>)
                }
            </Grid.Column>
        </Grid>

        <Divider />
        <Form>
            <Form.Group>
                <Form.Checkbox defaultChecked={eloreFizetes} onChange={() => setEloreFizetes(!eloreFizetes)} toggle label='Előre fizetés' />
                <Form.Checkbox style={{ marginTop: '4px' }} disabled={!eloreFizetes} label='2022 / 1. negyedév' />
                <Form.Checkbox style={{ marginTop: '4px' }} disabled={!eloreFizetes} label='2022 / 2. negyedév' />
                <Form.Checkbox style={{ marginTop: '4px' }} disabled={!eloreFizetes} label='2022 / 3. negyedév' />
                <Form.Checkbox style={{ marginTop: '4px' }} disabled={!eloreFizetes} label='2022 / 4. negyedév' />
            </Form.Group>
        </Form>
        <Divider />
        <Grid columns={2}>
            <Grid.Column>
                <Header size='small'>Összesen</Header>
                <Header size='large' style={{ marginTop: '0px' }}>
                    {
                        dijak.length === 0 && kedvezmenyek.length === 0
                            ?
                            <span style={{ color: '#aaa' }}>0 Ft</span>
                            :
                            <span style={{ color: osszesen < 0 ? '#dd6666' : '#000' }}>
                                {
                                    osszesen
                                } Ft + ÁFA
                                (
                                {
                                    Math.round((osszesen) * 1.27)
                                } Ft br.
                                )
                            </span>
                    }
                </Header>
            </Grid.Column>
            <Grid.Column>
                    {
                        Math.round(osszesen) * 1.27 <= 12700
                        ?
                        <Message info>
                            <b><Icon name='info circle'/> Pénzügyi teljesítést nem igényel.</b> Ezt az információt az értesítőn is feltüntetjük.
                        </Message>
                        :
                        <Message info>
                            <b><Icon name='info circle'/> Egyenlegből részben teljesítve.</b> Ezt az információt az értesítőn is feltüntetjük.
                        </Message>
                    }
            </Grid.Column>
        </Grid>
        <Divider />
        <Form>
            <Grid columns={2}>
                <Grid.Column>
                    <Header size='small'>Értesítő testreszabása</Header>
                    <Form.Checkbox defaultChecked={egyedi} toggle label='Értesítő egyedi paraméterekkel' onChange={() => setEgyedi(!egyedi)} />
                    {
                        !egyedi
                            ?
                            <>
                                <Form.Field>
                                    <label>Értesítő tárgya</label>
                                    Jogdíjértesítő / 2022 / Q1
                                </Form.Field>
                                <Form.Field>
                                    <label>Kiállítás dátuma</label>
                                    2022.04.08
                                </Form.Field>
                                <Form.Field>
                                    <label>Teljesítés dátuma</label>
                                    2022.04.08
                                </Form.Field>
                                <Form.Field>
                                    <label>Fizetési határidő (+15 nap)</label>
                                    2022.04.23
                                </Form.Field>
                            </>
                            :
                            <>
                                <Form.Input label='Értesítő tárgya' placeholder='Adja meg az értesítő egyedi tárgyát' disabled={!egyedi}></Form.Input>
                                <Form.Input label='Kiállítás dátuma' width={4} placeholder='2022.04.08' disabled={!egyedi}></Form.Input>
                                <Form.Input label='Teljesítés dátuma' width={4} placeholder='2022.04.08' disabled={!egyedi}></Form.Input>
                                <Form.Input label='Fizetési határidő' width={4} placeholder='2022.04.08' disabled={!egyedi}></Form.Input>
                            </>
                    }
                </Grid.Column>
                <Grid.Column>
                    <Form.TextArea label='Megjegyzés (felhasználó számára is megjelenik)' floated></Form.TextArea>
                    <Message info><Icon name='info circle' />Az értesítő tartalmazni fogja a csatorna nevét és Playlist azonosítóját.</Message>
                </Grid.Column>
            </Grid>
            <Divider />
            <Grid columns={3}>
                <Grid.Column>
                    <Button onClick={() => alert('Mockup verzióban nem elérhető.')} primary disabled={osszesen < 1}>Értesítő kiállítása</Button>
                </Grid.Column>
                <Grid.Column textAlign='center'>
                </Grid.Column>
                <Grid.Column>
                    <Button onClick={() => alert('Mockup verzióban nem elérhető.')} floated='right'><Icon name='file outline'></Icon>Előnézet</Button>
                </Grid.Column>
            </Grid>
        </Form>
    </Segment>
}

export default JogdijErtesitoAdatlap;