import React, {  } from 'react';
import { Button, Segment, Icon, Form, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Felhasznal felfuggesztés.
 */
const FelhasznaloSuspend = () => {

    return <>
        <Segment placeholder basic>
            <Header icon>
                <Icon name='user delete' color='red' />
                Felhasználó felfuggesztése
            </Header>
        </Segment>
        <Segment basic>
            <p>
                <b>Biztosan felfüggeszti a felhasználót?</b> A felhasználó adatait a rendszerből nem töröljük, azonban a felhasználó kizárólag olvasási joggal fér hozzá adataihoz. Új időszakot a hatálybalépés napjától kezdve nem hozunk létre számára. A felfuggesztés emelt adminisztrátori jogkörrel visszavonható a későbbiekben.
                <br/>
                <br/>
                <Form>
                    <Form.Input width={2} placeholder='ÉÉÉÉ.HH.NN' label='Hatálybalépés napja'/>
                </Form>
            </p>
            <NavLink to='/sugarzasi/felhasznalok'>
                <Button onClick={() => alert('Mockup verzióban nem elérhető.')} icon primary>Igen, megerősítem a döntést</Button>
            </NavLink>
            <NavLink to='/sugarzasi/felhasznalok/1'>
                <Button floated='right'>Vissza</Button>
            </NavLink>
        </Segment>
    </>

};

export default FelhasznaloSuspend;