import React, { useState } from 'react';
import { Button, Label, Menu, Icon, Dropdown, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import MainContent from '../../jatszasilistak/adatszolgaltatas/subcomponents/MainContent'
/**
 * Repeat music datasheet.
 */
const IsmetlesiAdatszolgaltatasAdatlap = () => {

    // Wizard step
    const [step, setStep] = useState(1);

    // Visible list?
    const [isListVisible, setIsListVisible] = useState(true);
    
    // Tab
    const [activeIndex, setActiveIndex] = useState(1);

    // Possible statuses
    const statuszok = [
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
    ]

    const panes = [
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='yellow' basic={ activeIndex !== 1 }>Feldolgozás alatt</Label>&nbsp; M1</span></Menu.Item>, render: () => <Tab.Pane><MainContent step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> },
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='yellow' basic={ activeIndex !== 2 }>Feldolgozás alatt</Label>&nbsp; M2</span></Menu.Item>, render: () => <Tab.Pane><MainContent step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> },
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='red' basic={ activeIndex !== 3 }>Hiányzó lista</Label>&nbsp; Duna TV</span></Menu.Item>, render: () => <Tab.Pane><MainContent  step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> }
    ]

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='yellow'>
            <b><Icon name='check' />Feldolgozás alatti</b> játszási lista <b>M2</b> (MTVA) részére a <b>2022 / 1.</b> negyedévre háttérzenére.
        </Segment>
        <Grid columns={5} style={{ marginTop: '24px' }}>
            <Grid.Row>
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='yellow' style={{ marginTop: '-8px' }}>
                        <Button compact>Feldolgozás alatt</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            onChange={() => { alert('Mockup módban nem elérhető.') }}
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Feltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <b>MTVA</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Adminisztrátor
                </Grid.Column>
                <Grid.Column>
                    <b>Nagy János</b>
                    <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='exchange' /></Button>} content='Adminisztrátor cseréje' />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó playlist azonosító
                </Grid.Column>
                <Grid.Column>
                    <b>51</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Kapcsolattartó
                </Grid.Column>
                <Grid.Column>
                    <b>Kovács Rozmaringné</b>
                    <Button.Group>
                        <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
                        <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={ <span>Telefon: <b>06 1 555 5555</b></span> } />
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>              
        </Grid>
        <Divider/>
        <Segment>
            A <b>3</b> játszási listából <b>2</b> feldolgozás alatt áll, <b>1</b> hiányzik. A feltöltés elfogadásához <b>3</b> elfogadott állapotú játszási lista szükséges.
        </Segment>
        <Tab onTabChange={ (e, data) => setActiveIndex(data.activeIndex + 1) } panes={panes} />        
        <NavLink to='/ismetlesi/idoszakok'><Button floated='right'>Vissza</Button></NavLink>
    </Segment>

}

export default IsmetlesiAdatszolgaltatasAdatlap;