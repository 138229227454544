import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

/**
 * Felhasznalok listaja.
 */
const FelhasznaloLista = () => {

    const history = useHistory();

    const lista = [
        {
            adminisztrator: 'Adminisztrátor 1',
            id: '1',
            csatorna: 'Kattintható Répa Rádió',
            felhasznalo: 'Zöldség kft.',
            statusz: 'Elfogadásra vár',
            tevekenysegei: 'Webcast'
        },
        {
            adminisztrator: 'Adminisztrátor 1',
            id: '2',
            csatorna: 'Kellemetlen Rádió',
            felhasznalo: 'Ungabunga kft.',
            statusz: 'Elfogadott',
            tevekenysegei: <span>Interaktív webcast<br/>Archívum hozzáférhető</span>
        },
        {
            adminisztrator: 'Adminisztrátor 1',
            id: '3',
            csatorna: 'Paradicsom Rádió',
            felhasznalo: 'Hey-Ho kft.',
            statusz: 'Elfogadásra vár',
            tevekenysegei: 'Webcast'
        },        
    ]

    const userStatuses = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Elfogadásra vár',
            text: 'Elfogadásra vár',
            value: 'Elfogadásra vár'
        },        
        {
            key: 'Elfogadva',
            text: 'Elfogadva',
            value: 'Elfogadva'
        },
        {
            key: 'Elutasítva',
            text: 'Elutasítva',
            value: 'Elutasítva'
        }
    ]    

    const tevekenysegei = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        }
    ] 

    const customCSS = `
        .ui.table td {
            padding-top: 0.35em;
            padding-bottom: 0.35em;
            padding-right: 0.6em;
            padding-left: 0.6em;
        }
    `    

    return <>
        <style>
            { customCSS }
        </style>    
        <Table striped selectable sortable floated style={{ marginTop: '0px' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ background: '#eee' }}>Id <Icon name='angle down'/><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Csatorna <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Felhasználó <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Státusza <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Tevékenységei <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Gyorsmenü <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Id' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Csatorna' iconPosition='left' fluid /></Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Felhasználó' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => {}} selection value={'Mind'} options={ userStatuses } fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => {}} selection value={'Mind'} options={ tevekenysegei } fluid />
                    </Table.HeaderCell>                    
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {
                lista.map( (felhasznalo, index) => <Table.Row onClick={ () => index === 0 ? history.push('/sugarzasi/felhasznalok/1') : index === 1 ? history.push('/sugarzasi/felhasznalok/1/elfogadott') : alert('Mockup verzióban nem elérhető.') } warning={ felhasznalo.statusz === 'Elfogadásra vár' } positive={ felhasznalo.statusz === 'Elfogadott' }>
                    <Table.Cell>
                        { felhasznalo.id }
                    </Table.Cell>
                    <Table.Cell>
                        { felhasznalo.csatorna }
                    </Table.Cell>
                    <Table.Cell>
                        { felhasznalo.felhasznalo }
                    </Table.Cell>
                    <Table.Cell>
                        <Label basic color={ felhasznalo.statusz === 'Elfogadott' ? 'green' : felhasznalo.statusz === 'Elfogadásra vár' ? 'yellow' : 'red' }>{ felhasznalo.statusz }</Label>
                    </Table.Cell>
                    <Table.Cell>
                        { felhasznalo.tevekenysegei }
                    </Table.Cell>                    
                    <Table.Cell>
                        <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon primary compact><Icon name='file pdf outline' /></Button> }>Igazolások</Popup>
                        <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon primary compact><Icon name='mail outline' /></Button> }>E-mail küldés</Popup>
                        <Popup trigger={ <Button onClick={ (e) => { history.push('/sugarzasi/felhasznalok/1/idoszak'); e.stopPropagation(); } } icon primary compact><Icon name='calendar plus outline' /></Button> }>Új időszak hozzáadása</Popup>
                    </Table.Cell>
                </Table.Row>)
            }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='11'>
                        <Button primary onClick={ () => alert('Mockup verzióban nem elérhető.') }><Icon name='user plus' />Új felhasználó</Button>
                        <Menu floated='right' pagination>
                            <Button onClick={ () => alert('Mockup verzióban nem elérhető.') }><Icon name='table' />Export</Button>
                        </Menu>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </>

};

export default FelhasznaloLista;