import React, {  } from 'react';
import { Button, Table, Icon, Popup } from 'semantic-ui-react';

/**
 * Felhasznalo sajat adminisztratorai.
 */
const SajatCsatornaAdminokComponent = () => {

    const adminok = [
        { role: 'Játszási lista feltöltés', org: 'Répa Rádió', text: 'Nyugodalmas Emília', date: '2022.04.21 12:00:00' },
        { role: 'Bevételi adat feltöltés', org: 'Répa Rádió', text: 'Bátsi Dagobert', date: '2022.04.21 12:00:00' },
    ]

    return <>
            <Table striped selectable>
                <Table.Header>
                    <Table.HeaderCell>
                        Adminisztártor neve
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Felhasználó létrehozta
                    </Table.HeaderCell>        
                    <Table.HeaderCell>
                        Szerepköre
                    </Table.HeaderCell>                                        
                    <Table.HeaderCell>
                        Csatorna
                    </Table.HeaderCell>                                                            
                    <Table.HeaderCell textAlign='right'>
                        Gyorsmenü
                    </Table.HeaderCell>                                                                                
                </Table.Header>
                <Table.Body>
                {
                    adminok.map( (admin, index) => <Table.Row key={ index }>
                        <Table.Cell>
                            <b>{ admin.text }</b>
                        </Table.Cell>
                        <Table.Cell>
                            { admin.date }
                        </Table.Cell>   
                        <Table.Cell>
                            { admin.role }
                        </Table.Cell>   
                        <Table.Cell>
                            { admin.org }
                        </Table.Cell>                                                                      
                        <Table.Cell textAlign='right'>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='blue'><Icon name='key'/></Button> } content='Jelszóváltoztatás'/>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='blue'><Icon name='mail'/></Button> } content='E-mail küldés'/>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='red'><Icon name='remove'/></Button> } content='Eltávolítás'/>
                        </Table.Cell>  
                    </Table.Row>)
                }
                </Table.Body>
            </Table>
            <Button onClick={ () => alert('Mockup módban nem elérhető.') } floated='right' primary><Icon name='user plus'/> Új adminisztrátor</Button>
            <br/>
            <br/>
    </>
};

export default SajatCsatornaAdminokComponent;