import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

/**
 * Background music user list.
 */
const AudiovizualisFelhasznaloLista = () => {

    const history = useHistory();

    const lista = [
        {
            kapcsolattarto: 'Nagy Gáspár Boldizsár',
            idoszak: '2022 / 4',
            felhasznalo: 'Media Aport',
            status: 'Elfogadásra vár',
            id: '2101',
            tevekenyseg: 'Audiovizuális jogdíj'
        }
    ]

    const jatszasiListaStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },        
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },        
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
        {
            key: 'Gyorsított elfogadás',
            text: 'Gyorsított elfogadás',
            value: 'Gyorsított elfogadás'
        },        
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        }
    ]

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },        
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];

    const customCSS = `
    .ui.table td {
        padding-top: 0.35em;
        padding-bottom: 0.35em;
        padding-right: 0.6em;
        padding-left: 0.6em;
    }
    `

    return <>
        <style>
            { customCSS }
        </style>    
        <Table striped selectable sortable style={{ marginTop: '0px' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ background: '#eee' }}>Id <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Felhasználó <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Státusz <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Tevékenység <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Gyorsmenü <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Id' iconPosition='left' fluid /></Table.HeaderCell>                    
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Felhasználó' iconPosition='left' fluid /></Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown selection defaultValue={'Mind'} options={jatszasiListaStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Tevékenység' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                    </Table.HeaderCell>                    
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    lista.map( (idoszak, index) => <Table.Row onClick={ () => history.push('/audiovizualis/felhasznalok/1') } warning={ idoszak.status === 'Elfogadásra vár' } key={ index }>
                        <Table.Cell>{ idoszak.id }</Table.Cell>
                        <Table.Cell><b>{ idoszak.felhasznalo }</b></Table.Cell>
                        <Table.Cell>
                            <Label basic color='yellow'>
                                { idoszak.status }
                            </Label>
                        </Table.Cell>
                        <Table.Cell>{ idoszak.tevekenyseg }</Table.Cell>
                        <Table.Cell>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon primary compact><Icon name='mail outline' /></Button> }>E-mail küldés</Popup>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon primary compact><Icon name='calendar plus outline' /></Button> }>Új időszak hozzáadása</Popup>
                        </Table.Cell>
                    </Table.Row>)
                }
            </Table.Body>
        </Table>
    </>

}

export default AudiovizualisFelhasznaloLista;