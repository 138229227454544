import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

/**
 * Igazolaslista komponens.
 */
const IgazolasListaComponent = () => {

    const history = useHistory();

    const igazolasok = [
        { id: 1, csatorna: 'Répa Rádió', felhasznalo: 'Zöldség kft.', datum: '2022.04.12', tipus: 'NMHH', statusz: 'Kész', peldanyszam: 1 },
        { id: 2, csatorna: 'Édesburgonya Rádió', felhasznalo: 'Zöldség kft.', datum: '2022.04.11', tipus: 'NMHH', statusz: 'Kész', peldanyszam: 2 },
        { id: 3, csatorna: 'Édesburgonya Rádió', felhasznalo: 'Zöldség kft.', datum: '2022.02.11', tipus: 'NMHH', statusz: 'Elutasítva', peldanyszam: 2 },
        { id: 4, csatorna: 'Nemecsek Ernő TV', felhasznalo: 'Csősz kft.', datum: '2022.01.11', tipus: 'NMHH', statusz: 'Feldolgozás alatt', peldanyszam: 1 },
    ]

    const customCSS = `
        .ui.table td {
            padding-top: 0.35em;
            padding-bottom: 0.35em;
            padding-right: 0.6em;
            padding-left: 0.6em;
        }
    `    

    return <>
        <style>
            { customCSS }
        </style>    
        <Table striped selectable sortable floated style={{ marginTop: '0px' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ background: '#eee' }}>Id <Icon name='angle down'/><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Csatorna <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Felhasználó <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Igénylés dátuma <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Típus <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Státusz <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Példányszám <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Gyorsmenü <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Id' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Csatorna' iconPosition='left' fluid /></Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Felhasználó' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => {}} selection value={'Mind'} options={ [ { key: 'Mind', text: 'Mind', value: 'Mind' } ] } fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => {}} selection value={'Mind'} options={ [ { key: 'Mind', text: 'Mind', value: 'Mind' } ] } fluid />
                    </Table.HeaderCell>                    
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => {}} selection value={'Mind'} options={ [ { key: 'Mind', text: 'Mind', value: 'Mind' } ] } fluid />
                    </Table.HeaderCell>                    
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => {}} value={''} placeholder='Példányszám' iconPosition='left' fluid />
                    </Table.HeaderCell>                    
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    igazolasok.map( (igazolas, index) => <Table.Row onClick={ () => history.push('/igazolasok/1') } positive={ igazolas.statusz === 'Kész' } negative={ igazolas.statusz === 'Elutasítva' } key={ index }>
                        <Table.Cell>
                            { igazolas.id }
                        </Table.Cell>
                        <Table.Cell>
                            { igazolas.csatorna }
                        </Table.Cell>
                        <Table.Cell>
                            { igazolas.felhasznalo }
                        </Table.Cell>
                        <Table.Cell>
                            { igazolas.datum }
                        </Table.Cell>
                        <Table.Cell>
                            { igazolas.tipus }
                        </Table.Cell>
                        <Table.Cell>
                            <Label basic>{ igazolas.statusz }</Label>
                        </Table.Cell>
                        <Table.Cell>
                            { igazolas.peldanyszam }
                        </Table.Cell>
                        <Table.Cell>
                            <Button primary compact><Icon name='download'/>Letöltés</Button>
                        </Table.Cell>                                                                                                                                                                        
                    </Table.Row>)
                }
            </Table.Body>
            </Table>        
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') }><Icon name='plus'/>Új igazolás</Button>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } floated='right'><Icon name='table'/>Export</Button>
    </>
};

export default IgazolasListaComponent;