import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import JatszasiListaKotberAdatlap from './JatszasiListaKotberAdatlap'
import DijszamitasiAdatokKotberAdatlap from './DijszamitasiAdatokKotberAdatlap'
import KesedelmiKamatAdatlap from './KesedelmiKamatAdatlap'

/**
 * Kotber es kesedelmi kamat osszefoglalo komponens.
 */
const KotberEsKesedelmiContainer = () => {

    const panelek = [
        {
            menuItem: <Menu.Item><Icon name='percent' />Késedelmi kamat</Menu.Item>,
            render: () => <KesedelmiKamatAdatlap />,
        },        
        {
            menuItem: <Menu.Item><Icon name='clock' /> Játszási lista kötbér</Menu.Item>,
            render: () => <JatszasiListaKotberAdatlap />,
        },
        {
            menuItem: <Menu.Item><Icon name='clock' /> Díjszámítási adatok kötbér</Menu.Item>,
            render: () => <DijszamitasiAdatokKotberAdatlap />,
        },
    ]
    
    return <>
        <Tab menu={{ secondary: true, color: 'blue' }} panes={panelek} />
    </>
}

export default KotberEsKesedelmiContainer;