import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

/**
 * Ertesitokereses komponens.
 */
const ErtesitoKeresesComponent = () => {

    const [show, setShow] = useState(false);

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];

    const stateOptions = [
        { key: 1, value: 1, text: '-' },
        { key: 2, value: 2, text: 'FMH' },
        { key: 3, value: 3, text: 'Peresítve' },
        { key: 4, value: 4, text: 'Felszámolás alatt' },
        { key: 5, value: 5, text: 'Nem érvényesíthető' },
        { key: 6, value: 6, text: 'Számszakilag leírt' },
        { key: 7, value: 7, text: 'Végrehajtás alatt' }
    ]

    const felszolitasokOptions = [
        { key: 1, value: 1, text: 'Adatpótlásra' },
        { key: 2, value: 2, text: 'Fizetésre' }
    ]

    const renderLabelFelszolitas = (label) => ({
        color: 'blue',
        content: label.text,
        icon: '',
    })

    const editTetel = (id) => {
        alert('Mockup módban nem elérhető a tételmódosítás. Éles verzióban számlázásra átadás előtt és után is módosíthatók lesznek a tételek, adminisztrátori hozzáférési szinttől függően.');
    }

    return <>
        <Segment>
            <Form>
                <Form.Group>
                    <Form.Dropdown width={4} selection label='Felhasználó' defaultValue={1} options={[{ key: 1, value: 1, text: 'Zöldség kft.' }]} />
                    <Form.Dropdown selection label='Csatorna' defaultValue={1} options={[{ key: 1, value: 1, text: 'Mind' }]} />
                    <Form.Dropdown selection label='Státusz' defaultValue={1} options={[{ key: 1, value: 1, text: 'Mind' }]} />
                    <Form.Input label='Összeg (-tól)' defaultValue={0} />
                    <Form.Input label='Összeg (-ig)' defaultValue={100000000} />
                    <Form.Button onClick={ () => setShow(true) } style={{ marginTop: '24px' }} primary>Lekérdezés</Form.Button>
                </Form.Group>
                <Form.Group>
                    <Form.Input width={4} label='Bizonylatszám' placeholder='PL-XXXX-XXXX-XXXX' />
                    <Form.Input width={10} label='Szabad szöveg' />
                </Form.Group>
            </Form>
        </Segment>
        {
            !show
                ?
                <center style={{ color: '#aaa' }}>Adja meg a keresési feltételeket és kattintson a <i>Lekérdezés</i> gombra.</center>
                :
                <>
                    <Table striped style={{ marginTop: '-16px' }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Időszak / megnevezés</Table.HeaderCell>
                                <Table.HeaderCell>Felhasználó</Table.HeaderCell>
                                <Table.HeaderCell>Összeg</Table.HeaderCell>
                                <Table.HeaderCell>Bizonylat</Table.HeaderCell>
                                <Table.HeaderCell>Állapota</Table.HeaderCell>
                                <Table.HeaderCell>Követeléskezelés</Table.HeaderCell>
                                <Table.HeaderCell>Felszólítások</Table.HeaderCell>
                                <Table.HeaderCell>Gyorsmenü</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Dropdown floating labeled defaultValue={'Mind'} selection options={idoszakokStatus} fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Dropdown floating labeled defaultValue={'Mind'} selection options={idoszakokStatus} fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Input icon={'filter'} placeholder='Összeg' iconPosition='left' fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Input icon={'filter'} placeholder='Bizonylat' iconPosition='left' fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Dropdown floating placeholder='Mind' labeled selection fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Dropdown floating placeholder='Mind' labeled selection fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                    <Dropdown floating placeholder='Mind' labeled selection fluid />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row positive>
                                <Table.Cell>Előleg</Table.Cell>
                                <Table.Cell>Zöldség kft.</Table.Cell>
                                <Table.Cell>500.000 Ft<br /><b>635.000 br.</b></Table.Cell>
                                <Table.Cell>PL-EJI-2022-12</Table.Cell>
                                <Table.Cell><Label>Nincs megfizetve</Label></Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        fluid
                                        search
                                        selection
                                        defaultValue={1}
                                        options={stateOptions}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        defaultValue={[1]}
                                        options={felszolitasokOptions}
                                        renderLabel={renderLabelFelszolitas}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => editTetel(1)} color='blue' icon><Icon name='edit' /></Button>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Díjbekérő</Table.Cell>
                                <Table.Cell>Zöldség kft.</Table.Cell>
                                <Table.Cell>500.000 Ft<br /><b>635.000 br.</b></Table.Cell>
                                <Table.Cell>PL-EJI-2022-11</Table.Cell>
                                <Table.Cell><Label color='blue'>Részben (21.000)</Label></Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        fluid
                                        search
                                        selection
                                        defaultValue={1}
                                        options={stateOptions}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        defaultValue={[]}
                                        renderLabel={renderLabelFelszolitas}
                                        options={felszolitasokOptions}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => editTetel(2)} color='blue' icon><Icon name='edit' /></Button>
                                    <Button onClick={() => alert('Mockup módban nem elérhető a stornózás.')} color='red' icon><Icon name='remove' /></Button>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Díjbekérő</Table.Cell>
                                <Table.Cell>Zöldség kft.</Table.Cell>
                                <Table.Cell>500.000 Ft<br /><b>635.000 br.</b></Table.Cell>
                                <Table.Cell>PL-EJI-2022-11</Table.Cell>
                                <Table.Cell><Label>Nincs megfizetve</Label></Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        fluid
                                        search
                                        selection
                                        defaultValue={2}
                                        options={stateOptions}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        defaultValue={[1, 2]}
                                        options={felszolitasokOptions}
                                        renderLabel={renderLabelFelszolitas}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => editTetel(3)} color='blue' icon><Icon name='edit' /></Button>
                                    <Button onClick={() => alert('Mockup módban nem elérhető a stornózás.')} color='red' icon><Icon name='remove' /></Button>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Segment basic style={{ marginTop: '-16px' }}>
                        <Button floated='right' onClick={() => alert('Mockup verzióban nem elérhető.')}><Icon name='table' />Export</Button>
                    </Segment>
                </>
        }
    </>
};

export default ErtesitoKeresesComponent;