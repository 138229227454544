import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Felhasznalo jatszasi helyei.
 */
const JatszasiHelyekComponent = () => {

    const helyek = [
        { orszag: 1, irsz: 1011, varos: 'Budapest', utca: 'Mészáros utca 1.', kezdet: '2019.01.12 12:00:00' },
        { orszag: 1, irsz: 1041, varos: 'Budapest', utca: 'Kelemen László utca 5.', kezdet: '2019.01.12 12:00:00' }
    ]

    return <>
        <Table striped selectable>
            <Table.Header>
                <Table.HeaderCell>
                    Ország
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Irányítószám
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Város
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Utca, házszám
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Felhasználás kezdete
                </Table.HeaderCell>
                <Table.HeaderCell>
                    Felhasználás vége
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>
                    Gyorsmenü
                </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {
                    helyek.map((hely, index) => <Table.Row key={index}>
                        <Table.Cell>
                            <Dropdown defaultValue={hely.orszag} options={[{ key: 1, value: 1, text: 'Magyarország' }]} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input defaultValue={hely.irsz} style={{ width: '80px' }}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Input defaultValue={hely.varos} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input defaultValue={hely.utca} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input defaultValue={hely.kezdet} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input defaultValue='-' />
                        </Table.Cell>
                        <Table.Cell textAlign='right'>
                            <Popup trigger={<Button onClick={() => alert('Mockup verzióban nem elérhető.')} icon color='red'><Icon name='remove' /></Button>} content='Eltávolítás' />
                        </Table.Cell>
                    </Table.Row>)
                }
            </Table.Body>
        </Table>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } primary icon><Icon name='plus'/> Új játszási hely</Button>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='file excel outline'/> Játszási hely import</Button>
    </>
};

export default JatszasiHelyekComponent;