import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Lekerdezes komponens.
 */
const LekerdezesLista = () => {

    return <Segment basic style={{ background: '#eee', height: '100%' }}>
        <Grid columns='equal'>
            <Grid.Column style={{ lineHeight: '1.8' }}>
                <h3><Icon name='star' /> Kiemelt lekérdezések</h3>
                <Segment>
                    <Icon name='star' color='yellow' /> <NavLink to='/lekerdezesek/csatornak'>Csatornák lekérdezése</NavLink><br />
                </Segment>                
                <h3><Icon name='chart bar outline' /> Általános</h3>
                <Segment>
                    <Icon name='star outline'/> Felszólító levél<br />
                </Segment>
                <h3><Icon name='users' /> Csatornák, felhasználók</h3>
                <Segment>
                    <Icon name='star' color='yellow' /> <NavLink to='/lekerdezesek/csatornak'>Csatornák lekérdezése</NavLink><br />
                    <Icon name='star outline' /> Felhasználók lekérdezése<br />
                </Segment>
            </Grid.Column>
            <Grid.Column style={{ lineHeight: '1.8' }}>
                <Header><Icon name='chart bar outline' /> Tartozások</Header>
                <Segment>
                    <Icon name='star outline' /> Tartozások összesített értéke<br />
                    <Icon name='star outline' /> Legnagyobb tartozók<br />
                    <Icon name='star outline' /> Legrégebben jogdíjjal tartozók<br />
                    <Icon name='star outline' /> Legtöbb játszási listával tartozók<br />
                    <Icon name='star outline' /> Díjszámítási adatokkal tartozók<br />
                    <Icon name='star outline' /> Legrégebben jogdíjjal tartozók (VIP)<br />
                    <Icon name='star outline' /> Legtöbb játszási listával tartozók (VIP)<br />
                    <Icon name='star outline' /> Díjszámítási adatokkal tartozók (VIP)<br />
                </Segment>
            </Grid.Column>
        </Grid>
    </Segment>

};

export default LekerdezesLista;