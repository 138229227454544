import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea, Statistic } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import FolyoszamlaLista from '../../jatszasilistak/folyoszamla/subcomponents/FolyoszamlaLista';
import ElolegErtesitoAdatlap from '../../jatszasilistak/folyoszamla/subcomponents/ElolegErtesitoAdatlap';
import JogdijErtesitoAdatlap from './subcomponents/JogdijErtesitoAdatlap';
import FizetesAdatlap from '../../jatszasilistak/folyoszamla/subcomponents/FizetesAdatlap';
import KotberEsKesedelmiContainer from '../../jatszasilistak/folyoszamla/subcomponents/KotberEsKesedelmiContainer'

/**
 * Folyoszamla komponens.
 */
const IsmetlesiFolyoszamlaAdatlap = () => {

  const [aktivPanel, setAktivPanel] = useState(0);

  const panes = [
    {
      menuItem: <Menu.Item><Icon name='chart bar outline' /> Folyószámla</Menu.Item>,
      render: () => <FolyoszamlaLista setAktivPanel={setAktivPanel} />,
    },
    {
      menuItem: <Menu.Item><Icon name='file alternate outline' /> Előleg értesítő</Menu.Item>,
      render: () => <ElolegErtesitoAdatlap />,
    },
    {
      menuItem: <Menu.Item><Icon name='file alternate' /> Jogdíj értesítő</Menu.Item>,
      render: () => <JogdijErtesitoAdatlap />,
    },
    {
      menuItem: <Menu.Item><Icon name='bars' /> Fizetés</Menu.Item>,
      render: () => <FizetesAdatlap />,
    },
    {
      menuItem: <Menu.Item><Icon name='clock outline' /> Kötbér & Késedelmi kamat</Menu.Item>,
      render: () => <KotberEsKesedelmiContainer />,
    },
  ]

  return <Segment basic>
    <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='green'>
      <b><Icon name='check' />Nincsen aktív értesítő</b>, a felhasználó a számára kiállított jogdíjértesítőt <b>megfizette</b> erre az időszakra.
    </Segment>
    <Divider />
    <Grid columns={5}>
      <Grid.Row columns={5}>
        <Grid.Column style={{ paddingTop: '8px' }}>
          Felhasználó
        </Grid.Column>
        <Grid.Column>
          <div style={{ whiteSpace: 'nowrap' }}>
            MTVA
          </div>
        </Grid.Column>
        <Grid.Column>
        </Grid.Column>
        <Grid.Column style={{ paddingTop: '8px' }}>
          Adminisztrátor
        </Grid.Column>
        <Grid.Column>
          <b>Nagy János</b> &nbsp;
          <Popup trigger={<Button compact icon><Icon name='exchange' /></Button>} content='Adminisztrátor cseréje' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          Felhasználó playlist azonosító
        </Grid.Column>
        <Grid.Column>
          <b>51</b>
        </Grid.Column>
        <Grid.Column>
        </Grid.Column>
        <Grid.Column>
          Kapcsolattartó
        </Grid.Column>
        <Grid.Column>
          <b>Kovács Rozmaringné</b>
          <Button.Group>
            <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
            <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={<span>Telefon: <b>06 1 555 5555</b></span>} />
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <Statistic.Group widths={5} size='small'>
      <Statistic label='Kiegyenlített' value='12' color='green' />
      <Statistic label='Részben kiegyenlített' value='1' color='blue' />
      <Statistic label='Kiegyenlítetlen' value='2' color='yellow' />
      <Statistic label='Késedelem' value='1' color='red' />
      <Statistic label='Végrehatás / FMH' value='1' color='grey' />
    </Statistic.Group>
    <Divider />
    <Tab menu={{ secondary: true, pointing: true }} onTabChange={(e) => setAktivPanel(e.target.value)} panes={panes} activeIndex={aktivPanel} style={{ marginTop: '-14px' }} />
  </Segment>

};

export default IsmetlesiFolyoszamlaAdatlap;