import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Store from './store/Store'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import InnerApp from './InnerApp'
// HOT RELOAD https://www.npmjs.com/package/craco-plugin-react-hot-reload

const App = () => {

    return (
        <div onClick={() => { }}> { /* HINT ugly JS hack for iOS */}
            <Store>
                <BrowserRouter>
                    <Redirect path='/' exact to='/globalwelcome' />
                    <InnerApp/>
                </BrowserRouter>
            </Store>
        </div>
    );
};

export default App;