import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Hatterzeneszolgaltato sajat adminisztratorai.
 */
const FelhasználoAdminokComponent = () => {

    const adminok = [
        { role: 'Játszási lista feltöltés', org: 'TESCO', text: 'Nyugodalmas Emília', date: '2022.04.21 12:00:00' },
        { role: 'Bevételi adat feltöltés', org: 'Auchan', text: 'Bátsi Dagobert', date: '2022.04.21 12:00:00' },
    ]

    return <>
            <Table striped selectable>
                <Table.Header>
                    <Table.HeaderCell>
                        Adminisztártor neve
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Felhasználó létrehozta
                    </Table.HeaderCell>        
                    <Table.HeaderCell>
                        Szerepköre
                    </Table.HeaderCell>                                        
                    <Table.HeaderCell>
                        Felhasználó
                    </Table.HeaderCell>                                                            
                    <Table.HeaderCell textAlign='right'>
                        Gyorsmenü
                    </Table.HeaderCell>                                                                                
                </Table.Header>
                <Table.Body>
                {
                    adminok.map( (admin, index) => <Table.Row key={ index }>
                        <Table.Cell>
                            <b>{ admin.text }</b>
                        </Table.Cell>
                        <Table.Cell>
                            { admin.date }
                        </Table.Cell>   
                        <Table.Cell>
                            { admin.role }
                        </Table.Cell>   
                        <Table.Cell>
                            <NavLink to='/hatterzene/felhasznalok/1'>
                                <a href='#'>{ admin.org }</a>
                            </NavLink>
                        </Table.Cell>                                                                      
                        <Table.Cell textAlign='right'>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='blue'><Icon name='mail'/></Button> } content='E-mail küldés'/>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='red'><Icon name='remove'/></Button> } content='Eltávolítás'/>
                        </Table.Cell>  
                    </Table.Row>)
                }
                </Table.Body>
            </Table>
            <br/>
    </>
};

export default FelhasználoAdminokComponent;