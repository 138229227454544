import React, { createContext } from 'react';
import { Icon, Header, Menu } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

/**
 * Header of this layout.
 */
export const LayoutHeader = ({ }) => {

    const location = useLocation();
    const icon = location.pathname.startsWith('/felhasznalo') ? 'user circle' : 'chart line';
    const background = location.pathname.startsWith('/felhasznalo') ?  '#944FA7' : '#015EB8';

    const getHeader = () => {

        switch (location.pathname)
        {
            case '/ertesitokeresese': return 'Keresés'
            case '/lekerdezesek/csatornak': return 'Csatornák'
            case '/lekerdezesek': return 'Minden lekérdezés'
            case '/szamlazas/import': return 'Bank import'
            case '/szamlazas/export': return 'Számlaexport'
            case '/ismetlesi/idoszakok': return 'Adatszolgáltatás'
            case '/ismetlesi/felhasznalok': return 'Felhasználók'
            case '/ismetlesi/felhasznalok/1': return 'MTVA'
            case '/ismetlesi/felhasznalok/1/idoszak': return 'MTVA / Új időszak'
            case '/ismetlesi/jatszasilistaadatlap': return 'Játszási lista / 2022 / 1'
            case '/ismetlesi/dijszamitasiAdatok': return 'Díjszámítási adatok / 2022 / 1'
            case '/ismetlesi/folyoszamla': return 'Folyószámla / Ismétlési jogdíj / 2022 / 1'
            case '/hatterzene/hatterzeneszolgaltatok/1': return 'Háttérzeneszolgáltatók / G3 Démia'
            case '/hatterzene/felhasznalok/1': return 'Felhasználók / Fressen & Gefressen Kft.'
            case '/felhasznalo/reparadio/sugo': return 'Súgó' 
            case '/felhasznalo/reparadio/uzenetek': return 'Üzenetek'
            case '/felhasznalo/reparadio/profil': return 'Profil'
            case '/felhasznalo/reparadio/kapcsolattartok': return 'Kapcsolattartók'
            case '/felhasznalo/reparadio/letoltes': return 'Letöltés'            
            case '/felhasznalo/reparadio/igazolasok': return 'Igazolások';
            case '/felhasznalo/reparadio/egyenleg': return 'Egyenleg';
            case '/felhasznalo/reparadio/feltoltes': return 'Játszási lista újra feltöltése';
            case '/felhasznalo/reparadio/ujfeltoltes': return 'Játszási lista feltöltése';
            case '/felhasznalo/reparadio/dijszamitasiadatok': return 'Díjszámítási adatok feltöltése';
            case '/felhasznalo/reparadio/welcome': return 'Üdvözöljük!';
            case '/felhasznalo/reparadio/adatszolgaltatas': return 'Adatszolgáltatás';            
            case '/felhasznalo/reparadio/szerzodes': return 'Szerződés igénylése'
            case '/letoltes': return 'Letöltés';
            case '/sugarzasi/felhasznalok': return 'Felhasználók';
            case '/sugarzasi/felhasznalok/1': return 'Zöldség kft.'
            case '/sugarzasi/felhasznalok/1/elfogadott': return 'Ungabunga kft.'
            case '/sugarzasi/felhasznalok/1/termination': return 'Zöldség kft. / Megszüntetés'
            case '/sugarzasi/felhasznalok/1/succession': return 'Zöldség kft. / Migráció'
            case '/sugarzasi/felhasznalok/1/suspend': return 'Zöldség kft. / Felfüggesztés'
            case '/sugarzasi/felhasznalok/1/idoszak': return 'Zöldség kft. / Új időszak'
            case '/igazolasok': return 'Igazolások';
            case '/jatszasilistak': return 'Adatszolgáltatási időszakok';
            case '/hatterzene/hatterzeneszolgaltatok': return 'Háttérzeneszolgáltatók';
            case '/hatterzene/felhasznalok': return 'Felhasználók';
            case '/hatterzene/jatszasilistak': return 'Adatszolgáltatási időszakok';
            case '/hatterzene/jatszasilistak/1': return 'Játszási lista / 2022 / 1';
            case '/jatszasilistak/jatszasilistaadatlapElfogadott': return 'Játszási lista / 2022 / 1';
            case '/jatszasilistak/jatszasilistaadatlap': return <span>Játszási lista / 2022 / 1</span>; 
            case '/jatszasilistak/dijszamitasiAdatok': return <span>Díjszámítási adatok / 2022 / 1</span>
            case '/jatszasilistak/folyoszamla': return <span>Folyószámla / 2022 / 1</span>
            case '/jatszasilistak/statisztika': return <span>Statisztika / 2022 / 1</span>
            case '/jatszasilistak/naplo': return 'Napló / 2022 / 1'
            case '/jatszasilistak/jatszasilistaadatlapAutomataHibas': return <span>Játszási lista / 2022 / 1</span>; 
            case '/audiovizualis/felhasznalok': return 'Felhasználók';
            case '/audiovizualis/idoszakok': return 'Adatszolgáltatási időszakok';
            case '/audiovizualis/felhasznalok/1': return 'Media Aport';
            case '/audiovizualis/jatszasilistak/1': return 'Játszási lista / 2022 / 1';
            default: return location.pathname;
        }
    }

    const getSubheader = () => {

        if (location.pathname.startsWith('/felhasznalo')) return 'Sugárzási jogdíj';

        switch (location.pathname)
        {
            case '/ertesitokeresese': return 'Értesítő keresése'
            case '/lekerdezesek/csatornak': return 'Lekérdezések'
            case '/lekerdezesek': return 'Lekérdezések'
            case '/szamlazas/import': return 'Számlázás'
            case '/szamlazas/export': return 'Számlázás'
            case '/ismetlesi/idoszakok': return 'Ismétlési jogdíj'
            case '/ismetlesi/felhasznalok': return 'Ismétlési jogdíj'
            case '/ismetlesi/felhasznalok/1': return 'Ismétlési jogdíj'
            case '/ismetlesi/jatszasilistaadatlap': return 'Ismétlési jogdíj'
            case '/ismetlesi/dijszamitasiAdatok': return 'Ismétlési jogdíj'
            case '/ismetlesi/felhasznalok/1/idoszak': return 'Ismétlési jogdíj'
            case '/ismetlesi/folyoszamla': return 'Ismétlési jogdíj'            
            case '/hatterzene/hatterzeneszolgaltatok/1': return 'Háttérzene'
            case '/hatterzene/felhasznalok/1': return 'Háttérzene'    
            case '/audiovizualis/felhasznalok': return 'Audiovizuális jogdíj';
            case '/audiovizualis/felhasznalok/1': return 'Audiovizuális jogdíj';
            case '/audiovizualis/idoszakok': return 'Audiovizuális jogdíj';        
            case '/letoltes': return 'Dokumentációk és leírások';
            case '/igazolasok': return 'Sugárzási jogdíj';
            case '/sugarzasi/felhasznalok': return 'Sugárzási jogdíj';
            case '/sugarzasi/felhasznalok/1/idoszak': return 'Sugárzási jogdíj'
            case '/sugarzasi/felhasznalok/1': return 'Sugárzási jogdíj'
            case '/sugarzasi/felhasznalok/1/elfogadott': return 'Sugárzási jogdíj'
            case '/sugarzasi/felhasznalok/1/termination': return 'Sugárzási jogdíj'
            case '/sugarzasi/felhasznalok/1/suspend': return 'Sugárzási jogdíj'
            case '/sugarzasi/felhasznalok/1/succession': return 'Sugárzási jogdíj'
            case '/jatszasilistak': return 'Sugárzási jogdíj';
            case '/hatterzene/hatterzeneszolgaltatok': return 'Háttérzene';
            case '/hatterzene/felhasznalok': return 'Háttérzene';
            case '/hatterzene/jatszasilistak': return 'Háttérzene';
            case '/hatterzene/jatszasilistak/1': return 'Háttérzene';
            case '/jatszasilistak/jatszasilistaadatlap': return 'Adatszolgáltatási időszakok';
            case '/jatszasilistak/dijszamitasiAdatok': return 'Adatszolgáltatási időszakok';
            case '/jatszasilistak/folyoszamla': return 'Adatszolgáltatási időszakok';
            case '/jatszasilistak/jatszasilistaadatlapElfogadott': return 'Adatszolgáltatási időszakok';
            case '/jatszasilistak/statisztika': return 'Adatszolgáltatási időszakok';
            case '/jatszasilistak/jatszasilistaadatlapAutomataHibas': return 'Adatszolgáltatási időszakok';
            case '/jatszasilistak/naplo': return 'Sugárzási jogdíj'
            case '/audiovizualis/jatszasilistak/1': return 'Audiovizuális jogdíj';
            default: return location.pathname;
        }
    }

    return <div style={{ zIndex: '1000', marginTop: '-2px', background: background, padding: '2em 2em 2em 1em', gridColumn: '1 / 4', width: '100%' }}>        
        <Header as='h2' inverted>
            {
                icon !== undefined
                ?
                <Icon name={ icon } />
                :
                ''
            }
            <Header.Content>
                { getHeader() }
                <Header.Subheader>{ getSubheader() }</Header.Subheader>
            </Header.Content>
        </Header>
        <Header as='h2' floated='right' inverted style={{ marginTop: '-68px', marginRight: '-8px' }}>
            <NavLink to='/felhasznalo/reparadio/welcome' style={{ color: '#fff', opacity: '0.5' }}>Répa Rádió</NavLink>
        </Header>        
    </div>     
}

/**
 * Vertical menu component placeholder on the left.
 * @param {*} param0 
 */
export const LayoutMenu = ({ children }) => <div style={{ marginTop: '0px' }}><Menu vertical>{ children }</Menu></div>

/**
 * The main area's placeholder.
 * @param {*} param0 
 */
export const LayoutMainArea = ({ children }) => <div style={{ marginTop: '0px', gridColumn: '2/4', maxWidth: '1300px', paddingLeft: '-1px' }}>{ children }</div>;

const LayoutContext = createContext();

/**
 * Custom layout component with placeholders for left menu, header and main area.
 * @param {*} props 
 */
const Layout = ({children}) => {
    return (
        <LayoutContext.Provider value={null}>
            <div style={{ marginTop: '-12px', display: 'grid', gridTemplateColumns: '210px auto', gridTemplateRows: '108px auto', gridGap: '0em' }}>
                {children}               
            </div>
        </LayoutContext.Provider>
    );
}
Layout.Header = LayoutHeader;
Layout.MainArea = LayoutMainArea;
Layout.Menu = LayoutMenu; 

export default Layout;