import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Okoskereses. De idiota nevet adtam neki.
 */
const SmartSearchComponent = () => {

    return <Segment basic>
        <h2><Icon name='lightbulb outline' />Okoskeresés</h2>
        <Form>
            <Form.Dropdown label='Keresési feltétel' search selection placeholder='Pink Floyd Final Cut'>
                <Dropdown.Menu>
                    <Dropdown.Item
                        label={{ color: 'green', empty: true, circular: true }}
                        text='Mindenhol'
                    />
                    <Dropdown.Item
                        label={{ color: 'red', empty: true, circular: true }}
                        text='Játszási lista'
                    />
                    <Dropdown.Item
                        label={{ color: 'blue', empty: true, circular: true }}
                        text='Felhasználó'
                    />
                    <Dropdown.Item
                        label={{ color: 'black', empty: true, circular: true }}
                        text='Értesítő'
                    />
                </Dropdown.Menu>
            </Form.Dropdown>
            <Form.Group>
                <Form.Input icon='clock' width={3} label='Időszak kezdete' placeholder='ÉÉÉÉ.HH.NN'/> -
                <Form.Input icon='clock' width={3} label='Időszak vége' placeholder='ÉÉÉÉ.HH.NN'/>
            </Form.Group>
            <Form.Group>
                <Form.Dropdown search selection label='Felhasználó' options={[]}/>
                <Form.Dropdown search selection label='Csatorna' options={[]}/>
            </Form.Group>            
        </Form>
        <Button floated='right'>Újra</Button>
        <Button primary><Icon name='zoom'/>Keresés</Button>        
    </Segment>
};

export default SmartSearchComponent;