import React, {createContext, useReducer} from "react";
import MainReducer from '../reducers/MainReducer'
import { initialState } from '../reducers/InitialState'

const Store = ({children}) => {
    const [state, dispatch] = useReducer(MainReducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;