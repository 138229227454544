import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import BontasContainer from './BontasContainer'

/**
 * Fizetes ertesito komponens.
 */
const FizetesAdatlap = () => {

    const [showBontas, setShowBontas] = useState(false);
    const [total, setTotal] = useState(0);
    const [dayOfPayment, setDayOfPayment] = useState();

    const showContainer = () => {
        if (document.getElementById('befnap').value === '') {
            alert('A befizetés napjának megadása kötelező.');
        }
        else {
            const fee = document.getElementById('dij').value;
            if (fee !== undefined && fee !== '' && fee !== 0) {
                setShowBontas(true);
                setTotal(fee + '');
                setDayOfPayment(document.getElementById('befnap').value);
                document.getElementById('befnap').value = '';
                document.getElementById('dij').value = '';
            }
            else {
                alert('A szétosztandó összeg megadása kötelező.');
            }
        }
    }

    return <Segment>
        <Header size='small'>Befizetés</Header>
        <Form>
            <Form.Group>
                <Form.Input id='befnap' placeholder='ÉÉÉÉ.HH.NN' label='Befizetés napja'></Form.Input>
                <Form.Input min={0} label='Összege' id='dij' type='number' placeholder='0' /><span style={{ marginTop: '32px' }}> Ft (br.)</span>
                <Form.Button onClick={() => showContainer()} style={{ marginTop: '24px' }} icon primary><Icon name='plus' /></Form.Button>
            </Form.Group>
            {
                showBontas && total !== undefined
                ?
                <>
                    <Divider />
                    <BontasContainer dayOfPayment={ dayOfPayment } total={total} />
                    <Form.Button primary onClick={() => alert('Mockup módban nem elérhető.')}>Fizetés rögzítése</Form.Button>
                </>
                :
                <></>
            }
        </Form>
    </Segment>
}

export default FizetesAdatlap;