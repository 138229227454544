import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Divider, Dropdown, Grid, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

/**
 * Uzenetek
 */
const Uzenetek = () => {

    return <Segment basic>
        <h3><Icon name='mail outline' /> Üzenetek</h3>
        <Segment color='yellow' tertiary inverted style={{ borderLeft: '3px solid #000' }}>
            <b style={{ color: '#000' }}>
                <Icon name='handshake outline' /> Nem rendelkezik szerződéssel
            </b><span style={{ color: '#000' }}>. A kedvezmények igénybevétele érdekében <u><NavLink style={{ color: '#000' }} to='/felhasznalo/reparadio/szerzodes'>kösse meg most</NavLink></u>.</span>
        </Segment>
        <Segment style={{ borderLeft: '3px solid #2185D0' }}>
            <p>
                <Icon name='mail outline' /><b>Közérdekű közlemény</b>
                <br />
                <br />
                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.
            </p>
            <a href='#' onClick={() => alert('Mockup módban nem elérhető az olvasott státuszra állítás.')}><Icon name='eye slash outline' /></a>
            <b><Icon name='clock outline' /> 2022.04.21</b>
        </Segment>
        <Segment style={{ borderLeft: '3px solid #2185D0' }}>
            <p>
                <Icon name='mail outline' /><b>Teljesen érdektelen közlemény</b>
                <br />
                <br />
                It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.
            </p>
            <a href='#' onClick={() => alert('Mockup módban nem elérhető az olvasott státuszra állítás.')}><Icon name='eye slash outline' /></a>
            <b><Icon name='clock outline' /> 2022.04.13</b>
        </Segment>
    </Segment>
};

export default Uzenetek;