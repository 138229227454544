import React, { useContext, useState } from 'react';
import { Header, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Letoltesek.
 */
const DownloadList = () => {

    return <Segment basic>
        <Grid columns={3} stackable>
            <Grid.Row>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Adminisztrátori kézikönyv
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Egységes rádiós játszási lista
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                DJ formátum leírás
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Játszási listák formátuma - gyakran idézett kérdések
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Játszási lista (Excel sablon)
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                PDI (Playlist Database Interface) - automatizált adatszolgáltatás
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Érvényesitési kódok magyarázata
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Háttérzene formátum
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Régi szerződés melléklet az adatszolgáltatás formátumáról
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>            
        </Grid>      
    </Segment>
};

export default DownloadList;