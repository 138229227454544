import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

/**
 * Adatszolgaltatasi idoszakok listak listaja.
 */
const AdatszolgaltatasLista = () => {

    const specialisSzures = [
        {
            key: 'Mind',
            text: '-',
            value: 'Mind'
        },
        {
            text: 'TV-k',
            key: '-',
            value: '-'
        },
        {
            text: 'Rádiók',
            key: '-',
            value: '-',
        },
        {
            text: 'Rádiók, 100 fő alatt',
            key: '-',
            value: '-',
        },
        {
            text: 'Minimum jogdíj',
            key: '-',
            value: '-',
        },                
        {
            text: 'Kedvezményes minimum jogdíj',
            key: '-',
            value: '-',
        },        
        {
            text: 'DJ fájltípus',
            key: '-',
            value: '-',
        },          
        {
            text: 'Nincs automatikus díjszámítás',
            key: '-',
            value: '-',
        },          
    ]

    const adminisztratorok = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Adminisztrátor 1',
            text: 'Békés Gergő',
            value: 'Adminisztrátor 1',
        },
        {
            key: 'Adminisztrátor 2',
            text: 'Adminisztrátor 2',
            value: 'Adminisztrátor 2',
        }
    ]

    const fizetveStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Értesített',
            text: 'Értesített',
            value: 'Értesített'
        },        
        {
            key: 'Részben megfizetett',
            text: 'Részben megfizetett',
            value: 'Részben megfizetett'
        },
        {
            key: 'Megfizetett',
            text: 'Megfizetett',
            value: 'Megfizetett'
        },
        {
            key: 'Késedelem',
            text: 'Késedelem',
            value: 'Késedelem'
        }
    ]

    const dijszamitasiAdatStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },        
        {
            key: 'Ellenőrzés alatt',
            text: 'Ellenőrzés alatt',
            value: 'Ellenőrzés alatt'
        },        
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },        
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
    ]

    const jatszasiListaStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },        
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },        
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
        {
            key: 'Gyorsított elfogadás',
            text: 'Gyorsított elfogadás',
            value: 'Gyorsított elfogadás'
        },        
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        }
    ]

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },        
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];

    const lista = [
        {
            id: 1,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 4',
            felhasznaloNeve: 'Répa Rádió (+2)',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Feldolgozás alatt',
            dijszamitasiAdatokStatus: 'Ellenőrzés alatt',
            dijfizetesiErtesitoStatus: '-',
            fizetesStatus: '-'
        },
        {
            id: 1,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 3',
            felhasznaloNeve: 'Répa Rádió (+2)',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Aut. hibás',
            dijszamitasiAdatokStatus: '-',
            dijfizetesiErtesitoStatus: '-',
            fizetesStatus: '-',
            status: 'Hibás'
        },
        {
            id: 1,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 2',
            felhasznaloNeve: 'Répa Rádió (+2)',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: '-',
            dijfizetesiErtesitoStatus: '-',
            fizetesStatus: '-'
        },
        {
            id: 1,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 1',
            felhasznaloNeve: 'Répa Rádió (+2)',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: '-',
            fizetesStatus: 'Késedelem'
        },
        {
            id: 2,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 1',
            felhasznaloNeve: 'Kellemetlen Rádió',
            felhasznalo: 'Kígyó bt.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Ellenőrzés alatt',
            dijfizetesiErtesitoStatus: '-',
            fizetesStatus: 'Értesített'
        },
        {
            id: 3,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2021 / 4',
            felhasznaloNeve: 'Pelikán Rádió',
            korabbiNev: 'Bástya Rádió',
            felhasznalo: 'Doktor Kotász kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: 'Elfogadott',
            fizetesStatus: 'Részben megfizetett',
            status: 'Elfogadott'
        },
        {
            id: 3,
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2021 / 3',
            felhasznaloNeve: 'Bástya Rádió',
            felhasznalo: 'Doktor Kotász kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: 'Elfogadott',
            fizetesStatus: 'Megfizetett',
            status: 'Elfogadott'
        },
        {
            id: 3,
            adminisztrator: 'Adminisztrátor 2',
            idoszak: '2021 / 2',
            felhasznaloNeve: 'Bástya Rádió',
            felhasznalo: 'Doktor Kotász kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: 'Elfogadott',
            fizetesStatus: 'Megfizetett',
            status: 'Elfogadott'
        },
        {
            id: 3,
            adminisztrator: 'Adminisztrátor 2',
            idoszak: '2021 / 1',
            felhasznaloNeve: 'Bástya Rádió',
            felhasznalo: 'Doktor Kotász kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: 'Elfogadott',
            fizetesStatus: 'Megfizetett',
            status: 'Elfogadott'
        },
        {
            id: 3,
            adminisztrator: 'Adminisztrátor 2',
            idoszak: '2020 / 4',
            felhasznaloNeve: 'Bástya Rádió',
            felhasznalo: 'Doktor Kotász kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: 'Elfogadott',
            fizetesStatus: 'Megfizetett',
            status: 'Elfogadott'
        },  
        {
            id: 3,
            adminisztrator: 'Adminisztrátor 2',
            idoszak: '2020 / 3',
            felhasznaloNeve: 'Bástya Rádió',
            felhasznalo: 'Doktor Kotász kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            dijfizetesiErtesitoStatus: 'Elfogadott',
            fizetesStatus: 'Megfizetett',
            status: 'Elfogadott'
        },                
    ]

    const resetFilter = () => {
        setAdminisztratorFilter('Adminisztrátor 1');
        setIdoszakFilter('Mind');
        setFelhasznaloFilter('');
        setFelhasznaloNevFilter('');
        setDijfizetesiErtesitoStatusFilter('Mind');
        setDijszamitasiAdatokStatusFilter('Mind');
        setFizetesStatusFilter('Mind');
        setJatszasiListaStatusFilter('Mind');

    }

    const getFilteredList = () => lista.
        filter(i => adminisztratorFilter === undefined || adminisztratorFilter === 'Mind' || i.adminisztrator.toUpperCase().includes(adminisztratorFilter.toUpperCase())).
        filter(i => idoszakFilter === undefined || idoszakFilter === 'Mind' || i.idoszak.toUpperCase().includes(idoszakFilter.toUpperCase())).
        filter(i => felhasznaloNevFilter === undefined || felhasznaloNevFilter === '' || i.felhasznaloNeve.toUpperCase().includes(felhasznaloNevFilter.toUpperCase())).
        filter(i => felhasznaloFilter === undefined || felhasznaloFilter === '' || i.felhasznalo.toUpperCase().includes(felhasznaloFilter.toUpperCase())).
        filter(i => jatszasiListaStatusFilter === undefined || jatszasiListaStatusFilter === 'Mind' || i.jatszasiListaStatus.toUpperCase().includes(jatszasiListaStatusFilter.toUpperCase())).
        filter(i => dijszamitasiAdatokStatusFilter === undefined || dijszamitasiAdatokStatusFilter === 'Mind' || i.dijszamitasiAdatokStatus.toUpperCase().includes(dijszamitasiAdatokStatusFilter.toUpperCase())).
        filter(i => dijfizetesiErtesitoStatusFilter === undefined || dijfizetesiErtesitoStatusFilter === 'Mind' || i.dijfizetesiErtesitoStatus.toUpperCase().includes(dijfizetesiErtesitoStatusFilter.toUpperCase())).
        filter(i => fizetesStatusFilter === undefined || fizetesStatusFilter === 'Mind' || i.fizetesStatus.toUpperCase().includes(fizetesStatusFilter.toUpperCase()));

    const [idoszakFilter, setIdoszakFilter] = useState('Mind');
    const [felhasznaloNevFilter, setFelhasznaloNevFilter] = useState('');
    const [felhasznaloFilter, setFelhasznaloFilter] = useState('');
    const [jatszasiListaStatusFilter, setJatszasiListaStatusFilter] = useState('Mind');
    const [dijszamitasiAdatokStatusFilter, setDijszamitasiAdatokStatusFilter] = useState('Mind');
    const [dijfizetesiErtesitoStatusFilter, setDijfizetesiErtesitoStatusFilter] = useState('Mind');
    const [fizetesStatusFilter, setFizetesStatusFilter] = useState('Mind');
    const [adminisztratorFilter, setAdminisztratorFilter] = useState('Mind');
    const [specialisSzuresFilter, setSpecialisSzuresFilter] = useState('Mind');

    const history = useHistory();

    const customCSS = `
        .ui.table td {
            padding-top: 0.35em;
            padding-bottom: 0.35em;
            padding-right: 0.6em;
            padding-left: 0.6em;
        }
    `

    return <>
        {
            idoszakFilter !== 'Mind' || felhasznaloFilter !== '' || felhasznaloNevFilter !== '' || jatszasiListaStatusFilter !== 'Mind' ||
                dijfizetesiErtesitoStatusFilter !== 'Mind' || dijfizetesiErtesitoStatusFilter !== 'Mind' || fizetesStatusFilter !== 'Mind' ||
                adminisztratorFilter !== 'Mind'
                ?
                <Segment style={{ borderRadius: '0px' }} inverted color='yellow'>
                    <Button color='yellow' floated='right' icon compact style={{ marginTop: '-6px' }} onClick={resetFilter}>
                        <Icon name='delete' /> Szűrés kikapcsolása
                    </Button>
                    <b><Icon name='filter' />Szűrés aktív </b> az adatszolgáltatási időszakok listáján ({getFilteredList().length} szűrt / {lista.length} összes)
                </Segment>
                :
                ''
        }
        <style>
            { customCSS }
        </style>        
        <Table striped selectable sortable style={{ marginTop: '0px' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>Id <Icon name='angle down'/><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Időszak <Icon name='angle down'/><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Csatorna <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Felhasználó <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Játszási lista <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Díjszámítási adatok <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Fizetés <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Folyószámla <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} value={''} placeholder='Id' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating labeled onChange={(e, { value }) => setIdoszakFilter(value)} value={idoszakFilter} selection options={idoszakokStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => setFelhasznaloNevFilter(e.target.value)} value={felhasznaloNevFilter} placeholder='Csatorna' iconPosition='left' fluid /></Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} onChange={(e) => setFelhasznaloFilter(e.target.value)} value={felhasznaloFilter} placeholder='Felhasználó' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setJatszasiListaStatusFilter(value)} selection value={jatszasiListaStatusFilter} options={jatszasiListaStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setDijszamitasiAdatokStatusFilter(value)} selection value={dijszamitasiAdatokStatusFilter} options={dijszamitasiAdatStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setFizetesStatusFilter(value)} selection value={fizetesStatusFilter} options={fizetveStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    getFilteredList().
                        map((idoszak) => <Table.Row positive={ idoszak.status === 'Elfogadott' } negative={ idoszak.jatszasiListaStatus === 'Aut. hibás' }>
                            <Table.Cell>{idoszak.id}</Table.Cell>
                            <Table.Cell>{idoszak.idoszak}</Table.Cell>
                            <Table.Cell>
                                { idoszak.felhasznaloNeve }
                                &nbsp;
                                {
                                    idoszak.korabbiNev === undefined
                                    ?
                                    ''
                                    :
                                    <Popup position='top center' content={ <span><b>Névváltozás</b>. A felhasználó korábbi neve <a>{ idoszak.korabbiNev }</a></span>} trigger={<Icon size='small' inverted color='yellow' circular name='history'/> }/>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <a onClick={ () => history.push('/sugarzasi/felhasznalok/1') } href='#'>{ idoszak.felhasznalo }</a>
                            </Table.Cell>
                            <Table.Cell>
                                <NavLink to={ idoszak.jatszasiListaStatus === 'Aut. hibás' 
                                              ? 
                                              '/jatszasilistak/jatszasilistaadatlapAutomataHibas' 
                                              :
                                              idoszak.jatszasiListaStatus === 'Feldolgozás alatt'
                                              ?
                                              '/jatszasilistak/jatszasilistaadatlap' 
                                              :
                                              '/jatszasilistak/jatszasilistaadatlapElfogadott'
                                            }>
                                    {
                                        idoszak.jatszasiListaStatus === 'Elfogadott'
                                            ?
                                            <Label basic color='green'><Icon name='check' />{idoszak.jatszasiListaStatus}</Label>
                                            :
                                            idoszak.jatszasiListaStatus === 'Aut. hibás'
                                                ?
                                                <Label basic color='red'><Icon name='warning sign' />{idoszak.jatszasiListaStatus}</Label>
                                                :
                                                idoszak.jatszasiListaStatus === 'Feldolgozás alatt'
                                                    ?
                                                    <Label basic color='yellow'>{idoszak.jatszasiListaStatus}</Label>
                                                    :
                                                    ''
                                    }
                                </NavLink>
                            </Table.Cell>
                            <Table.Cell>
                                <NavLink to={ idoszak.dijszamitasiAdatokStatus === 'Ellenőrzés alatt' ? '/jatszasilistak/dijszamitasiAdatok' : '#' }>
                                    {
                                        idoszak.dijszamitasiAdatokStatus === 'Elfogadott'
                                            ?
                                            <Label color='green' basic><Icon name='check' />{idoszak.dijszamitasiAdatokStatus}</Label>
                                            :
                                            idoszak.dijszamitasiAdatokStatus === 'Hibás'
                                                ?
                                                <Label color='red' basic><Icon name='delete' />{idoszak.dijszamitasiAdatokStatus}</Label>
                                                :
                                                idoszak.dijszamitasiAdatokStatus === 'Ellenőrzés alatt'
                                                    ?
                                                    <Label color='yellow' basic>{idoszak.dijszamitasiAdatokStatus}</Label>
                                                    :
                                                    ''
                                    }
                                </NavLink>
                            </Table.Cell>
                            <Table.Cell>
                                <NavLink to='/jatszasilistak/folyoszamla'>
                                {
                                    idoszak.fizetesStatus === 'Megfizetett'
                                        ?
                                        <Label color='green' basic><Icon name='check' />{idoszak.fizetesStatus}</Label>
                                        :
                                        idoszak.fizetesStatus === 'Késedelem'
                                            ?
                                            <Label color='red' basic><Icon name='clock' />{idoszak.fizetesStatus}</Label>
                                            :
                                            idoszak.fizetesStatus === 'Részben megfizetett'
                                                ?
                                                <Label color='yellow' basic>{idoszak.fizetesStatus}</Label>
                                                :
                                                idoszak.fizetesStatus === 'Értesített'
                                                ?
                                                <Label color='blue' basic><Icon name='mail outline' />{idoszak.fizetesStatus}</Label>
                                                :
                                                ''
                                }
                                </NavLink>
                            </Table.Cell>
                            <Table.Cell verticalAlign='center'>
                                <NavLink to='/jatszasilistak/folyoszamla'>
                                    <Button primary compact icon><Icon name='chart bar outline' /></Button>
                                </NavLink>
                            </Table.Cell>
                        </Table.Row>)
                }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='11'>
                        <b>Adminisztrátor</b> &nbsp;<Dropdown onChange={ (e, {value}) => setAdminisztratorFilter(value) } options={adminisztratorok} defaultValue={adminisztratorFilter} search selection placeholder='Adminisztrátor neve' />
                        &nbsp;
                        <b>Egyedi szűrés</b>
                        <Dropdown onChange={(e, { value }) => setSpecialisSzuresFilter(value)} selection value={specialisSzuresFilter} options={specialisSzures} />
                        <span style={{ paddingLeft: '120px', color: '#aaa' }}><b>{ lista.length }</b> tétel összesen a <b>5000</b>-ből.</span>
                        <Menu floated='right' pagination>
                            <Button onClick={ () => alert('Mockup verzióban nem elérhető.') }><Icon name='table' />Export</Button>
                        </Menu>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </>

};

export default AdatszolgaltatasLista;