import React, { useLocationt } from 'react';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import { NavLink, useLocation } from 'react-router-dom';

/**
 * Sidebar
 */
const Sidebar = () => {

    const location = useLocation();

    return  location.pathname.startsWith('/felhasznalo/')
        ?
        <>
        <Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/welcome'><span><Icon name='bullhorn'/> Összefoglalás</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/adatszolgaltatas'><span><Icon name='chart line'/> Adatszolgáltatás</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/uzenetek'><span><Icon name='mail outline'/> Üzenetek</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/egyenleg'><span><Icon name='bars'/> Egyenleg</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/profil'><span><Icon name='clipboard list'/> Profil</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/igazolasok'><span><Icon name='newspaper outline'/> Igazolások</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/kapcsolattartok'><span><Icon name='address card outline'/> Kapcsolattartók</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/szerzodes'><span><Icon name='handshake outline'/> Szerződés</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/letoltes'><span><Icon name='download'/> Letöltés</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/felhasznalo/reparadio/sugo'><span><Icon name='help'/> Súgó</span></Menu.Item>
        </Menu.Item>        
        </>
        :
        <>
        <Menu.Item>
            <Menu.Header><Icon name='rss'/> Sugárzási jogdíj</Menu.Header>
            <Menu.Item as={ NavLink } to='/sugarzasi/felhasznalok'><span><Icon name='users'/> Felhasználók</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/jatszasilistak'><span><Icon name='chart line'/> Adatszolgáltatás</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/igazolasok'><span><Icon name='newspaper outline'/> Igazolások</span></Menu.Item>
        </Menu.Item>
        <Menu.Item>
            <Menu.Header><Icon name='volume up'/> Háttérzene</Menu.Header>
            <Menu.Item as={ NavLink } to='/hatterzene/hatterzeneszolgaltatok'><span><Icon name='server'/> Szolgáltatók</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/hatterzene/felhasznalok'><span><Icon name='users'/> Felhasználók</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/hatterzene/jatszasilistak'><span><Icon name='chart line'/> Adatszolgáltatás</span></Menu.Item>
        </Menu.Item>
        <Menu.Item>
            <Menu.Header><Icon name='repeat'/> Ismétlési jogdíj</Menu.Header>
            <Menu.Item as={ NavLink } to='/ismetlesi/felhasznalok'><span><Icon name='users'/> Felhasználók</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/ismetlesi/idoszakok'><span><Icon name='chart line'/> Adatszolgáltatás</span></Menu.Item>
        </Menu.Item>
        <Menu.Item>
            <Menu.Header><Icon name='radio'/> Audiovizuális jogdíj</Menu.Header>
            <Menu.Item as={ NavLink } to='/audiovizualis/felhasznalok'><span><Icon name='users'/> Felhasználók</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/audiovizualis/idoszakok'><span><Icon name='chart line'/> Adatszolgáltatás</span></Menu.Item>
        </Menu.Item>        
        <Menu.Item>
            <Menu.Header><Icon name='file alternate outline'/>  Számlák & bank</Menu.Header>
            <Menu.Item as={ NavLink } to='/szamlazas/export'><span><Icon name='angle double right'/> Számlaexport</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/szamlazas/import'><span><Icon name='angle double left'/> Bank import</span></Menu.Item>
        </Menu.Item>        
        <Menu.Item>
            <Menu.Header><Icon name='search'/>  Keresés</Menu.Header>
            <Menu.Item as={ NavLink } to='/lekerdezesek'><span><Icon name='eye'/> Lekérdezések</span></Menu.Item>
            <Menu.Item as={ NavLink } to='/ertesitokeresese'><span><Icon name='search'/> Értesítő keresése</span></Menu.Item>
        </Menu.Item>
        <Menu.Item>
            <Menu.Header><Icon name='cogs'/>  Eszközök</Menu.Header>
            <Menu.Item as={ NavLink } to='/letoltes'><span><Icon name='download'/> Letöltés</span></Menu.Item>
        </Menu.Item>
        </>
};

export default Sidebar;