import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Statistic, Tab, Step, Progress, FormButton, Form } from 'semantic-ui-react';

/**
 * Jatszasi lista adatlap.
 */
const JatszasiListaKezdokepernyo = ( { step, setStep } ) => {

    return <Segment basic>
        <h3>Játszási lista ellenőrzése</h3>
        <p>
        A játszási listát ellenőrizni kell mind formai, mind tartalmi, mind statisztikai szempontból elfogadás előtt. Kérjük, haladjon végig ezen a folyamaton és a végén fogadja el - vagy utasítsa el - a játszási listát az ismert protokollok alapján.
        </p>
        <p>
            <b>Elfogadott</b> státuszt kizárólag a <b>folyamat befejezése után</b> adhat a játszási listának.
        </p>
        <center>
            <Button onClick={ () => setStep( step + 1 ) } primary icon><Icon name='magnify'/>Ellenőrzés</Button>
        </center>
    </Segment>

}

export default JatszasiListaKezdokepernyo;