import React, { useState } from 'react';
import { Form, Label, Icon } from 'semantic-ui-react';
import FeltetlenElutasitas from '../../../jatszasilistak/adatszolgaltatas/subcomponents/wizard/FeltetlenElutasitas'
import EllenorizendoElutasitas from '../../../jatszasilistak/adatszolgaltatas/subcomponents/wizard/EllenorizendoElutasitas'
import EgyebElteres from '../../../jatszasilistak/adatszolgaltatas/subcomponents/wizard/EgyebElteres'
import Statisztika from '../../../jatszasilistak/adatszolgaltatas/subcomponents/wizard/Statisztika'

/**
 * Statisztika.
 */
const StatisztikaComponent = () => {

    // Tab
    const [step, setStep] = useState(1);

    return <>
        <Form>
            <Form.Group>
                <Form.Button onClick={() => setStep(step - 1)} disabled={step === 1} icon><Icon name='chevron left' /><b>Előző lépés</b></Form.Button>
                <Label basic style={{ paddingTop: '12px' }}>
                    <Icon name='point' color='red' />
                    {
                        step === 1
                            ?
                            'Feltétlen elutasítási okok'
                            :
                            step === 2
                                ?
                                'Ellenőrizendő elutasítási okok'
                                :
                                step === 3
                                    ?
                                    'Egyéb eltérés'
                                    :
                                    'Statisztika'
                    }
                    {
                        step > 0
                            ?
                            <>
                                &nbsp; ({step} / 4)
                            </>
                            :
                            ''
                    }
                </Label>
                <Form.Button disabled={step === 4} onClick={() => setStep(step + 1)} icon><b>Következő lépés</b><Icon name='chevron right' /></Form.Button>
            </Form.Group>
        </Form>
        {
            step === 1
                ?
                <FeltetlenElutasitas />
                :
                step === 2
                    ?
                    <EllenorizendoElutasitas />
                    :
                    step === 3
                        ?
                        <EgyebElteres />
                        :
                        <Statisztika />
        }
    </>
}

export default StatisztikaComponent;