import React, {  } from 'react';
import { Button, Menu, Icon, Dropdown, Popup, Segment, Tab, Grid, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import NaploComponent from './subcomponents/naploComponent'
import StatisztikaComponent from './subcomponents/statisztikaComponent'

/**
 * Background music datasheet.
 */
const HatterzeneAdatszolgaltatasAdatlap = () => {

    // Possible statuses
    const statuszok = [
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
    ]

    const panes = [
        {
            menuItem: <Menu.Item><Icon name='chart pie' /> Statisztika</Menu.Item>,
            render: () => <StatisztikaComponent/>,
        },
        {
            menuItem: <Menu.Item><Icon name='history' /> Napló</Menu.Item>,
            render: () => <NaploComponent />,
        }
    ]    

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='yellow'>
            <b><Icon name='check' />Feldolgozás alatti</b> játszási lista <b>Répa Rádió</b> (Zöldség kft.) részére a <b>2022 / 1.</b> negyedévre háttérzenére.
        </Segment>
        <Grid columns={5} style={{ marginTop: '24px' }}>
            <Grid.Row>
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='yellow' style={{ marginTop: '-8px' }}>
                        <Button compact>Feldolgozás alatt</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            onChange={() => { alert('Mockup módban nem elérhető.') }}
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Feltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <b>Fressne & Gefressen kft.</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Adminisztrátor
                </Grid.Column>
                <Grid.Column>
                    <b>Nagy János</b>
                    <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='exchange' /></Button>} content='Adminisztrátor cseréje' />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó playlist azonosító
                </Grid.Column>
                <Grid.Column>
                    <b>1901</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Kapcsolattartó
                </Grid.Column>
                <Grid.Column>
                    <b>Kovács Rozmaringné</b>
                    <Button.Group>
                        <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
                        <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={ <span>Telefon: <b>06 1 555 5555</b></span> } />
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>            
        </Grid>
        <Divider/>
        <NavLink to='/hatterzene/jatszasilistak'><Button floated='right'>Vissza</Button></NavLink>
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'/> Játszási lista letöltése</Button>
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='history'/></Button>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'/> Játszási lista visszatöltése</Button>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: '12px' }} />
    </Segment>

}

export default HatterzeneAdatszolgaltatasAdatlap;