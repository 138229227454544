import React from 'react';
import { Button, Checkbox, Divider, Dropdown, Grid, Icon, Input, Label, Menu, Message, Segment, Tab, Table } from 'semantic-ui-react';
import CimekComponent from '../../felhasznalok/subcomponents/cimekComponent'
import SajatCsatornaAdminokComponent from './SajatCsatornaAdminokComponent';
import TevekenysegekComponent from '../../jatszasilistak/adatszolgaltatas/subcomponents/TevekenysegekComponent'
import MainDataComponent from './MainDataComponent'

/**
 * Adatok & kapcsolattartok
 */
const FelhasznaloProfil = () => {

    const panes = [
        {
            menuItem: <Menu.Item><Icon name='id card outline' /> Alapadatok</Menu.Item>,
            render: () => <MainDataComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='rss' /> Sugárzási jogdíj</Menu.Item>,
            render: () => <>
                <TevekenysegekComponent />
                <Divider/>
                <Checkbox toggle checked label='Díjszámítási adatok rejtettek feltöltést követően.'/>
            </>,
        },        
        {
            menuItem: <Menu.Item><Icon name='home' /> Címek & Kapcsolat</Menu.Item>,
            render: () => <CimekComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='users' /> Saját adminisztrátorok</Menu.Item>,
            render: () => <SajatCsatornaAdminokComponent />,
        }        
    ]    

    return <Segment basic>
        <h3><Icon name='clipboard list' /> Felhasználói & csatorna adatok</h3>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: '18px' }} />
        <Divider/>
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') }>Mentés</Button>
    </Segment>
};

export default FelhasznaloProfil;