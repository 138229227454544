import React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Label, Button, Icon, Popup } from 'semantic-ui-react';

/**
 * Adatszolgaltatas naplo.
 */
const AdatszolgaltatasNaploComponent = () => <>
    <Table striped selectable>
        <Table.Header>
            <Table.HeaderCell>Adminisztrátor/felhasználó</Table.HeaderCell>
            <Table.HeaderCell>Megjegyzés</Table.HeaderCell>
            <Table.HeaderCell>Dátum</Table.HeaderCell>
            <Table.HeaderCell>Státusz</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
            <Table.Row>
                <Table.Cell>
                    Nagy János
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    2022.03.14 14:4:03
                </Table.Cell>
                <Table.Cell>
                    <Label color='yellow'>Tartalmi módosítás online</Label>
                </Table.Cell>
                <Table.Cell>
                    <Popup trigger={ <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'></Icon></Button> } content='Játszási lista letöltése (hatályos verzió)' />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Nagy János
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    2022.03.14 14:44:01
                </Table.Cell>
                <Table.Cell>
                    <Label basic color='green'>Formailag elfogadott</Label>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Automata ellenőrzés
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    2022.03.14 14:43:00
                </Table.Cell>
                <Table.Cell>
                    <Label basic color='green'>Előellenőrzés sikeres</Label>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Kovácsné Lajos
                </Table.Cell>
                <Table.Cell style={{ color: '#888' }}>
                    Excelben küldöm szeretettel.
                </Table.Cell>
                <Table.Cell>
                    2022.03.12 12:41:01
                </Table.Cell>
                <Table.Cell>
                    <Label basic color='green'>Játszási lista feltöltés</Label>
                </Table.Cell>
                <Table.Cell>
                    <Popup trigger={ <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'></Icon></Button> } content='Játszási lista letöltése (2022.03.12 12:41:01 állapot)' />
                </Table.Cell>                
            </Table.Row>
        </Table.Body>
    </Table>
    <NavLink to='/jatszasilistak/jatszasilistaadatlap'><Button floated='right'>Vissza</Button></NavLink>
</>

export default AdatszolgaltatasNaploComponent;