import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Egyeb komponens.
 */
const EgyebComponent = () => {

    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <Form>
                    {
                        /**
                         * Innen van amit at kell szervezni csatorna szintre, ill. ez az "Egyéb" elég döcögős itt
                         */
                    }
                    <Form.Checkbox toggle label='A csatorna TV' />
                    <Form.Checkbox toggle label='A csatorna rádió' />
                    <Form.Checkbox toggle label='Közszolgálati hozzájárulásra jogosult' />
                    <Form.Checkbox toggle label='HTOE' />
                    <Form.Checkbox toggle label='H kliprotáció' />
                    <Form.Checkbox toggle label='Egyéb okból nincs automatikus díjszámítás' />
                </Form>
            </Grid.Column>
        </Grid.Row>
    </Grid>

};

export default EgyebComponent;