import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Form, Checkbox, Message } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Alapadatok
 */
const MainDataComponent = () => {

    return <>
        <Grid columns={'equal'}>
            <Grid.Row>
                <Grid.Column>Felhasználó neve</Grid.Column>
                <Grid.Column><b>Zöldség kft.</b></Grid.Column>
                <Grid.Column></Grid.Column>
                <Grid.Column>
                    Felhasználás kezdete
                </Grid.Column>
                <Grid.Column><b>2022.05.01</b></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={{ paddingTop: '8px' }}>Csatorna neve</Grid.Column>
                <Grid.Column><Input fluid defaultValue='Répa Rádió'/></Grid.Column>
                <Grid.Column></Grid.Column>
                <Grid.Column style={{ paddingTop: '8px' }}>Csatorna weboldala</Grid.Column>
                <Grid.Column><Input fluid defaultValue='www.reparadio.hu'/></Grid.Column>
            </Grid.Row>            
        </Grid>
        <Message info>
            <Icon name='info circle'/> A felhasználóhoz tartozó adminisztártorok listáját a <i>Saját adminisztrátorok</i> fülön találja.
        </Message>
    </>

}

export default MainDataComponent;