import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Import settings.
 */
const ImportSettings = () => {

    return <Segment basic style={{ marginTop: '-12px' }}>
        <Form>
            <Form.Dropdown width={4} label='Alapértelmezett import formátum' defaultValue='1' options={ [{ value: '1', key: '1', text: 'OTP Electra'}, { key: '2', text: 'OTP UMS'}] } selection />
            <Form.Dropdown width={4} label='Kódlap' defaultValue='1' options={ [{ value: '1', key: '1', text: 'UTF-8'}, { key: '2', text: 'ANSI'}] } selection />
        </Form>
    </Segment>
}

export default ImportSettings;