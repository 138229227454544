import React, {  } from 'react';
import { Table, Label } from 'semantic-ui-react';

/**
 * Naplo.
 */
const NaploComponent = () => {

    return <Table striped selectable>
            <Table.Header>
                <Table.HeaderCell>Adminisztrátor/felhasználó</Table.HeaderCell>
                <Table.HeaderCell>Megjegyzés</Table.HeaderCell>
                <Table.HeaderCell>Dátum</Table.HeaderCell>
                <Table.HeaderCell>Státusz</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
            <Table.Row>
                    <Table.Cell>
                        Automata értesítés
                    </Table.Cell>
                    <Table.Cell style={{ color: '#888' }}>
                        E-mail értesítés
                    </Table.Cell>
                    <Table.Cell>
                        2022.03.12 12:41:02
                    </Table.Cell>
                    <Table.Cell>
                        <Label basic color='grey'>Értesítés elküldve</Label>
                    </Table.Cell>
                </Table.Row>                
                <Table.Row>
                    <Table.Cell>
                        kovacsne_lapos_role
                    </Table.Cell>
                    <Table.Cell style={{ color: '#888' }}>
                        Feltöltés API-n keresztül.
                    </Table.Cell>
                    <Table.Cell>
                        2022.03.12 12:41:01
                    </Table.Cell>
                    <Table.Cell>
                        <Label basic color='green'>Játszási lista feltöltés</Label>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table> 
}

export default NaploComponent;