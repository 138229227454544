import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import TevekenysegekComponent from './subcomponents/TevekenysegekComponent'
import KedvezmenyekComponent from './subcomponents/KedvezmenyekComponent'
import NaploComponent from './subcomponents/NaploComponent'
import EgyebComponent from './subcomponents/EgyebComponent'
import DijszamitasiAdatokComponent from './subcomponents/DijszamitasiAdatokComponent'

/**
 * Dijszamitasi adatok adatlap.
 */
const DijszamitasiAdatok = () => {

    const statuszok = [
        {
            key: 'Ellenőrzés alatt',
            text: 'Ellenőrzés alatt',
            value: 'Ellenőrzés alatt'
        },
        {
            key: 'Elutasítva',
            text: 'Elutasítva',
            value: 'Elutasítva'
        },
        {
            key: 'Elfogadva',
            text: 'Elfogadva',
            value: 'Elfogadva'
        },
    ]

    const panelek = [
        {
            menuItem: <Menu.Item><Icon name='clipboard outline' /> Tevékenységek</Menu.Item>,
            render: () => <Tab.Pane attached={false}><TevekenysegekComponent showWarning /></Tab.Pane>,
        },
        {
            menuItem: <Menu.Item><Icon name='calculator' />Kedvezmények</Menu.Item>,
            render: () => <Tab.Pane attached={false}><KedvezmenyekComponent /></Tab.Pane>,
        },
        {
            menuItem: <Menu.Item><Icon name='paperclip' />Feltöltött fájlok</Menu.Item>,
            render: () => <Tab.Pane attached={false}><DijszamitasiAdatokComponent /></Tab.Pane>,
        },
        {
            menuItem: <Menu.Item><Icon name='cogs' />Egyéb</Menu.Item>,
            render: () => <Tab.Pane attached={false}><EgyebComponent /></Tab.Pane>,
        },
        {
            menuItem: <Menu.Item><Icon name='history' />Napló</Menu.Item>,
            render: () => <NaploComponent />,
        },
    ]

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='yellow'><b><Icon name='eye' />Ellenőrzés alatt</b> álló díjszámítási adatok a <b>Répa Rádió</b> (Zöldség kft.) részére a <b>2022 / 1.</b> negyedévre sugárzási jogdíjra.</Segment>
        <Divider />
        <Grid style={{ paddingTop: '12px' }}>
            <Grid.Row style={{ marginBottom: '-8px' }} columns={5} >
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='yellow'>
                        <Button compact>Ellenőrzés alatt</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Beltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} >
                <Grid.Column style={{ paddingTop: '8px' }}>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <div style={{ whiteSpace: 'nowrap' }}>
                        Zöldség kft.
                    </div>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Adminisztrátor
                </Grid.Column>
                <Grid.Column>
                    <b>Nagy János</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5}>
                <Grid.Column>
                    Felhasználó playlist azonosító
                </Grid.Column>
                <Grid.Column>
                    <b>1</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Kapcsolattartó
                </Grid.Column>
                <Grid.Column>
                    <b>Kovács Rozmaringné</b>
                    <Button.Group>
                        <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
                        <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={ <span>Telefon: <b>06 1 555 5555</b></span> } />
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>             
            <Divider />
            <Grid.Row columns={4}>
                <Grid.Column>
                    <b>Kereskedelmi bevételek (Ft)</b>
                </Grid.Column>
                <Grid.Column>
                    <Input action={ <><Button.Group>
                        <Popup content='Előző negyedévvel összevetve' trigger={ <Button compact color='green'>2021Q4 8%</Button> }/>
                        </Button.Group>
                        &nbsp;
                        <Button.Group>
                        <Popup content='Előző év azonos negyedévével összevetve' trigger={ <Button compact color='red'>2021Q1 16%</Button> } />
                    </Button.Group></>} style={{ marginTop: '-12px' }} value='520.000' />
                </Grid.Column>
                <Grid.Column textAlign='right'>
                    ebből kábeltévés (Ft)
                </Grid.Column>
                <Grid.Column>
                    <Input action={ <><Button.Group>
                        <Button color='green' compact>2021Q1 9%</Button>
                        </Button.Group>
                        &nbsp;
                        <Button.Group>
                        <Button color='green' compact>2021Q4 7%</Button>
                    </Button.Group></>} style={{ marginTop: '-12px', width: '100px' }} value='31.110' />
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns={4} >
                <Grid.Column>
                    <b>Közszolgálati hozzájárulás (Ft)</b>
                </Grid.Column>
                <Grid.Column>
                    <Input action={ <><Button.Group>
                        <Button color='green' compact>2021Q4 8%</Button>
                        </Button.Group>
                        &nbsp;
                        <Button.Group>
                        <Button color='green' compact>2021Q1 -3%</Button>
                    </Button.Group></>} style={{ marginTop: '-12px' }} value='100.000' />
                </Grid.Column>
                <Grid.Column></Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} >
                <Grid.Column>
                    <b>Egyéb támogatás (Ft)</b>
                </Grid.Column>
                <Grid.Column>
                    <Input action={ <><Button.Group>
                        <Button color='green' compact>2021Q4 8%</Button>
                        </Button.Group>
                        &nbsp;
                        <Button.Group>
                        <Button color='green' compact>2021Q1 -6%</Button>
                    </Button.Group></>}
                     style={{ marginTop: '-12px' }} value='50.000' />
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns={4} >
                <Grid.Column>
                    <b>Magyar felvételek aránya az összes hangfelvételhez viszonyítva (%)</b>
                </Grid.Column>
                <Grid.Column>
                    <Input type='number' max={100} min={0} compact style={{ marginTop: '4px' }} defaultValue={52} />
                </Grid.Column>
                <Grid.Column style={{ paddingTop:'8px' }}>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>                
            </Grid.Row>
            <Divider />
        </Grid>
        <Tab menu={{ secondary: true, pointing: true, color: 'blue' }} panes={panelek} />
        <Divider />
        <NavLink onClick={ () => alert('Mockup verzióban nem elérhető.') } to='/jatszasilistak'><Button primary>Mentés</Button></NavLink>
        <NavLink to='/jatszasilistak'><Button floated='right'>Vissza</Button></NavLink>
    </Segment>

};

export default DijszamitasiAdatok;