import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Kedvezmenyek komponens.
 */
const KedvezmenyekComponent = ({ disabled }) => {

    const statuszok = [
        {
            key: 'Ellenőrzés alatt',
            text: 'Ellenőrzés alatt',
            value: 'Ellenőrzés alatt'
        },
        {
            key: 'Elutasítva',
            text: 'Elutasítva',
            value: 'Elutasítva'
        },
        {
            key: 'Elfogadva',
            text: 'Elfogadva',
            value: 'Elfogadva'
        }        
    ]

    return <Grid>
        <Grid.Row>
            <Grid.Column width={7}>
                <Form>
                    <Form.Checkbox disabled={ disabled !== undefined } toggle label='Magyar felvételek utáni kedvezmény' />
                    <Form.Checkbox disabled={ disabled !== undefined } toggle label='Magyar felvételek kiadási éve utáni kedvezmény' />
                    <Form.Checkbox disabled={ disabled !== undefined } toggle label='Magyar felvételek sugárzási időpontja utáni kedvezmény' />
                    <Form.Checkbox disabled={ disabled !== undefined } toggle label='Egyszerűsített ellenőrzés utáni kedvezmény' />
                </Form>
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
            <Grid.Column width={7}>
                <Form>
                    <Form.TextArea label='Megjegyzés' />
                </Form>
            </Grid.Column>
        </Grid.Row>
    </Grid>

};

export default KedvezmenyekComponent;