import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Form } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Hatterzeneszolgaltato felhasznaloi.
 */
const HatterzeneFelhasznalokComponent = () => {

    const adminok = [
        { id:1901,org: 'Fressen & Gefressen Kft.', date: '2022.04.21 12:00:00' },
        { id:1902,org: 'Hotel Káptalan Kft.', date: '2022.04.21 12:00:00' },
    ]

    return <>
            <Table striped selectable>
                <Table.Header>
                    <Table.HeaderCell width={1}>
                        Id
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Felhasználó neve
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Létrehozva
                    </Table.HeaderCell>        
                    <Table.HeaderCell>
                        Státusz
                    </Table.HeaderCell>                                        
                </Table.Header>
                <Table.Body>
                {
                    adminok.map( (admin, index) => <Table.Row key={ index }>
                        <Table.Cell>
                            { admin.id }
                        </Table.Cell>   
                        <Table.Cell>
                            <NavLink to='/hatterzene/felhasznalok/1'><a href='#'>{ admin.org }</a></NavLink>
                        </Table.Cell>
                        <Table.Cell>
                            { admin.date }
                        </Table.Cell>   
                        <Table.Cell>
                            Aktív
                        </Table.Cell>   
                    </Table.Row>)
                }
                </Table.Body>
            </Table>
    </>
};

export default HatterzeneFelhasznalokComponent;