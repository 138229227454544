import React, { useState } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Segment, Table, Icon, Dropdown, Input, Button, Popup, Label } from 'semantic-ui-react';

/**
 * Lots of data from the user.
 */
const FelhasznaloiAdatszolgaltatas = () => {

    const lista = [
        {
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 3',
            csatorna: 'Répa Rádió',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Hiányzik',
            dijszamitasiAdatokStatus: 'Hiányzik',
            fizetesStatus: '-',
            filename: '-',
            dijfizetesiErtesitoLink: '#',
            listaStatus: '-'
        },
        {
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 3',
            csatorna: 'Kukorica Jancsi Rádió',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Hiányzik',
            dijszamitasiAdatokStatus: 'Hiányzik',
            fizetesStatus: '-',
            filename: '-',
            dijfizetesiErtesitoLink: '#',
            listaStatus: '-'
        },
        {
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 2',
            csatorna: 'Répa Rádió',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Feldolgozás alatt',
            dijszamitasiAdatokStatus: 'Ellenőrzés alatt',
            fizetesStatus: '-',
            filename: 'RepaRadio_2022Q2.xlsx',
            dijfizetesiErtesitoLink: '#',
            listaStatus: 'Feldolgozás alatt'
        },
        {
            adminisztrator: 'Adminisztrátor 1',
            idoszak: '2022 / 2',
            csatorna: 'Kukorica Jancsi Radio',
            filename: 'Johncorn_2022Q2.xlsx',
            felhasznalo: 'Zöldség kft.',
            jatszasiListaStatus: 'Elfogadott',
            dijszamitasiAdatokStatus: 'Elfogadott',
            fizetesStatus: 'Megfizetve',
            dijfizetesiErtesitoLink: '#',
            listaStatus: 'Elfogadott'
        },
    ]

    const fizetveStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Értesített',
            text: 'Értesített',
            value: 'Értesített'
        },
        {
            key: 'Részben megfizetett',
            text: 'Részben megfizetett',
            value: 'Részben megfizetett'
        },
        {
            key: 'Megfizetett',
            text: 'Megfizetett',
            value: 'Megfizetett'
        },
        {
            key: 'Késedelem',
            text: 'Késedelem',
            value: 'Késedelem'
        }
    ]

    const dijszamitasiAdatStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Ellenőrzés alatt',
            text: 'Ellenőrzés alatt',
            value: 'Ellenőrzés alatt'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
    ]

    const jatszasiListaStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
        {
            key: 'Gyorsított elfogadás',
            text: 'Gyorsított elfogadás',
            value: 'Gyorsított elfogadás'
        },
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        }
    ]

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];

    const [idoszakFilter, setIdoszakFilter] = useState('Mind');
    const [jatszasiListaStatusFilter, setJatszasiListaStatusFilter] = useState('Mind');
    const [fizetesStatusFilter, setFizetesStatusFilter] = useState('Mind');

    return <>
        <Table striped selectable sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ background: '#eee' }}>Időszak <Icon name='angle down' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Csatorna <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Játszási lista <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Lista státusz <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Díjszámítás státusz <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Fizetési státusz <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                    <Table.HeaderCell style={{ background: '#eee' }}>Gyorsmenü <Icon name='angle down' color='grey' /><Icon name='angle up' color='grey' /></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating labeled onChange={(e, { value }) => setIdoszakFilter(value)} value={idoszakFilter} selection options={idoszakokStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Csatorna' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setJatszasiListaStatusFilter(value)} selection value={jatszasiListaStatusFilter} options={jatszasiListaStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setJatszasiListaStatusFilter(value)} selection value={jatszasiListaStatusFilter} options={jatszasiListaStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setJatszasiListaStatusFilter(value)} selection value={jatszasiListaStatusFilter} options={jatszasiListaStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown onChange={(e, { value }) => setFizetesStatusFilter(value)} selection value={fizetesStatusFilter} options={fizetveStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {
                lista.map((idoszak, index) => <Table.Row warning={idoszak.listaStatus == 'Feldolgozás alatt'} positive={idoszak.dijszamitasiAdatokStatus == 'Elfogadott'}>
                    <Table.Cell>
                        {idoszak.idoszak}
                    </Table.Cell>
                    <Table.Cell>
                        <b>{idoszak.csatorna}</b>
                    </Table.Cell>
                    <Table.Cell>
                        {
                            idoszak.filename === '-'
                            ?
                            <NavLink to='/felhasznalo/reparadio/ujfeltoltes'>
                                Kattintson a feltöltéshez
                            </NavLink>
                            :
                            <>
                                <a href='#'>
                                    {idoszak.filename}
                                </a>
                                &nbsp;
                                <Popup content='Játszási lista (újra) feltöltése. Amíg a lista nem került elfogadásra, az újrafeltöltés lehetséges.' trigger={<NavLink to='/felhasznalo/reparadio/feltoltes'><Button disabled={idoszak.dijszamitasiAdatokStatus == 'Elfogadott'} compact icon><Icon name='paperclip' /></Button></NavLink>} />                                
                            </>
                        }
                    </Table.Cell>
                    <Table.Cell>
                        {
                            idoszak.jatszasiListaStatus === 'Hiányzik'
                                ?
                                <Label basic color='grey'>Hiányzik</Label>
                                :
                                <NavLink to='/felhasznalo/reparadio/feltoltes'>
                                    <Label basic color={idoszak.listaStatus == 'Feldolgozás alatt' ? 'yellow' : 'green'}>{idoszak.listaStatus}</Label>
                                </NavLink>
                        }
                    </Table.Cell>
                    <Table.Cell>
                        <NavLink to='/felhasznalo/reparadio/dijszamitasiadatok'>
                            {
                                idoszak.dijszamitasiAdatokStatus === 'Hiányzik'
                                    ?
                                    <NavLink to='/felhasznalo/reparadio/dijszamitasiadatok'>Kattintson a feltöltéshez</NavLink>
                                    :
                                    <Label basic color={idoszak.listaStatus == 'Feldolgozás alatt' ? 'yellow' : 'green'}>{idoszak.dijszamitasiAdatokStatus}</Label>
                            }
                        </NavLink>
                    </Table.Cell>
                    <Table.Cell>
                        {idoszak.fizetesStatus}
                    </Table.Cell>
                    <Table.Cell>
                        <Popup content='Díjfizetési értesítő letöltése'
                            trigger={<Button compact icon primary>
                                <Icon name='download' />
                            </Button>}
                        ></Popup>
                    </Table.Cell>
                </Table.Row>)
            }
        </Table>
    </>
};

export default FelhasznaloiAdatszolgaltatas;