import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header, Message } from 'semantic-ui-react';
import NyugtaLista from './NyugtaLista';
/**
 * Szamla export lista.
 */
const WannabeInvoicesList = () => {

    return <Segment basic style={{ marginTop: '-12px' }}>
        Válassza ki azokat a tételeket, melyekből a számlázóprogramban számlákat kíván készíteni, majd kattintson az <i>Export</i> gombra a lap alján.
        <NyugtaLista/>
        <Divider />
        <Grid columns='equal'>
            <Grid.Column>
                <Header size='small'>Érték összesen</Header>
                <Header size='large' style={{ paddingTop: '-20px', margin: '0px' }}>
                    150.000 Ft + ÁFA
                </Header>
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
            <Grid.Column>
                <Header size='small'>Darabszám</Header>
                <Header size='large' style={{ padding: '0px', margin: '0px' }}>
                    2 db
                </Header>
            </Grid.Column>
        </Grid>
        <Divider/>
        <Button icon primary onClick={ () => alert('Mockup módban nem elérhető.') }>
            <Icon name='table'/>
            &nbsp;
            Export
        </Button>
        <Message info>
            <Icon name='info circle'/> Az exportált fájlt mentse el, és importálja számlázó programjába. A jelenleg kiválasztott formátum: <b>KulcsSzámla Prémium</b>. A <i>Beállítások</i> menüpontban megváltoztathatja az ismert számlázórogram formátumok bármelyikére.
        </Message>
    </Segment>
}

export default WannabeInvoicesList;