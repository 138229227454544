import React, { useState } from 'react';
import { Icon, Tab, Menu } from 'semantic-ui-react';
import ImportHistory from './subcomponents/ImportHistory'
import ImportSettings from './subcomponents/ImportSettings'
import BankImport from './subcomponents/BankImport'
import BankSelectFile from './subcomponents/BankSelectFile'
import BankFinalizeImport from './subcomponents/BankFinalizeImport'

/**
 * Bank import komponens.
 */
const BankImportComponent = () => {

    const [page, setPage] = useState(0);

    const panes = [
        {
            menuItem: <Menu.Item><Icon name='angle double right' /> Import</Menu.Item>,
            render: () => <BankImportWorkflow/>
        },
        {
            menuItem: <Menu.Item><Icon name='history' /> Napló</Menu.Item>,
            render: () => <ImportHistory/>
        },        
        {
            menuItem: <Menu.Item><Icon name='cogs' /> Beállítások</Menu.Item>,
            render: () => <ImportSettings/>
        }
    ];

    const BankImportWorkflow = () => {
        return page === 0
        ?
        <BankSelectFile setPage={ setPage }/>
        :
        page === 1
        ?
        <BankImport setPage={ setPage } />
        :
        <BankFinalizeImport setPage={ setPage } />
    };

    return <>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: '0px' }} />
    </>
};

export default BankImportComponent;