import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Statistic, Tab, Step, Progress, FormButton, Form } from 'semantic-ui-react';

/**
 * Jatszasi lista befejezo kepernyo.
 */
const JatszasiListaBefejezes = ({ step, setStep }) => {

    return <Segment basic>
        <h3>Elfogadás & Elutasítás</h3>
        <p>
            Automatikus feldolgozás során <b>1 darab ellenőrizendő elutasítási</b> okot találtunk.
        </p>
        <p>
            A megismert információk ismeretében kérem állítsa be a játszási lista státuszát.
        </p>        
        <Grid columns='equal'>
            <Grid.Column></Grid.Column>
            <Grid.Column textAlign='center'>
                <Form>
                    <Form.Group>
                        <Form.Button onClick={ () => alert('Mockup módban nem elérhető.') } icon color='green'><Icon name='check'/> Elfogadom</Form.Button>
                        <Form.Button onClick={ () => alert('Mockup módban nem elérhető, indoklás megadása a Megjegyzés mezőben kötelező.') } icon color='red'><Icon name='remove'/> Hibásra állítom</Form.Button>
                    </Form.Group>
                </Form>
            </Grid.Column>
            <Grid.Column></Grid.Column>
        </Grid>
        <Form>
            <Form.Input placeholder='Indoklás (hibás esetben kötelező)' label='Megjegyzés'></Form.Input>
        </Form>
    </Segment>

}

export default JatszasiListaBefejezes;