import React from 'react';
import { Button, Divider, Dropdown, Grid, Icon, Input, Label, Statistic, Segment, Table, Message } from 'semantic-ui-react';

/**
 * Egyenleg
 */
const Egyenleg = () => {

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },        
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];  

    return <Segment basic>
        <h3><Icon name='bars' /> Egyenleg</h3>
        <Statistic.Group>
            <Statistic horizontal color='green'>
                <Statistic.Value>32.500</Statistic.Value>
                <Statistic.Label>Ft</Statistic.Label>
            </Statistic>
        </Statistic.Group>
        <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Időszak / megnevezés</Table.HeaderCell>
                        <Table.HeaderCell>Bizonylat</Table.HeaderCell>
                        <Table.HeaderCell>Bizonylat összege</Table.HeaderCell>
                        <Table.HeaderCell>Könyvelt összeg</Table.HeaderCell>
                        <Table.HeaderCell>Állapota</Table.HeaderCell>
                        <Table.HeaderCell>Könyvelve</Table.HeaderCell>
                        <Table.HeaderCell>Megjegyzés</Table.HeaderCell>
                        <Table.HeaderCell>Fizetés</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Dropdown floating labeled defaultValue={'Mind'} selection options={idoszakokStatus} fluid />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input icon={'filter'} placeholder='Bizonylat' iconPosition='left' fluid />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input icon={'filter'} placeholder='Bizonylat összeg' iconPosition='left' fluid />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input icon={'filter'} placeholder='Könyvelt összeg' iconPosition='left' fluid />
                        </Table.HeaderCell>                                                
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Dropdown floating placeholder='Mind' labeled selection fluid />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Dropdown floating placeholder='Mind' labeled selection fluid />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                            <Input icon={'filter'} placeholder='Megjegyzés' iconPosition='left' fluid />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            2022 / 2. negyedév
                        </Table.Cell>
                        <Table.Cell>
                            PL-EJI-2021-55 <Label>Előleg</Label><Button floated='right' compact icon primary><Icon name='download'/></Button>
                        </Table.Cell>                        
                        <Table.Cell>
                            100.000 Ft + ÁFA<br/>
                            <b>127.000 Ft (br.)</b>
                        </Table.Cell>
                        <Table.Cell>
                            -
                        </Table.Cell>                        
                        <Table.Cell>
                            <Label color='yellow'>Értesített</Label>
                        </Table.Cell>
                        <Table.Cell>
                            -
                        </Table.Cell>
                        <Table.Cell>
                            -
                        </Table.Cell>    
                        <Table.Cell>
                            <Button onClick={ () => alert('Mockup módban nem tudja kifizetni bankkártyával az adott összeget.') } icon primary><Icon name='credit card'/></Button>
                        </Table.Cell>                                                                                
                    </Table.Row>                    
                    <Table.Row positive>
                        <Table.Cell>
                            2022 / 1. negyedév
                        </Table.Cell>
                        <Table.Cell>
                            PL-EJI-2021-41
                            <Button floated='right' compact icon primary><Icon name='download'/></Button>
                        </Table.Cell>                        
                        <Table.Cell>
                            100.000 Ft + ÁFA<br/>
                            <b>127.000 Ft (br.)</b>
                        </Table.Cell>
                        <Table.Cell>
                            100.000 Ft + ÁFA<br/>
                            <b>127.000 Ft (br.)</b>
                        </Table.Cell>                        
                        <Table.Cell>
                            <Label color='blue'>Megfizetett</Label>
                        </Table.Cell>
                        <Table.Cell>
                            2021.12.04
                        </Table.Cell>
                        <Table.Cell>
                            -
                        </Table.Cell> 
                        <Table.Cell>
                            <Button disabled onClick={ () => alert('Mockup módban nem tudja kifizetni bankkártyával az adott összeget.') } icon primary><Icon name='credit card'/></Button>
                        </Table.Cell>                                                                                    
                    </Table.Row>
                </Table.Body>
            </Table>     
            <Message info>
                <Button onClick={ () => alert('Mockup módban nem elérhető.') } style={{ marginTop: '-6px', marginRight: '-0px' }} compact floated='right' primary>Rendelkezés</Button>
                Túlfizetését visszautaljuk bankszámlájára. Amennyiben az összeget egyenlegén kívánja hagyni, kattintson a <i>Rendelkezés</i> gombra.
            </Message>             
    </Segment>
};

export default Egyenleg;