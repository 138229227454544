import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Divider, Dropdown, Grid, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

/**
 * Certs page.
 */
const Igazolasok = () => {

    return <Segment basic>
        <h3><Icon name='newspaper outline' />Igazolások</h3>
        <p>
            Itt kérheti annak igazolását, hogy a szomszédos jogi jogdíjakat az EJI és a MAHASZ közös díjközleménye, illetve a felhasználási szerződésben rögzített határidőn belül megfizette, tartozása nincsen. Korábban kiállított igazolásait az oldal alján található listából töltheti le.
        </p>
        <Message error>
            <Icon name='warning sign' />
            <b>Nem igényelhet igazolást</b> az alábbi okok miatt:
            <ul>
                <li>Játszási lista adatszolgáltatás késedelme <b>2022 év 1. és 2. negyedévére.</b></li>
                <li>Bevételi adatszolgáltatás késedelme <b>2022 év 2. negyedévére.</b></li>
                <li>Jogdíjtartozás <b>2022 év 2. negyedévére.</b></li>
            </ul>
        </Message>
        <p>
            Tekintse meg <NavLink to='/felhasznalo/reparadio/egyenleg'><a href='#'>egyenlegét</a></NavLink> a részletekért.
        </p>
        <p>
            <b>Az ön jogdíj számításával, megfizetésével kapcsolatos ügyekben kijelölt EJI/MAHASZ kapcsolattartója:</b>
        </p>
        <Grid columns={8}>
            <Grid.Column>Kapcsolattartó neve</Grid.Column>
            <Grid.Column><b>Homályos Géza</b></Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column>E-mail címe</Grid.Column>
            <Grid.Column><b>geza@eji.hu</b></Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column>Telefonszáma</Grid.Column>
            <Grid.Column><b>06 1 555 55555</b></Grid.Column>
        </Grid>
        <Divider />
        <center>
            <Button primary disabled>NMHH igazolás igénylése</Button>
            &nbsp;
            <Button primary disabled>MTVA igazolás igénylése</Button>
        </center>
        <h3><Icon name='history' />Igényelt igazolások</h3>
        <center style={{ color: '#aaa' }}>Nem igényelt igazolásokat.</center>
    </Segment>
};

export default Igazolasok;