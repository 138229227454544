import React, { useState, useEffect } from 'react';
import { Button, Header, Label, Icon, Dropdown, Popup, Grid, Segment, Divider, Form, Message, Tab, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import MainContent from './subcomponents/MainContent'

/**
 * Jatszasi lista adatlap.
 */
const FeltoltottJatszasiListaAdatlap = () => {

    // Wizard step
    const [step, setStep] = useState(1);

    // Visible list?
    const [isListVisible, setIsListVisible] = useState(true);
    
    // Tab
    const [activeIndex, setActiveIndex] = useState(1);

    // Possible statuses
    const statuszok = [
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
    ]

    const panes = [
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='yellow' basic={ activeIndex !== 1 }>Feldolgozás alatt</Label>&nbsp; Répa Rádió</span></Menu.Item>, render: () => <Tab.Pane><MainContent step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> },
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='yellow' basic={ activeIndex !== 2 }>Feldolgozás alatt</Label>&nbsp; Kukorica Jancsi Rádió</span></Menu.Item>, render: () => <Tab.Pane><MainContent step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> },
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='red' basic={ activeIndex !== 3 }>Hiányzó lista</Label>&nbsp; Djent Metal Radió</span></Menu.Item>, render: () => <Tab.Pane><MainContent  step={ step } setStep={ setStep } activeIndex={ activeIndex } /></Tab.Pane> }
    ]

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='yellow'>
            <b><Icon name='check' />Feldolgozás alatti</b> játszási lista Zöldség kft. részére a <b>2022 / 1.</b> negyedévre sugárzási jogdíjra.
        </Segment>  
        <Grid columns={5} style={{ paddingTop: '24px' }}>
            <Grid.Row>
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='yellow' style={{ marginTop: '-8px' }}>
                        <Button disabled compact>Feldolgozás alatt</Button>
                        <Dropdown
                            disabled
                            className='button icon'
                            floating
                            onChange={() => { alert('Mockup módban nem elérhető. Az "Elfogadott" státusz kizárólag az utolsó lépésben lesz állítható.') }}
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Feltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <b>Zöldség kft.</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Adminisztrátor
                </Grid.Column>
                <Grid.Column>
                    <b>Nagy János</b>
                    <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='exchange' /></Button>} content='Adminisztrátor cseréje' />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó playlist azonosító
                </Grid.Column>
                <Grid.Column>
                    <b>1</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Kapcsolattartó
                </Grid.Column>
                <Grid.Column>
                    <b>Kovács Rozmaringné</b>
                    <Button.Group>
                        <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
                        <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={ <span>Telefon: <b>06 1 555 5555</b></span> } />
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>            
        </Grid>
        <Segment>
            A <b>3</b> játszási listából <b>2</b> feldolgozás alatt áll, <b>1</b> hiányzik. A feltöltés elfogadásához <b>3</b> elfogadott állapotú játszási lista szükséges.
        </Segment>
        <Tab onTabChange={ (e, data) => setActiveIndex(data.activeIndex + 1) } panes={panes} />
        <Divider />
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'/> Játszási lista letöltése</Button>
        <NavLink to='/jatszasilistak/naplo'><Button primary icon><Icon name='history'/></Button></NavLink>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='upload'/> Játszási lista visszatöltése</Button>        
        <NavLink to='/jatszasilistak'><Button floated='right'>Vissza</Button></NavLink>
        <NavLink to='/jatszasilistak/naplo'><Button floated='right' icon><Icon name='history' /> Napló</Button></NavLink>
        <br />
        <br />
    </Segment>

};

export default FeltoltottJatszasiListaAdatlap;