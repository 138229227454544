import React, { useState } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Segment, Table, Icon, Dropdown, Input, Button, Popup, Label, Grid, Divider, Form, Message } from 'semantic-ui-react';
import KedvezmenyekComponent from '../../jatszasilistak/adatszolgaltatas/subcomponents/KedvezmenyekComponent';
import TevekenysegekComponent from '../../jatszasilistak/adatszolgaltatas/subcomponents/TevekenysegekComponent';

/**
 * Lots of data from the user.
 */
const DijszamitasiComponent = () => {

    return <Segment basic>
        <h3><Icon name='bars' /> Díjszámítási adatok feltöltése</h3>
        <Segment>
            <h3>Bevételi adatok</h3>
            <Grid>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <b>Kereskedelmi bevételek (Ft)</b>
                    </Grid.Column>
                    <Grid.Column>
                        <Input style={{ marginTop: '-12px' }} value='0' />
                        &nbsp; <Popup content='Súgó szöveg az adott mezőről.' trigger={ <Icon name='help circle' color='blue'/> } />
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        ebből kábeltévés (Ft)
                    </Grid.Column>
                    <Grid.Column>
                        <Input style={{ marginTop: '-12px', width: '100px' }} value='0' />
                        &nbsp; <Popup content='Súgó szöveg az adott mezőről.' trigger={ <Icon name='help circle' color='blue'/> } />
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={4} >
                    <Grid.Column>
                        <b>Közszolgálati hozzájárulás (Ft)</b>
                    </Grid.Column>
                    <Grid.Column>
                        <Input style={{ marginTop: '-12px' }} value='0' />
                        &nbsp; <Popup content='Súgó szöveg az adott mezőről.' trigger={ <Icon name='help circle' color='blue'/> } />
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} >
                    <Grid.Column>
                        <b>Egyéb támogatás (Ft)</b>
                    </Grid.Column>
                    <Grid.Column>
                        <Input style={{ marginTop: '-12px' }} value='0' />
                        &nbsp; <Popup content='Súgó szöveg az adott mezőről.' trigger={ <Icon name='help circle' color='blue'/> } />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <h3>Kedvezmények</h3>
            <Message info>
                <Icon name='info circle' />Kedvezményeket kizárólag hatályos szerződés mellett lehet igénybe venni. Kérjük, a szerződéshez kattinson a <NavLink to='/felhasznalo/reparadio/szerzodes'>Szerződés</NavLink> menüpontra.
            </Message>
            <KedvezmenyekComponent disabled />
            <h3>Sugárzási jogdíj</h3>
            <TevekenysegekComponent/>
            <h3>Kapcsoldó fájlok</h3>
            A szükséges fájlokat itt csatolhatja a díjszámítási adatok mellé.
            <Form>
                <Form.Group>
                    <Form.Input width={8} label='Dokumentum' />
                    <Form.Button onClick={ () => alert('Mockup módban nem elérhető.') } icon style={{ marginTop: '24px' }}><Icon name='folder open outline' /></Form.Button>
                </Form.Group>
                <Form.TextArea label='Megjegyzés' />
            </Form>            
        </Segment>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } primary>Mentés</Button>
        <NavLink to='/felhasznalo/reparadio/adatszolgaltatas'>
            <Button floated='right'>Vissza</Button>
        </NavLink>
    </Segment>
};

export default DijszamitasiComponent;