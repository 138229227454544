import React from 'react';
import { Icon, Dropdown, Segment, Form, Message } from 'semantic-ui-react';

/**
 * Felhasznalo penzugyi bellitasai adatlap.
 */
const PenzugyComponent = () => {

    const kamatok = [
        { key: 1, value: 1, text: 'Jegybanki alapkamat kétszerese (5,4%)' },
        { key: 2, value: 2, text: 'Jegybanki alapkamat (2,7%)' },
        { key: 3, value: 3, text: 'Ptk. szerint' },
        { key: 4, value: 4, text: 'Egyedi fix kamat' },
    ]

    const eloleg = [
        { key: 1, value: 1, text: 'Nem fizet' },
        { key: 2, value: 2, text: 'Általános szabályok szerint fizet' },
        { key: 3, value: 3, text: 'Biztosan fizet' },
        { key: 4, value: 4, text: 'Egyedi összeget fizet' },
    ]    

    const afas = [
        { key: 1, value: 1, text: 'Alapértelmezett (27%)' },
        { key: 1, value: 1, text: 'Egyedi' },
    ]    

    const beszamoloStatus = [
        { key: 1, value: 1, text: 'nem érkezett meg' },
        { key: 2, value: 2, text: 'ellenőrzésre vár' },
        { key: 3, value: 3, text: 'elfogadva' },
        { key: 4, value: 4, text: 'elutasítva' },
    ]

    return <>
        <h3 style={{ marginTop: '0px' }}>Fizetés</h3>
        <Form>
            <Form.Checkbox toggle label='Túlfizetés engedélyezése' />
            <Form.Checkbox toggle label='VIP felhasználó' />
            <Form.Dropdown defaultValue={1} width={5} selection label='Alapértelmezett kamatszámítás' options={ kamatok } />
            <Form.Dropdown defaultValue={1} width={5} selection label='ÁFA kulcs' options={ afas } />
        </Form>    
        <h3>Számviteli beszámolók</h3>
        <Segment>
            <Message info><Icon name='info circle'/>Nincsen megjeleníthető számviteli beszámoló.</Message>
            A <b>2022</b>.-es évi beszámoló <Dropdown selection options={ beszamoloStatus } defaultValue={1} />
        </Segment>      
        <h3 style={{ marginTop: '0px' }}>Határidők</h3>
        <Segment>
            <Form>
            <Form.Input width={8} defaultValue='20' label='Játszási lista feltöltési határideje, negyedévet követöen (nap)'/>
            <Form.Input width={8} defaultValue='20' label='Díjszámítási adatok feltöltési határideje, negyedévet követöen (nap)'/>
            <Form.Input width={8} defaultValue='15' label='Fizetés határideje, értesítö kiállítását követöen (nap)'/>
            <Form.Checkbox toggle label='Elözetes figyelmeztetö e-mail küldése'/>
            <Form.Checkbox toggle label='Figyelmeztetö e-mailek küldése'/>
            </Form>
        </Segment>  
        <h3 style={{ marginTop: '0px' }}>Előleg</h3>
        <Segment>
            <Form>
                <Form.Input width={8} defaultValue='0' label='Az általános szabályok szerint az éves előleg sszege (Ft)'/>
                <Form.Dropdown selection options={ eloleg } defaultValue={1} label='Előleg típusa' />
            </Form>
        </Segment>                
    </>
};

export default PenzugyComponent;