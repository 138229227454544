import React from 'react';
import { Button, Divider, Dropdown, Grid, Header, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

/**
 * Help
 */
const Sugo = () => {

    return <Segment basic>
        <h3><Icon name='help' /> Súgó</h3>
        <Segment>
            Itt a legégetőbb kérdésekre csak adunk válaszokat. A felhasználók nem szeretnének több tíz oldalas pdf kézikönyveket olvasni ha alapvető kérdésük van. Meg mondjuk amúgy sem:)
        </Segment>
    </Segment>
};

export default Sugo;