import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Segment, Table, Icon, Dropdown, Input, Button, Popup, Grid, Form, Divider, Label } from 'semantic-ui-react';

/**
 * (Re)upload a list.
 */
const NewFileUploadComponent = () => {

    return <Segment basic>
        <h3><Icon name='cloud upload' /> Játszási lista feltöltése</h3>
        <Segment>
            <Grid columns={5}>
                <Grid.Column>
                    Feltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.29</b> <Icon name='warning sign' color='red' />
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
                <Grid.Column>
                    Utolsó feltöltés időpontja
                </Grid.Column>
                <Grid.Column>
                    <b>-</b>
                </Grid.Column>
            </Grid>
            <Divider />
            <Form>
                <Form.Group>
                    <Form.Input width={8} label='Játszási lista' />
                    <Form.Button onClick={ () => alert('Mockup módban nem elérhető.') } icon style={{ marginTop: '24px' }}><Icon name='folder open outline' /></Form.Button>
                </Form.Group>
                <Form.TextArea label='Megjegyzés' />
            </Form>
            <Divider />
            <Button onClick={() => alert('Mockup módban nem elérhető.')} primary>Mentés</Button>
            <NavLink to='/felhasznalo/reparadio/adatszolgaltatas'><Button floated='right'>Vissza</Button></NavLink>
        </Segment>
    </Segment>

}

export default NewFileUploadComponent;
