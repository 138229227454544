import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Export settings.
 */
const ImportSettings = () => {

    return <Segment basic style={{ marginTop: '-12px' }}>
        <Form>
            <Form.Dropdown width={6} label='Export formátum' defaultValue='1' options={ [{ value: '1', key: '1', text: 'KulcsSoft'}, { key: '2', text: 'Revolution'}] } selection />
        </Form>
    </Segment>
}

export default ImportSettings;