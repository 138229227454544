import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Egyeb komponens.
 */
const DijszamitasiAdatokComponent = () => {

    return <Message warning>
         Nincs feltöltve kapcsolódó fájl.
        </Message>

};

export default DijszamitasiAdatokComponent;