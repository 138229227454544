import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';

/**
 * Global welcome page.
 */
const GlobalWelcome = () => {

    return <>
        <div style={{ zIndex: '1000', background: '#015EB8', padding: '2em 2em 2em 1em', gridColumn: '1 / 4', width: '100%' }}>
            <Header as='h2' inverted>
                <Icon name='headphones' /> Playlist Rebuild Mockup
            </Header>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid columns={5} stackable>
            <Grid.Row>
                <Grid.Column></Grid.Column>
                <Grid.Column>
                    <NavLink to='/jatszasilistak'>
                        <Segment placeholder>
                            <Header icon>
                                <a href='#'>
                                    <Icon name='user secret' />
                                    Jogdíj és játszási lista adminisztrátori felület
                                </a>
                            </Header>
                        </Segment>
                    </NavLink>
                </Grid.Column>
                <Grid.Column>
                    <NavLink to='/felhasznalo/reparadio/welcome'>
                        <Segment placeholder>
                            <Header icon>
                                <a href='#'>
                                    <Icon name='user' />
                                    Felhasználói felület
                                </a>
                            </Header>
                        </Segment>
                    </NavLink>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder onClick={() => alert('Jelenleg nem elérhető.')}>
                        <Header icon>
                            <a href='#'>
                                <Icon name='history' />
                                Verziótörténet
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
        </Grid>
    </>
};

export default GlobalWelcome;