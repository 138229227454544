import React, {  } from 'react';
import { Table, Label, Icon, Dropdown, Input, Form } from 'semantic-ui-react';

/**
 * Dijfizeto ertesito lista
 */
const DijfizetesiErtesitokComponent = ({ setErtesitok, setTetelek, tetelek }) => {

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];

    const stateOptions = [
        { key: 1, value: 1, text: '-' },
        { key: 2, value: 2, text: 'FMH' },
        { key: 3, value: 3, text: 'Peresítve' },
        { key: 4, value: 4, text: 'Felszámolás alatt' },
        { key: 5, value: 5, text: 'Nem érvényesíthető' },
        { key: 6, value: 6, text: 'Számszakilag leírt' },
        { key: 7, value: 7, text: 'Végrehajtás alatt' }
    ]

    const felszolitasokOptions = [
        { key: 1, value: 1, text: 'Adatpótlásra' },
        { key: 2, value: 2, text: 'Fizetésre' }
    ]

    const summarize = (item) => {
        const subTotal = document.getElementById(item).value;
        setErtesitok(parseInt(subTotal));
        document.getElementById(item).value = '';

        setTetelek([...tetelek,{ subTotal, name: item === 'e1' ? 'PL-EJI-2022-12' : item === 'e2' ? 'PL-EJI-2022-11' : 'PL-EJI-2022-04' }])
    }

    return <Form>
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Időszak / megnevezés</Table.HeaderCell>
                    <Table.HeaderCell>Összeg</Table.HeaderCell>
                    <Table.HeaderCell>Bizonylat</Table.HeaderCell>
                    <Table.HeaderCell>Állapota</Table.HeaderCell>
                    <Table.HeaderCell>Összeg</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating labeled defaultValue={'Mind'} selection options={idoszakokStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Összeg' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Bizonylat' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Mind' labeled selection fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Összeg' iconPosition='left' fluid />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Díjfizetési értesítő</Table.Cell>
                    <Table.Cell>500.000 Ft<br /><b>635.000 br.</b></Table.Cell>
                    <Table.Cell>PL-EJI-2022-12</Table.Cell>
                    <Table.Cell><Label>Nincs megfizetve</Label></Table.Cell>
                    <Table.Cell>
                        <Form.Group style={{ margin: '0px' }}>
                            <Form.Input id='e1' placeholder='0' type='number'></Form.Input>&nbsp;
                            <span style={{ paddingTop: '8px' }}>Ft</span>
                            <Form.Button primary onClick={(e) => { summarize('e1'); e.preventDefault() } } icon><Icon name='plus' /></Form.Button>
                        </Form.Group>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Díjfizetési értesítő</Table.Cell>
                    <Table.Cell>500.000 Ft<br /><b>635.000 br.</b></Table.Cell>
                    <Table.Cell>PL-EJI-2022-11</Table.Cell>
                    <Table.Cell><Label>Nincs megfizetve</Label></Table.Cell>
                    <Table.Cell>
                        <Form.Group style={{ margin: '0px' }}>
                            <Form.Input id='e2' placeholder='0' type='number'></Form.Input>&nbsp;
                            <span style={{ paddingTop: '8px' }}>Ft</span>
                            <Form.Button primary onClick={(e) => { summarize('e2'); e.preventDefault() } } icon><Icon name='plus' /></Form.Button>
                        </Form.Group>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Díjfizetési értesítő</Table.Cell>
                    <Table.Cell>500.000 Ft<br /><b>635.000 br.</b></Table.Cell>
                    <Table.Cell>PL-EJI-2022-04</Table.Cell>
                    <Table.Cell><Label>Nincs megfizetve</Label></Table.Cell>
                    <Table.Cell>
                        <Form.Group style={{ margin: '0px' }}>
                            <Form.Input id='e3' placeholder='0' type='number'></Form.Input>&nbsp;
                            <span style={{ paddingTop: '8px' }}>Ft</span>
                            <Form.Button primary onClick={(e) => { summarize('e3'); e.preventDefault() } } icon><Icon name='plus' /></Form.Button>
                        </Form.Group>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </Form>
}

export default DijfizetesiErtesitokComponent;