/**
 * Just some random data from a mock data server, spiced with random numbers
 * @param {*} state 
 * @param {*} action 
 */
const ProfileReducer = (state, action) => { 
    switch (action.type) {
        case 'SET_PROFILE_DATA':
            return {
                ...state,
                error: undefined,
                current: action.payload
            };
        case 'SET_PROFILE_ERROR':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default ProfileReducer;