import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Export settings.
 */
const BankSelectFile = ({setPage}) => {

    return <Segment basic style={{ marginTop: '-12px' }}>
        <Form>
            <Form.Group>
                <Form.Input width={8} label='Bankkivonat' value='20220512_9684723964.csv' />
                <Form.Button onClick={ () => alert('Mockup módban nem elérhető.') } icon style={{ marginTop: '24px' }}><Icon name='folder open outline' /></Form.Button>
            </Form.Group>
            <Grid columns={4}>
                <Grid.Column>
                    <Form.Dropdown width={8} label='Import formátum' defaultValue='1' options={ [{ value: '1', key: '1', text: 'OTP Electra'}, { key: '2', text: 'OTP UMS'}] } selection />
                </Grid.Column>
                <Grid.Column>
                    <Form.Input width={8} placeholder='ÉÉÉÉ.HH.NN' label='Befizetés napja' />                
                </Grid.Column>
                <Grid.Column/>
                <Grid.Column/>
            </Grid>
        </Form>
        <Divider/>
        <Button onClick={ () => setPage(1) } primary>Tovább</Button>
        <Button floated='right' onClick={ () => alert('Mockup módban nem elérhető.') }>Alapállapot</Button>
    </Segment>
}

export default BankSelectFile;