import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Divider, Dropdown, Grid, Header, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

/**
 * Download page.
 */
const Letoltes = () => {

    return <Segment basic>
        <h3><Icon name='download' />Letöltés</h3>
        <Grid columns={3} stackable>
            <Grid.Row>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Felhasználói kézikönyv
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Aktuális díjszabás
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Egységes rádiós játszási lista
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Játszási listák formátuma - gyakran idézett kérdések
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                Játszási lista (Excel sablon)
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment placeholder>
                        <Header icon>
                            <a href='#'>
                                <Icon name='book' />
                                PDI (Playlist Database Interface) - automatizált adatszolgáltatás
                            </a>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
};

export default Letoltes;