import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Szamla export lista history.
 */
const ExportHistory = () => {

    const idoszakokStatus = [
        {
            key: 'Mind',
            text: 'Mind',
            value: 'Mind'
        },
        {
            key: 'Egyedi',
            text: 'Egyedi',
            value: 'Egyedi'
        },
        {
            key: '2022 / 4',
            text: '2022 / 4',
            value: '2022 / 4'
        },
        {
            key: '2022 / 3',
            text: '2022 / 3',
            value: '2022 / 3'
        },
        {
            key: '2022 / 2',
            text: '2022 / 2',
            value: '2022 / 2'
        },
        {
            key: '2022 / 1',
            text: '2022 / 1',
            value: '2022 / 1'
        },
        {
            key: '2021 / 4',
            text: '2021 / 4',
            value: '2021 / 4'
        },
        {
            key: '2021 / 3',
            text: '2021 / 3',
            value: '2021 / 3'
        },
        {
            key: '2021 / 2',
            text: '2021 / 2',
            value: '2021 / 2'
        },
        {
            key: '2021 / 1',
            text: '2021 / 1',
            value: '2021 / 1'
        },
    ];

    return <div style={{ marginTop: '-14px' }}>
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Időszak / megnevezés</Table.HeaderCell>
                    <Table.HeaderCell>Bizonylat</Table.HeaderCell>
                    <Table.HeaderCell>Összeg</Table.HeaderCell>
                    <Table.HeaderCell>Könyvelt összeg</Table.HeaderCell>
                    <Table.HeaderCell>Állapota</Table.HeaderCell>
                    <Table.HeaderCell>Könyvelve</Table.HeaderCell>
                    <Table.HeaderCell>Megjegyzés</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating labeled defaultValue={'Mind'} selection options={idoszakokStatus} fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Bizonylat' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Bizonylat összeg' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Könyvelt összeg' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Mind' labeled selection fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Mind' labeled selection fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Megjegyzés' iconPosition='left' fluid />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row style={{ padding: '4px', background: '#eee' }}>
                    <Table.Cell>
                        <h4>2022. január 2.</h4>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                </Table.Row>
                <Table.Row positive>
                    <Table.Cell>
                        2022 / 2. negyedév
                    </Table.Cell>
                    <Table.Cell>
                        PL-EJI-2021-55
                    </Table.Cell>
                    <Table.Cell>
                        100.000 Ft + ÁFA<br />
                        <b>127.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        50.000 Ft + ÁFA<br />
                        <b>63.500 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        <Label color='yellow'>Részben megfizetett</Label>
                    </Table.Cell>
                    <Table.Cell>
                        2021.12.04
                    </Table.Cell>
                    <Table.Cell>
                        <b>KulcsSzámla Prémium</b>
                    </Table.Cell>
                </Table.Row>
                <Table.Row positive>
                    <Table.Cell>
                        2022 / 1. negyedév
                    </Table.Cell>
                    <Table.Cell>
                        PL-EJI-2021-41
                    </Table.Cell>
                    <Table.Cell>
                        100.000 Ft + ÁFA<br />
                        <b>127.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        100.000 Ft + ÁFA<br />
                        <b>127.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        <Label color='blue'>Megfizetett</Label>
                    </Table.Cell>
                    <Table.Cell>
                        2021.12.04
                    </Table.Cell>
                    <Table.Cell>
                        <b>KulcsSzámla Prémium</b>
                    </Table.Cell>
                </Table.Row>
                <Table.Row style={{ padding: '4px', background: '#eee' }}>
                    <Table.Cell>
                        <h4>2022. december 21.</h4>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                </Table.Row>
                <Table.Row positive>
                    <Table.Cell>
                        2021 / 4. negyedév
                    </Table.Cell>
                    <Table.Cell>
                        PL-EJI-2021-51 <Label>Előleg</Label>
                    </Table.Cell>
                    <Table.Cell>
                        1.000.000 Ft + ÁFA<br />
                        <b>1.270.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        1.000.000 Ft + ÁFA<br />
                        <b>1.270.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        <Label color='blue'>Megfizetett</Label>
                    </Table.Cell>
                    <Table.Cell>
                        2021.12.01
                    </Table.Cell>
                    <Table.Cell>
                        <b>KulcsSzámla Prémium</b>
                    </Table.Cell>
                </Table.Row>
                <Table.Row positive>
                    <Table.Cell>
                        2021 / 4. negyedév
                    </Table.Cell>
                    <Table.Cell>
                        PL-EJI-2021-33
                    </Table.Cell>
                    <Table.Cell>
                        100.000 Ft + ÁFA<br />
                        <b>127.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        100.000 Ft + ÁFA<br />
                        <b>127.000 Ft (br.)</b>
                    </Table.Cell>
                    <Table.Cell>
                        <Label color='blue'>Megfizetett</Label>
                    </Table.Cell>
                    <Table.Cell>
                        2021.12.01
                    </Table.Cell>
                    <Table.Cell>
                        <b>KulcsSzámla Prémium</b>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </div>
}

export default ExportHistory;