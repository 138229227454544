import React, {  } from 'react';
import { Button, Table, Icon, Popup, Message } from 'semantic-ui-react';

/**
 * Felhasznalo adminisztratorai.
 */
const AdminokComponent = () => {

    const adminok = [
        { role: 'Kapcsolattartó adminisztrátor', org: 'EJI', text: 'Nagyfalusi Emília', date: '2022.04.01 12:00:00' },
        { role: 'Játszási lista adminisztrátor', org: 'EJI', text: 'Békés Gergő', date: '2022.04.01 12:00:00' },
        { role: 'Játszási lista adminisztrátor', org: 'MAHASZ', text: 'Nagy Rozália', date: '2022.04.01 12:00:00' },
        { role: 'Jogdíjfizetési adminisztrátor', org: 'MAHASZ', text: 'Rettentes Ivánné Julika', date: '2022.04.01 12:00:00' },
    ]

    return <>
            <Table striped selectable>
                <Table.Header>
                    <Table.HeaderCell>
                        Adminisztártor neve
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Felhasználóhoz rendelve
                    </Table.HeaderCell>        
                    <Table.HeaderCell>
                        Szerepköre
                    </Table.HeaderCell>                                        
                    <Table.HeaderCell>
                        Szervezet
                    </Table.HeaderCell>                                                            
                    <Table.HeaderCell textAlign='right'>
                        Gyorsmenü
                    </Table.HeaderCell>                                                                                
                </Table.Header>
                <Table.Body>
                {
                    adminok.map( (admin, index) => <Table.Row key={ index }>
                        <Table.Cell>
                            <b>{ admin.text }</b>
                        </Table.Cell>
                        <Table.Cell>
                            { admin.date }
                        </Table.Cell>   
                        <Table.Cell>
                            { admin.role }
                        </Table.Cell>   
                        <Table.Cell>
                            { admin.org }
                        </Table.Cell>                                                                      
                        <Table.Cell textAlign='right'>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='blue'><Icon name='mail'/></Button> } content='E-mail küldés'/>
                            <Popup trigger={ <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } icon color='red'><Icon name='remove'/></Button> } content='Eltávolítás'/>
                        </Table.Cell>  
                    </Table.Row>)
                }
                </Table.Body>
            </Table>
            <Message info>
                <Icon name='info circle'/> Adminisztrátort a megfelelő szerepkörrel rendelkező adminisztártorok vehetnek fel illetve törölhetnek a felhasználó mellől.
            </Message>
            <Button onClick={ () => alert('Mockup módban nem elérhető.') } floated='right' primary><Icon name='user plus'/> Új adminisztrátor</Button>
            <br/>
            <br/>            
    </>
};

export default AdminokComponent;