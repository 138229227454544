import React, { useContext } from 'react';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import { Context } from '../store/Store';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

/**
 * Responsive navigation bar, look at 'Responsive' tags
 */
const Navigation = () => {

    // Global state, persistent (app lifecycle).
    // Using it to get profile information.
    const [state /*, dispatch*/] = useContext(Context);
    const location = useLocation();

    return (
            <Menu>
                <Menu.Item header as={NavLink} to='/globalwelcome'><Icon style={{ marginRight: '0px' }} size='big' name='sound' /></Menu.Item>
                <Menu secondary>
                    <Menu.Item>
                        <h3>Playlist Rebuild</h3>
                    </Menu.Item>
                </Menu>
                <Menu.Menu position='right'>
                    <Dropdown text={ <span>
                                        <b>{ state.profile.current.name }</b>
                                        { location.pathname.startsWith('/felhasznalo') ? <> | Felhasználó</> : <> | Jogkezelő adminisztrátor</> }
                                     </span> } style={{ padding: '18px'}}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={NavLink} to='/profile'>Profile</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Image style={{ height: '40px', width: '40px', marginTop: '8px', marginRight: '8px' }} alt='user profile' circular src='/steve.jpg'/>
                </Menu.Menu>
            </Menu>
            )
};

export default Navigation;