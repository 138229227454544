import React, { } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Divider, Segment, Grid, Dropdown, Button, Popup } from 'semantic-ui-react';

/**
 * Igazolas adatlap.
 */
const IgazolasAdatlap = () => {

    const statuszok = [
        {
            key: 'Elfogadásra vár',
            text: 'Elfogadásra vár',
            value: 'Elfogadásra vár'
        },
        {
            key: 'Elutasítva',
            text: 'Elutasítva',
            value: 'Elutasítva'
        },
        {
            key: 'Elfogadva',
            text: 'Elfogadva',
            value: 'Elfogadva'
        },
    ]

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='green'>
            <b><Icon name='check' />Elfogadott NMHH</b> igazolás igénylés.
        </Segment>
        <Grid columns={5} style={{ paddingTop: '24px' }}>
            <Grid.Row>
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='green' style={{ marginTop: '-8px' }}>
                        <Button compact>Elfogadva</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            onChange={() => { alert('Mockup módban nem elérhető.') }}
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Igénylés ideje
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <b>Zöldség kft.</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Csatorna
                </Grid.Column>
                <Grid.Column>
                    <b>Répa Rádió</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Példányszám
                </Grid.Column>
                <Grid.Column>
                    <b>1</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Típus
                </Grid.Column>
                <Grid.Column>
                    <b>NMHH</b>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Divider />
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'/> Igazolás sablon letöltése</Button>
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='upload'/> Aláírt igazolás feltöltése</Button>        
        <NavLink to='/igazolasok'><Button floated='right'>Vissza</Button></NavLink>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } floated='right' icon><Icon name='history' /> Napló</Button>
        <br />
        <br />        
    </Segment>

};

export default IgazolasAdatlap;