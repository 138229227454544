import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header, Message } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import BankTetelFelosztas from './BankTetelFelosztas'

/**
 * Bank import.
 */
const BankImport = ({ setPage }) => {

    const itemsStart = [
        {
            datum: '2022.05.22',
            osszeg: '100.000',
            partner: 'Kiskörei Tározó Rádió kft.',
            szamlaszam: '11055555-55555555-55555555',
            kozlemeny: 'PL-MAHASZ-034-2022',
            match: 'Közleményben szereplő értesítő száma alapján azonosítva',
            ertesito: 'PL-MAHASZ-034-2022'
        },
        {
            datum: '2022.05.21',
            osszeg: '15.300',
            partner: 'Zöldséges kft.',
            szamlaszam: '11255555-55555555-55555555',
            kozlemeny: 'utalok'
        },
        {
            datum: '2022.05.14',
            osszeg: '150.300',
            partner: 'Mókus Egyesület',
            szamlaszam: '13255555-55555555-55555555',
            kozlemeny: ''
        },
    ]

    const [showDetails, setShowDetails] = useState(-1);
    const [items, setItems] = useState(itemsStart);

    const removePinnedInvoice = () => {
    }

    const showOrHide = (index) => {
        if (index === showDetails)
        {
            setShowDetails(-1); 
            alert('Mockup módben nem történik mentés.');
        }
        else
        {
            setShowDetails(index)
        }
    }

    return <div style={{ marginTop: '-16px' }}>
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Státusz</Table.HeaderCell>
                    <Table.HeaderCell>Dátum</Table.HeaderCell>
                    <Table.HeaderCell>Összeg</Table.HeaderCell>
                    <Table.HeaderCell>Partner</Table.HeaderCell>
                    <Table.HeaderCell>Értesítő / jogcím</Table.HeaderCell>
                    <Table.HeaderCell>Partner bankszámlaszám</Table.HeaderCell>
                    <Table.HeaderCell>Közlemény</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Dátum' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Összeg' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Partner' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Input icon={'filter'} placeholder='Értesítő' iconPosition='left' fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Partner bankszámlaszám' labeled selection fluid />
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ padding: '4px', background: '#eee' }}>
                        <Dropdown floating placeholder='Közlemény' labeled selection fluid />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    items.map((item, index) => <>
                        <Table.Row positive={item.match !== undefined} active={ index === showDetails } >
                            <Table.Cell>{
                                index === showDetails
                                ?
                                <Icon name='eye'/>
                                :
                                item.match !== undefined
                                    ?
                                    <Popup trigger={<Icon name='check' color='green' />} content={item.match} />
                                    :
                                    ''
                            }</Table.Cell>
                            <Table.Cell>{item.datum}</Table.Cell>
                            <Table.Cell><b>{item.osszeg}</b></Table.Cell>
                            <Table.Cell>{item.partner}</Table.Cell>
                            <Table.Cell>{
                                item.ertesito === undefined
                                ?
                                index === showDetails
                                ?
                                <Label color='blue' onClick={ () => showOrHide(index) } as='a'><Icon name='check'/>Összepontozás mentése</Label>
                                :
                                <Label onClick={ () => index === showDetails ? setShowDetails(-1) : setShowDetails(index) } as='a'><Icon name='usb'/>Összepontozás</Label>
                                :
                                <>
                                    <Popup content='Összepontozott értesítők listájának módosítása' trigger={<Label as='a' color='green'><Icon name='remove' onClick={ () => removePinnedInvoice() } />{item.ertesito}</Label>} />
                                </>
                            }</Table.Cell>
                            <Table.Cell>{item.szamlaszam}</Table.Cell>
                            <Table.Cell>{item.kozlemeny}</Table.Cell>
                        </Table.Row>
                        {
                            showDetails === index
                            ?
                            <Table.Row style={{ background: '#efefef' }}>
                                <Table.Cell colSpan={7}>
                                    <BankTetelFelosztas total={ item.osszeg } />
                                </Table.Cell>
                            </Table.Row>
                            :
                            <></>
                        }
                    </>)
                }
            </Table.Body>
        </Table>
        <Segment basic style={{ marginTop: '-16px' }}>
            <Button onClick={() => setPage(2)} primary>Tovább</Button>
            &nbsp;
            <span style={{ color: '#aaa' }}>Minden tétel összepontozandó import előtt (3/1 kész).</span>
            <Button floated='right' onClick={() => setPage(0)}>Vissza</Button>
        </Segment>
    </div>
}

export default BankImport;