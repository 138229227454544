import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Menu, Segment, Tab, Grid, Divider, Checkbox } from 'semantic-ui-react';
import CimekComponent from '../../felhasznalok/subcomponents/cimekComponent';
import CsatolmanyokComponent from '../../felhasznalok/subcomponents/csatolmanyokComponent';
import AdminokComponent from '../../felhasznalok/subcomponents/adminokComponent'
import JatszasiHelyekComponent from './subcomponents/JatszasiHelyekComponent'
import FelhasznaloJogdijAdatlap from './subcomponents/felhasznaloJogdijAdatlap'

/**
 * Background music user datasheet.
 */
const AudiovizualisFelhasznaloAdatlap = () => {

    // Possible statuses
    const statuszok = [
        {
            key: 'Elfogadásra vár',
            text: 'Elfogadásra vár',
            value: 'Elfogadásra vár'
        },
        {
            key: 'Elutasított',
            text: 'Elutasított',
            value: 'Elutasított'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
    ]

    const options = [
        { key: 'clone', icon: 'user plus', text: 'Migráció', value: 'clone' },
        { key: 'suspend', icon: 'user delete', text: 'Felfüggesztés', value: 'suspend' },
        { key: 'remove', icon: 'user delete', text: 'Megszüntetés', value: 'remove' },
    ]

    const panes = [        
        {
            menuItem: <Menu.Item><Icon name='home' /> Címek & Kapcsolat</Menu.Item>,
            render: () => <CimekComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='bars' /> Jogdij</Menu.Item>,
            render: () => <FelhasznaloJogdijAdatlap />,
        },        
        {
            menuItem: <Menu.Item><Icon name='file alternate' /> Egyéb beállítás</Menu.Item>,
            render: () => <>
                <h3>Teljesítési határidők</h3>
                <Segment>
                    <Checkbox toggle label='Láthatók előre az adatszolgáltatási időszakok' />
                </Segment>
            </>,
        },
        {
            menuItem: <Menu.Item><Icon name='paperclip' /> Csatolmányok</Menu.Item>,
            render: () => <CsatolmanyokComponent />,
        },
        {
            menuItem: <Menu.Item><Icon name='users' /> Adminisztrátorok</Menu.Item>,
            render: () => <AdminokComponent />,
        },          
    ]       

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='yellow'>
            <b><Icon name='check' />Elfogadásra váró</b> felhasználói regisztráció.
        </Segment>
        <Grid style={{ paddingTop: '28px' }}>
            <Grid.Row style={{ marginBottom: '-8px' }}>
                <Grid.Column width={4}>
                    Státusz
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button.Group color='yellow' style={{ marginTop: '-8px' }} >
                        <Button compact>Elfogadásra vár</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={3}>
                    Elfogadás dátuma
                </Grid.Column>
                <Grid.Column width={4}>
                    -
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    Felhasználó neve
                </Grid.Column>
                <Grid.Column width={4}>
                    <Input defaultValue='Media Aport' style={{ marginTop: '-8px' }} />
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={3}>
                    Felhasználás kezdete
                </Grid.Column>
                <Grid.Column width={4}>
                    <b>2022.05.01</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    Felhasználó playlist azonosítója
                </Grid.Column>
                <Grid.Column width={4}>
                    <b>2101</b>
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={3}>
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: '-8px' }} >
                </Grid.Column>
            </Grid.Row>                        
        </Grid>        
        <Divider/>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } primary>Mentés</Button>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: '18px' }} />
        
        <NavLink to='/hatterzene/hatterzeneszolgaltatok'><Button floated='right'>Vissza</Button></NavLink>
    </Segment>

}

export default AudiovizualisFelhasznaloAdatlap;