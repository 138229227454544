import React, { useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Peldareport
 */
const ReportCsatornaComponent = () => {

    const [show, setShow] = useState(false);

    return <Segment basic style={{ height: '100%', background: '#eee' }}>
        <Segment basic>
            <h3><Icon name='gitter'/> Jelentés paraméterei</h3>
            <Segment style={{ width: '80%' }}>
                <Form>
                    <Form.Group>
                        <Form.Dropdown defaultValue={1} selection options={[{ key: 1, value: 1, text: 'TV' }]} label='Csatorna típusa' />
                        <Form.Dropdown defaultValue={1} selection options={[{ key: 1, value: 1, text: '2022' }]} label='Év' />
                        <Form.Input width={16} placeholder='Szöveg vagy szövegtöredék' label='Tartalmazza...' />
                        <Form.Button onClick={() => setShow(true)} style={{ marginTop: '24px' }} primary>Lekérdezés</Form.Button>
                    </Form.Group>
                </Form>
                <span style={{ color: '#aaa' }}>
                    Oszlopok: <i>Felhasználó, Csatorna, Típus</i>
                </span>
            </Segment>
            {
                show
                    ?
                    <>
                        <h3><Icon name='eye'/> Előnézet</h3>
                        <Segment style={{ width: '80%' }}>
                            <h3>Csatornák</h3>
                            <Grid>
                                <Grid.Column width={1}>
                                    Év:
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <b>2022</b>
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    Típus:
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <b>TV</b>
                                </Grid.Column>
                                <Grid.Column width={8} style={{ color: '#aaa' }} textAlign='right'>
                                    Lekérdezés ideje: <b>2022.06.04 12:00:54</b>
                                </Grid.Column>
                            </Grid>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Felhasználó</Table.HeaderCell>
                                        <Table.HeaderCell>Csatorna</Table.HeaderCell>
                                        <Table.HeaderCell>Típus</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Zöldség kft.</Table.Cell>
                                        <Table.Cell>Répa TV</Table.Cell>
                                        <Table.Cell>TV</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Zöldség kft.</Table.Cell>
                                        <Table.Cell>Kossuth Béla Televízió</Table.Cell>
                                        <Table.Cell>TV</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Haszontalan kft.</Table.Cell>
                                        <Table.Cell>Hasznos TV</Table.Cell>
                                        <Table.Cell>TV</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>...</Table.Cell>
                                        <Table.Cell>...</Table.Cell>
                                        <Table.Cell>...</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <center style={{ color: '#aaa' }}>Az első 50 tétel mutatása</center>
                            <br/>
                            <Button onClick={ () => alert('Mockup módban nem elérhető.') } color='green' icon><Icon name='file pdf outline'/> PDF export</Button>
                            <Button onClick={ () => alert('Mockup módban nem elérhető.') } color='green' icon><Icon name='file excel outline'/> Excel export</Button>
                        </Segment>
                    </>
                    :
                    ''
            }
        </Segment>
    </Segment>

};

export default ReportCsatornaComponent;