import React, { useState, useEffect } from 'react';
import { Button, Header, Label, Icon, Dropdown, Popup, Grid, Segment, Divider, Form, Card, Tab, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import FeltetlenElutasitas from './subcomponents/wizard/FeltetlenElutasitas'
import EllenorizendoElutasitas from './subcomponents/wizard/EllenorizendoElutasitas'
import EgyebElteres from './subcomponents/wizard/EgyebElteres'
import Statisztika from './subcomponents/wizard/Statisztika'
import JatszasiListaTartalom from './subcomponents/wizard/jatszasiListaTartalom'
import JatszasiListaKezdokepernyo from './subcomponents/wizard/JatszasiListaKezdokepernyo'
import JatszasiListaBefejezes from './subcomponents/wizard/JatszasiListaBefejezes'

/**
 * Elfogadott jatszasi lista adatlap.
 */
const FeltoltottJatszasiListaAdatlapElfogadott = () => {

    // Wizard step
    const [step, setStep] = useState(1);

    // Visible list?
    const [isListVisible, setIsListVisible] = useState(true);
    
    // Tab
    const [activeIndex, setActiveIndex] = useState(1);

    // Possible statuses
    const statuszok = [
        {
            key: 'Hibás',
            text: 'Hibás',
            value: 'Hibás'
        },
        {
            key: 'Feldolgozás alatt',
            text: 'Feldolgozás alatt',
            value: 'Feldolgozás alatt'
        },
        {
            key: 'Hiányzik',
            text: 'Hiányzik',
            value: 'Hiányzik'
        },
        {
            key: 'Elfogadott',
            text: 'Elfogadott',
            value: 'Elfogadott'
        },
    ]

    const MainContent = () => <>
        {
            !isListVisible
                ?
                <Segment placeholder>
                    <Header icon>
                        <Icon name='file outline' color='grey' />
                        A játszási lista hiányzik, értesítse a felhasználót a pótlás szükségességéről.
                    </Header>
                    <Button primary><Icon name='mail' /> Értesítő küldése</Button>
                </Segment>
                :
                <>
                    <Form>
                        <Form.Group>
                            <Form.Button onClick={() => setStep(step - 1)} disabled={step === 1} icon><Icon name='chevron left' /><b>Előző lépés</b></Form.Button>
                            <Label basic style={{ paddingTop: '12px' }}>
                                <Icon name='point' color='red' />
                                {
                                    step === 0
                                        ?
                                        'Kezdőképernyő'
                                        :
                                        step === 1
                                            ?
                                            'Játszási lista tartalmi, formai ellenőrzése'
                                            :
                                            step === 2
                                                ?
                                                'Feltétlen elutasítási okok'
                                                :
                                                step === 3
                                                    ?
                                                    'Ellenőrizendő elutasítási okok'
                                                    :
                                                    step === 4
                                                        ?
                                                        'Egyéb eltérés'
                                                        :
                                                        step === 5
                                                            ?
                                                            'Statisztika'
                                                            :
                                                            'Jóváhagyás vagy elutasítás'
                                }
                                {
                                    step > 0
                                        ?
                                        <>
                                            &nbsp; ({step} / 6)
                                        </>
                                        :
                                        ''
                                }
                            </Label>
                            <Form.Button disabled={step === 6} onClick={() => setStep(step + 1)} icon><b>Következő lépés</b><Icon name='chevron right' /></Form.Button>
                        </Form.Group>
                    </Form>
                    {
                        step === 0
                            ?
                            <JatszasiListaKezdokepernyo step={step} setStep={setStep} />
                            :
                            step === 1
                                ?
                                <JatszasiListaTartalom />
                                :
                                step === 2
                                    ?
                                    <FeltetlenElutasitas />
                                    :
                                    step === 3
                                        ?
                                        <EllenorizendoElutasitas />
                                        :
                                        step === 4
                                            ?
                                            <EgyebElteres />
                                            :
                                            step === 5
                                                ?
                                                <Statisztika />
                                                :
                                                <JatszasiListaBefejezes />
                    }
                </>
        }
    </>

    const panes = [
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='green' basic={ activeIndex !== 1 }>Elfogadott</Label>&nbsp; Répa Rádió</span></Menu.Item>, render: () => <Tab.Pane><MainContent/></Tab.Pane> },
        { menuItem: <Menu.Item><span style={{ color: '#000' }}><Label size='small' color='green' basic={ activeIndex !== 2 }>Elfogadott</Label>&nbsp; Kukorica Jancsi Rádió</span></Menu.Item>, render: () => <Tab.Pane><MainContent/></Tab.Pane> },
    ]

    return <Segment basic>
        <Segment style={{ margin: '-14px', borderRadius: '0px' }} inverted color='green'>
            <b><Icon name='check' />Elfogadott</b> játszási lista Zöldség kft. részére a <b>2022 / 1.</b> negyedévre sugárzási jogdíjra.
        </Segment>
        <Grid columns={5} style={{ marginTop: '24px' }}>
            <Grid.Row>
                <Grid.Column>
                    Státusz
                </Grid.Column>
                <Grid.Column>
                    <Button.Group color='green' style={{ marginTop: '-8px' }}>
                        <Button compact>Elfogadott</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            onChange={() => { alert('Mockup módban nem elérhető. Az "Elfogadott" státusz kizárólag az utolsó lépésben lesz állítható.') }}
                            options={statuszok}
                            trigger={<></>}
                        />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Feltöltési határidő
                </Grid.Column>
                <Grid.Column>
                    <b>2022.03.01.</b>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó
                </Grid.Column>
                <Grid.Column>
                    <b>Zöldség kft.</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Adminisztrátor
                </Grid.Column>
                <Grid.Column>
                    <b>Nagy János</b>
                    <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='exchange' /></Button>} content='Adminisztrátor cseréje' />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    Felhasználó playlist azonosító
                </Grid.Column>
                <Grid.Column>
                    <b>1</b>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                    Kapcsolattartó
                </Grid.Column>
                <Grid.Column>
                    <b>Kovács Rozmaringné</b>
                    <Button.Group>
                        <Popup trigger={<Button style={{ marginLeft: '8px', marginTop: '-8px' }} compact icon><Icon name='mail outline' /></Button>} content='E-mail a felhasználónak' />
                        <Popup trigger={<Button style={{ marginLeft: '4px', marginTop: '-8px' }} compact icon><Icon name='phone' /></Button>} content={ <span>Telefon: <b>06 1 555 5555</b></span> } />
                    </Button.Group>
                </Grid.Column>
            </Grid.Row>  
        </Grid>
        <Divider/>
        <Tab onTabChange={ (e, data) => setActiveIndex(data.activeIndex + 1) } panes={panes}  />
        <Divider />
        <Button primary onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='download'/> Játszási lista letöltése</Button>
        <NavLink to='/jatszasilistak/naplo'><Button primary icon><Icon name='history'/></Button></NavLink>
        <Button onClick={ () => alert('Mockup módban nem elérhető.') } icon><Icon name='upload'/> Játszási lista visszatöltése</Button>        
        <NavLink to='/jatszasilistak'><Button floated='right'>Vissza</Button></NavLink>
        <NavLink to='/jatszasilistak/naplo'><Button floated='right' icon><Icon name='history' /> Napló</Button></NavLink>
        <br />
        <br />
    </Segment>

};

export default FeltoltottJatszasiListaAdatlapElfogadott;