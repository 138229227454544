import React from 'react';
import { Button, Label, Icon, Segment, Header, Form, Message } from 'semantic-ui-react';
import FeltetlenElutasitas from './wizard/FeltetlenElutasitas'
import FeltetlenElutasitasHibas from './wizard/FeltetlenElutasitasHibas'
import EllenorizendoElutasitas from './wizard/EllenorizendoElutasitas'
import EgyebElteres from './wizard/EgyebElteres'
import Statisztika from './wizard/Statisztika'
import JatszasiListaTartalom from './wizard/jatszasiListaTartalom'
import JatszasiListaKezdokepernyo from './wizard/JatszasiListaKezdokepernyo'
import JatszasiListaBefejezes from './wizard/JatszasiListaBefejezes'

/**
 * Egyeb komponens.
 */
const MainContent = ({ activeIndex, step, setStep, error }) => {

    return <>
        {
            activeIndex > 2
                ?
                <Segment placeholder>
                    <Header icon>
                        <Icon name='file outline' color='grey' />
                        A játszási lista hiányzik, értesítse a felhasználót a pótlás szükségességéről.
                    </Header>
                    <Button primary><Icon name='mail' /> Értesítő küldése</Button>
                </Segment>
                :
                <>
                    {
                        error !== undefined
                        ?
                        <Message error>
                            <Icon name='warning sign'></Icon><b>Tartalmilag hibás Excel</b>. Kiadás év 56%-ban hiányzik.
                        </Message>
                        :
                        ''
                    }
                    <Form>
                        <Form.Group style={{ width: '100%' }}>
                            <Form.Button onClick={() => setStep(step - 1)} disabled={step === 1} icon><Icon name='chevron left' /><b>Előző lépés</b></Form.Button>
                            <Label basic style={{ paddingTop: '12px' }}>
                                <Icon name='point' color='red' />
                                {
                                    step === 0
                                        ?
                                        'Kezdőképernyő'
                                        :
                                        step === 1
                                            ?
                                            'Játszási lista tartalmi, formai ellenőrzése'
                                            :
                                            step === 2
                                                ?
                                                'Feltétlen elutasítási okok'
                                                :
                                                step === 3
                                                    ?
                                                    'Ellenőrizendő elutasítási okok'
                                                    :
                                                    step === 4
                                                        ?
                                                        'Egyéb eltérés'
                                                        :
                                                        step === 5
                                                            ?
                                                            'Statisztika'
                                                            :
                                                            'Jóváhagyás vagy elutasítás'
                                }
                                {
                                    step > 0
                                        ?
                                        <>
                                            &nbsp; ({step} / 6)
                                        </>
                                        :
                                        ''
                                }
                            </Label>
                            <Form.Button disabled={step === 6} onClick={() => setStep(step + 1)} icon><b>Következő lépés</b><Icon name='chevron right' /></Form.Button>
                        </Form.Group>
                    </Form>
                    {
                        error !== undefined && step === 2
                        ?
                        <FeltetlenElutasitasHibas />
                        :
                        step === 0
                            ?
                            <JatszasiListaKezdokepernyo step={step} setStep={setStep} />
                            :
                            step === 1
                                ?
                                <JatszasiListaTartalom error={ error } />
                                :
                                step === 2
                                    ?
                                    <FeltetlenElutasitas />
                                    :
                                    step === 3
                                        ?
                                        <EllenorizendoElutasitas />
                                        :
                                        step === 4
                                            ?
                                            <EgyebElteres />
                                            :
                                            step === 5
                                                ?
                                                <Statisztika />
                                                :
                                                <JatszasiListaBefejezes />
                    }
                </>
        }
    </>
}

export default MainContent;