import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Message, Segment } from 'semantic-ui-react';
import SmartSearchComponent from './components/smartsearch/smartSearchComponent'
import Navigation from './components/Navigation';
import Sidebar from './components/Sidebar'
import Layout from './lib/components/Layout'
import AdatszolgaltatasLista from './components/jatszasilistak/adatszolgaltatas/adatszolgaltatasLista'
import FeltoltottJatszasiListaAdatlap from './components/jatszasilistak/adatszolgaltatas/feltoltottJatszasiListaAdatlap'
import FeltoltottJatszasiListaAdatlapAutomataHibas from './components/jatszasilistak/adatszolgaltatas/feltoltottJatszasiListaAdatlapAutomataHibas'
import DijszamitasiAdatok from './components/jatszasilistak/adatszolgaltatas/dijszamitasiAdatok'
import LekerdezesLista from './components/lekerdezesek/lekerdezesLista'
import FolyoszamlaAdatlap from './components/jatszasilistak/folyoszamla/folyoszamlaAdatlap'
import FelhasznaloLista from './components/felhasznalok/felhasznalokLista';
import FelhasznaloAdatlap from './components/felhasznalok/felhasznaloAdatlap'
import FelhasznaloAdatlapElfogadott from './components/felhasznalok/felhasznaloAdatlapElfogadott'
import FelhasznaloTermination from './components/felhasznalok/felhasznaloTermination'
import FelhasznaloSuccession from './components/felhasznalok/felhasznaloSuccession'
import FelhasznaloSuspend from './components/felhasznalok/felhasznaloSuspend'
import UjIdoszakComponent from './components/felhasznalok/ujIdoszakComponent'
import FelhasznaloiAdatszolgaltatas from './components/felhasznalo/adatszolgaltatas/FelhasznaloiAdatszolgaltatas'
import Welcome from './components/felhasznalo/welcome';
import FelhasznaloProfil from './components/felhasznalo/felhasznaloProfil/felhasznaloProfil'
import Kapcsolattartok from './components/felhasznalo/kapcsolattartok/kapcsolattartok'
import Letoltes from './components/felhasznalo/letoltes/letoltes';
import Egyenleg from './components/felhasznalo/egyenleg/egyenleg';
import Igazolasok from './components/felhasznalo/igazolasok/igazolasok';
import Uzenetek from './components/felhasznalo/uzenetek/uzenetek';
import Szerzodes from './components/felhasznalo/szerzodes/szerzodes';
import Sugo from './components/felhasznalo/sugo/sugo';
import IgazolasListaComponent from './components/igazolasok/IgazolasListaComponent'
import DownloadList from './components/download/downloadList';
import AdatszolgaltatasNaploComponent from './components/jatszasilistak/adatszolgaltatas/subcomponents/AdatszolgaltasNaploComponent';
import NewFileUploadComponent from './components/felhasznalo/adatszolgaltatas/NewFileUploadComponent';
import FileUploadComponent from './components/felhasznalo/adatszolgaltatas/FileUploadComponent';
import HatterzeneAdatszolgaltatasLista from './components/hatterzene/adatszolgaltatas/HatterzeneAdatszolgaltatasLista'
import HatterzeneFelhasznaloLista from './components/hatterzene/felhasznalok/HatterzeneFelhasznaloLista'
import HatterzeneFelhasznaloAdatlap from './components/hatterzene/felhasznalok/HatterzeneFelhasznaloAdatlap'
import HatterzeneSzolgaltatoLista from './components/hatterzene/hatterzeneszolgaltatok/HatterzeneSzolgaltatoLista'
import HatterzeneSzolgaltatoAdatlap from './components/hatterzene/hatterzeneszolgaltatok/HatterzeneSzolgaltatoAdatlap'
import HatterzeneAdatszolgaltatasAdatlap from './components/hatterzene/adatszolgaltatas/HatterzeneAdatszolgaltatasAdatlap'
import FeltoltottJatszasiListaAdatlapElfogadott from './components/jatszasilistak/adatszolgaltatas/feltoltottJatszasiListaAdatlapElfogadott'
import IsmetlesiFelhasznaloLista from './components/ismetlesi/felhasznalok/IsmetlesiFelhasznaloLista'
import IsmetlesiAdatszolgaltatasLista from './components/ismetlesi/adatszolgaltatas/IsmetlesiAdatszolgaltatasLista'
import IsmetlesiFelhasznaloAdatlap from './components/ismetlesi/felhasznalok/IsmetlesiFelhasznaloAdatlap'
import IsmetlesiAdatszolgaltatasAdatlap from './components/ismetlesi/adatszolgaltatas/IsmetlesiAdatszolgaltatasAdatlap'
import IsmetlesiFolyoszamlaAdatlap from './components/ismetlesi/folyoszamla/ismetlesiFolyoszamlaAdatlap'
import UjIdoszakIsmetlesiComponent from './components/ismetlesi/felhasznalok/ujIdoszakIsmetlesiComponent'
import DijszamitasiComponent from './components/felhasznalo/adatszolgaltatas/DijszamitasiComponent';
import SzamlaExportComponent from './components/szamlazas/SzamlaExportComponent'
import BankImportComponent from './components/szamlazas/BankImportComponent'
import GlobalWelcome from './components/globalwelcome/globalwelcome'
import { useLocation } from 'react-router-dom';
import ReportCsatornaComponent from './components/lekerdezesek/ReportCsatornaComponent'
import IgazolasAdatlap from './components/igazolasok/igazolasAdatlap'
import ErtesitoKeresesComponent from './components/ertesitokereses/Ertesitokereses'
import AudiovizualisFelhasznaloLista from './components/audiovizualis/felhasznalok/AudiovizualisFelhasznaloLista'
import AudiovizualisAdatszolgaltatasLista from './components/audiovizualis/adatszolgaltatas/AudiovizualisAdatszolgaltatasLista'
import AudiovizualisFelhasznaloAdatlap from './components/audiovizualis/felhasznalok/AudiovizualisFelhasznaloAdatlap'
import AudiovizualisAdatszolgaltatasAdatlap from './components/audiovizualis/adatszolgaltatas/AudiovizualisAdatszolgaltatasAdatlap'
// HOT RELOAD https://www.npmjs.com/package/craco-plugin-react-hot-reload

const InnerApp = () => {

    const location = useLocation();

    return <>
    <Switch>
        <Route path='/globalwelcome' exact>
            <GlobalWelcome />
        </Route>
    </Switch>
    {
            location.pathname.includes('globalwelcome')
            ?
            ''
            :
            <>
                <Navigation />
                <Layout>
                    <Layout.Header />
                    <Layout.Menu>
                        <Sidebar />
                    </Layout.Menu>
                    <Layout.MainArea>
                        <Switch>
                            <Route path='/ertesitokeresese' exact component={ErtesitoKeresesComponent}/>
                            <Route path='/szamlazas/export' exact component={SzamlaExportComponent} />
                            <Route path='/szamlazas/import' exact component={BankImportComponent} />
                            <Route path='/felhasznalo/reparadio/sugo' exact component={Sugo} />
                            <Route path='/felhasznalo/reparadio/szerzodes' exact component={Szerzodes} />
                            <Route path='/felhasznalo/reparadio/egyenleg' exact component={Egyenleg} />
                            <Route path='/felhasznalo/reparadio/uzenetek' exact component={Uzenetek} />
                            <Route path='/felhasznalo/reparadio/letoltes' exact component={Letoltes} />
                            <Route path='/felhasznalo/reparadio/igazolasok' exact component={Igazolasok} />
                            <Route path='/felhasznalo/reparadio/profil' exact component={FelhasznaloProfil} />
                            <Route path='/felhasznalo/reparadio/kapcsolattartok' exact component={Kapcsolattartok} />
                            <Route path='/felhasznalo/reparadio/welcome' exact component={Welcome} />
                            <Route path='/felhasznalo/reparadio/adatszolgaltatas' exact component={FelhasznaloiAdatszolgaltatas} />
                            <Route path='/felhasznalo/reparadio/ujfeltoltes' exact component={NewFileUploadComponent} />
                            <Route path='/felhasznalo/reparadio/feltoltes' exact component={FileUploadComponent} />
                            <Route path='/felhasznalo/reparadio/dijszamitasiadatok' exact component={DijszamitasiComponent} />
                            <Route path='/ismetlesi/idoszakok' exact component={IsmetlesiAdatszolgaltatasLista} />
                            <Route path='/ismetlesi/felhasznalok' exact component={IsmetlesiFelhasznaloLista} />
                            <Route path='/ismetlesi/felhasznalok/1' exact component={IsmetlesiFelhasznaloAdatlap} />
                            <Route path='/ismetlesi/felhasznalok/1/idoszak' exact component={UjIdoszakIsmetlesiComponent} />
                            <Route path='/ismetlesi/jatszasilistaadatlap' exact component={IsmetlesiAdatszolgaltatasAdatlap} />
                            <Route path='/ismetlesi/folyoszamla' exact component={IsmetlesiFolyoszamlaAdatlap} />
                            <Route path='/audiovizualis/felhasznalok' exact component={AudiovizualisFelhasznaloLista} />
                            <Route path='/audiovizualis/felhasznalok/1' exact component={AudiovizualisFelhasznaloAdatlap} />
                            <Route path='/audiovizualis/idoszakok' exact component={AudiovizualisAdatszolgaltatasLista} />
                            <Route path='/audiovizualis/jatszasilistak/1' exact component={AudiovizualisAdatszolgaltatasAdatlap} />
                            <Route path='/hatterzene/felhasznalok' exact component={HatterzeneFelhasznaloLista} />
                            <Route path='/hatterzene/jatszasilistak' exact component={HatterzeneAdatszolgaltatasLista} />
                            <Route path='/hatterzene/jatszasilistak/1' exact component={HatterzeneAdatszolgaltatasAdatlap} />
                            <Route path='/hatterzene/felhasznalok' exact component={HatterzeneFelhasznaloLista} />
                            <Route path='/hatterzene/felhasznalok/1' exact component={HatterzeneFelhasznaloAdatlap} />
                            <Route path='/hatterzene/hatterzeneszolgaltatok' exact component={HatterzeneSzolgaltatoLista} />
                            <Route path='/hatterzene/hatterzeneszolgaltatok/1' exact component={HatterzeneSzolgaltatoAdatlap} />
                            <Route path='/igazolasok' exact component={IgazolasListaComponent} />
                            <Route path='/letoltes' exact component={DownloadList} />
                            <Route path='/sugarzasi/felhasznalok' exact component={FelhasznaloLista} />
                            <Route path='/sugarzasi/felhasznalok/1/idoszak' exact component={UjIdoszakComponent} />
                            <Route path='/sugarzasi/felhasznalok/1/termination' exact component={FelhasznaloTermination} />
                            <Route path='/sugarzasi/felhasznalok/1/suspend' exact component={FelhasznaloSuspend} />
                            <Route path='/sugarzasi/felhasznalok/1/succession' exact component={FelhasznaloSuccession} />
                            <Route path='/sugarzasi/felhasznalok/1' exact component={FelhasznaloAdatlap} />
                            <Route path='/sugarzasi/felhasznalok/1/elfogadott' exact component={FelhasznaloAdatlapElfogadott} />
                            <Route path='/smartSearch' exact component={SmartSearchComponent} />
                            <Route path='/lekerdezesek' exact component={LekerdezesLista} />
                            <Route path='/jatszasilistak' exact component={AdatszolgaltatasLista} />
                            <Route path='/jatszasilistak/folyoszamla' exact component={FolyoszamlaAdatlap} />
                            <Route path='/jatszasilistak/naplo' exact component={AdatszolgaltatasNaploComponent} />
                            <Route path='/jatszasilistak/jatszasilistaadatlap' component={FeltoltottJatszasiListaAdatlap} />
                            <Route path='/jatszasilistak/dijszamitasiAdatok' component={DijszamitasiAdatok} />
                            <Route path='/jatszasilistak/jatszasilistaadatlapAutomataHibas' component={FeltoltottJatszasiListaAdatlapAutomataHibas} />
                            <Route path='/jatszasilistak/jatszasilistaadatlapElfogadott' component={FeltoltottJatszasiListaAdatlapElfogadott} />
                            <Route path='/lekerdezesek/csatornak' component={ReportCsatornaComponent} />
                            <Route path='/igazolasok/1' component={IgazolasAdatlap} />
                            <Route render={() => <Segment basic><Message warning>Ooops, page not found</Message></Segment>} />
                        </Switch>
                    </Layout.MainArea>
                </Layout>
            </>
    }
</>
}

export default InnerApp;
