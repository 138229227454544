import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Divider, Dropdown, Grid, Icon, Input, Label, Message, Segment, Statistic, Table } from 'semantic-ui-react';
import ChartistGraph from 'react-chartist';

/**
 * Welcome page.
 */
const Welcome = () => {

    var data = {
        labels: ['2020/1', '2020/2', '2020/3', '2020/4', '2021/1', '2021/2', '2021/3', '2021/4', '2022/1', '2022/2'],
        series: [
            [120000, 210000, 140000, 180000, 120000, 210000, 140000, 180000, 160000, 210000, 240000]
        ]
    };

    var options = {
        high: 250000,
        low: 0,
        showLabels: true,
        axisX: {
            labelInterpolationFnc: function (value, index) {
                return index % 2 === 0 ? value : null;
            }
        }
    };

    const [isPaid, setPaid] = useState(false);

    var type = 'Bar'

    const customCSS = `
        .ct-series-a .ct-bar {
            stroke-width: 24px;
            stroke: #2285D0;
        }
    `

    return <Segment basic>
        <style>
            {customCSS}
        </style>
        <Grid stackable>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <h2><Icon name='mail outline' /> Üzenetek</h2>
                    <Segment color='yellow' tertiary inverted style={{ borderLeft: '3px solid #000' }}>
                        <b style={{ color: '#000' }}>
                            <Icon name='handshake outline' /> Nem rendelkezik szerződéssel
                        </b><span style={{ color: '#000' }}>. A kedvezmények igénybevétele érdekében <u><NavLink style={{ color: '#000' }} to='/felhasznalo/reparadio/szerzodes'>kösse meg most</NavLink></u>.</span>
                    </Segment>
                    <Segment style={{ borderLeft: '3px solid #2185D0' }}>
                        <p>
                            <Icon name='mail outline' /><b>Jogdíjfizetési határidő közeleg</b>
                            <br />
                            <br />
                            Az ön következő sugárzási jogdíjfizetési határideje <b>2022. 05. 22</b>. Jogdíjfizetési értesítőjét letöltheti <a href='#' onClick={ () => alert('Mockup módban nem elérhető.') }>innen</a>.
                        </p>
                        <a href='#' onClick={() => alert('Mockup módban nem elérhető az olvasott státuszra állítás.')}><Icon name='eye slash outline' /></a>
                        <b><Icon name='clock outline' /> 2022.04.21</b>
                    </Segment>
                    <Segment style={{ borderLeft: '3px solid #2185D0' }}>
                        <p>
                            <Icon name='mail outline' /><b>Teljesen érdektelen közlemény</b>
                            <br />
                            <br />
                            It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.
                        </p>
                        <a href='#' onClick={() => alert('Mockup módban nem elérhető az olvasott státuszra állítás.')}><Icon name='eye slash outline' /></a>
                        <b><Icon name='clock outline' /> 2022.04.13</b>
                    </Segment>
                    <NavLink to='/felhasznalo/reparadio/uzenetek'>
                        <a href='#'>Üzenetek <Icon name='arrow right'></Icon></a>
                    </NavLink>
                </Grid.Column>
                <Grid.Column>
                    <Segment>
                        <h4><Icon name='bars' />Egyenleg</h4>
                        {
                            isPaid
                            ?
                        <Grid columns={2}>
                            <Grid.Column width={4}>
                                <Statistic horizontal>
                                    <Statistic.Value>0</Statistic.Value>
                                    <Statistic.Label>Ft</Statistic.Label>
                                </Statistic>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={12}>
                                <div style={{ whiteSpace: 'nowrap', paddingTop: '8px' }}>
                                    <Label color='green'>Nincsen tartozása</Label>
                                    <Label color='blue'>Minden értesítő kiegyenlítve</Label>
                                </div>
                            </Grid.Column>                            
                        </Grid>
                        :
                        <Grid columns={2}>
                            <Grid.Column width={4}>
                                <Statistic horizontal color='red'>
                                    <Statistic.Value>-25.000</Statistic.Value>
                                    <Statistic.Label>Ft</Statistic.Label>
                                </Statistic>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={12}>
                                <div style={{ whiteSpace: 'nowrap', paddingTop: '8px' }}>
                                    <Button onClick={ () => setPaid(true) } primary><Icon name='credit card'/> Fizetés</Button>
                                </div>
                            </Grid.Column>                            
                        </Grid>
                        }
                    </Segment>
                    <Segment>
                        <h4><Icon name='newspaper outline' /> Adatszolgáltatás</h4>
                        <center>
                            <span style={{ color: '#aaa' }}>
                                Hatályos adatszolgáltatási kötelezettségének eleget tett <b>2022.05.03</b>-án.
                            </span>
                        </center>
                    </Segment>
                    <Segment>
                        <h4><Icon name='id card outline' /> Kapcsolat</h4>
                        <p>
                            Az EJI/MAHASZ közös, játszási listákat kezelő oldalát nyitotta meg. Az ön számára kijelölt <b>általános kapcsolattartó</b>:
                        </p>
                        <p>
                            <Grid columns='3'>
                                <Grid.Column>
                                    Empatikus Jolán
                                </Grid.Column>
                                <Grid.Column>
                                    <a href='tel:+36 1 555 5555'>+36 1 555 5555</a>
                                </Grid.Column>
                                <Grid.Column>
                                    <a href='mailto:#'>empatikus.jolán@eji.hu</a>
                                </Grid.Column>
                            </Grid>
                        </p>
                        <p>
                            <b>Kapcsolattartója jogdíj ügybekben:</b>
                        </p>
                        <p>
                            <Grid columns='3'>
                                <Grid.Column>
                                    S. Stalloné
                                </Grid.Column>
                                <Grid.Column>
                                    <a href='tel:+36 1 555 5555'>+36 1 555 5555</a>
                                </Grid.Column>
                                <Grid.Column>
                                    <a href='mailto:#'>s.stallone@eji.hu</a>
                                </Grid.Column>
                            </Grid>                            
                        </p>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
};

export default Welcome;