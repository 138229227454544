import React, { useContext, useState } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Kesedelmi kamat adatlap.
 */
const KesedelmiKamatAdatlap = () => {

    const kamatok = [
        { key: 1, value: 1, text: 'Jegybanki alapkamat kétszerese (5,4%)' },
        { key: 2, value: 2, text: 'Jegybanki alapkamat (2,7%)' },
        { key: 3, value: 3, text: 'Ptk. szerint' },
        { key: 4, value: 4, text: 'Egyedi fix kamat' },
    ]

    const [egyedi, setEgyedi] = useState(false);
    const [szamitasMutatas, setSzamitasMutatas] = useState(false);

    return <Segment>
        <Grid columns={'equal'}>
            <Grid.Column>
                <Form>
                    <Form.Checkbox radio checked label='Kamat számítása'></Form.Checkbox>
                    <Form.Input width={6} placeholder='0' label='Összeg (Ft)' min={0} value='100000' />
                    <Form.Group>
                        <Form.Dropdown options={kamatok} width={10} defaultValue={1} label='Kamat' selection></Form.Dropdown>
                        <span style={{ color: '#aaa', paddingTop: '32px' }}>
                            <Icon name='clock outline' /> 2022.03.12
                        </span>
                    </Form.Group>
                </Form>
            </Grid.Column>
            <Grid.Column>
                <Form>
                    <Form.Checkbox radio checked={false} label='Kamat megadása'></Form.Checkbox>
                    <Form.Input width={4} disabled placeholder='0' label='Kamat' min={0} />
                </Form>
            </Grid.Column>
        </Grid>
        <Divider />
        <Grid columns={'equal'}>
            <Grid.Column>
                <Form>
                    <Form.Input width={6} label='Kamatszámítás kezdete' defaultValue='2022.04.01'></Form.Input>
                </Form>
            </Grid.Column>
            <Grid.Column>
                <Form>
                    <Form.Input width={6} label='Kamatszámítás vége' defaultValue='2022.05.15'></Form.Input>
                </Form>
            </Grid.Column>
        </Grid>
        <Divider />
        <Button onClick={() => setSzamitasMutatas(true)} secondary><Icon name='calculator' />Számítás</Button>
        <Divider />
        {
            !szamitasMutatas
                ?
                ''
                :
                <>
                    <Form>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Kamatszámítási időszak</label>
                                    2022.04.01 - 2022.05.15
                                </Form.Field>
                                <Form.Field>
                                    <label>Napok száma</label>
                                    45 nap
                                </Form.Field>
                                <Form.Field>
                                    <label>Késedelmi kamat</label>
                                    <h2 style={{ marginTop: '-0px' }}>443 Ft</h2>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Kamatszámítás alapja</label>
                                    100.000 Ft
                                </Form.Field>
                                <Form.Field>
                                    <label>Kamat mértéke</label>
                                    Jegybanki alapkamat kétszerese (5,4%)
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                    </Form>
                    <Divider />
                    <Form>
                        <Form.Group>
                            <Form.Checkbox onChange={() => setEgyedi(!egyedi)} toggle label='Értesítő egyedi tárgya' />
                            <Form.Input width={12} style={{ marginTop: '-12px' }} value='Késedelmi kamat értesítő' disabled={!egyedi} />
                        </Form.Group>
                        <Form.TextArea label='Megjegyzés' floated></Form.TextArea>

                    </Form>
                    <Divider />
                </>
        }
        <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } disabled={ !szamitasMutatas } primary>Értesítő kiállítása</Button>
        <Button onClick={ () => alert('Mockup verzióban nem elérhető.') } disabled={ !szamitasMutatas } icon floated='right'><Icon name='file outline' /> Előnézet</Button>
    </Segment>
}

export default KesedelmiKamatAdatlap;