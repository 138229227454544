import React, {  } from 'react';
import { Segment, Form } from 'semantic-ui-react';

/**
 * Felhasznalo cimei es kapcsolattartoi adatlap.
 */
const CimekComponent = () => {

    return <>
        <h3 style={{ marginTop: '0px' }}>Ügyféloldali kapcsolattartó</h3>
        <Segment>
            <Form widths='equal'>
                <Form.Group>
                    <Form.Input label='Név' placeholder='Kapcsolattartó neve' defaultValue='Kis Rozália' />
                    <Form.Input label='Telefonszám' placeholder='Kapcsolattartó telefonszáma' defaultValue='+36 30 55555555' />
                    <Form.Input label='E-mail' placeholder='Kapcsolattartó e-mail címe' defaultValue='rozi@ot.hu' />
                </Form.Group>
            </Form>
        </Segment>
        <h3 style={{ marginTop: '0px' }}>Címe</h3>
        <Segment>
            <Form fluid>
                <Form.Group>
                    <Form.Select
                        width='5'
                        fluid
                        label='Ország'
                        defaultValue={1}
                        search
                        options={[{ key: 1, value: 1, text: 'Magyarország' }, { key: 2, value: 2, text: 'Ausztria' }, { key: 3, value: 3, text: '...' } ]}
                        placeholder='Ország'
                    />
                    <Form.Input width='2' fluid label='Iránytószám' placeholder='' type='number' defaultValue='1201' />
                    <Form.Input width='9' fluid label='Település' placeholder='Település' defaultValue='Budapest' />
                </Form.Group>
                <Form.Group>
                    <Form.Input width='16' label='Utca, házszám' placeholder='Utca, házszám' defaultValue='Kenyér utca 2.' />
                </Form.Group>
                <Form.Field>
                    <label>Felhasználó adószáma</label>
                    <Form.Group>
                        <Form.Input width={3} placeholder='0000000' defaultValue='555555' />
                        <Form.Input width={1} placeholder='0' defaultValue='5' />
                        <Form.Input width={1} placeholder='00' defaultValue='55' />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Felhasználó bankszámlaszáma</label>
                    <Form.Group>
                        <Form.Input width={3} placeholder='00000000' defaultValue='55555555'/>
                        <Form.Input width={3} placeholder='00000000' defaultValue='55555555'/>
                        <Form.Input width={3} placeholder='00000000' defaultValue='55555555'/>
                    </Form.Group>
                </Form.Field>
            </Form>
        </Segment>
        <h3 style={{ marginTop: '0px' }}>Postacíme</h3>
        <Segment>
            <Form fluid>
                <Form.Group>
                    <Form.Select
                        width='5'
                        fluid
                        label='Ország'
                        defaultValue={1}
                        options={[{ key: 1, value: 1, text: 'Magyarország' }]}
                        placeholder='Ország'
                    />
                    <Form.Input width='2' fluid label='Iránytószám' placeholder='' type='number' defaultValue='1201' />
                    <Form.Input width='9' fluid label='Település' placeholder='Település' defaultValue='Budapest' />
                </Form.Group>
                <Form.Group>
                    <Form.Input width='16' label='Utca, házszám' placeholder='Utca, házszám' defaultValue='Kenyér utca 2.' />
                </Form.Group>
            </Form>
        </Segment>
    </>
};

export default CimekComponent;