import React, { useState } from 'react';
import { Message, Button, Checkbox, Divider, Form, Grid, Icon, Label, Segment, Table } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Uj idoszakok.
 */
const UjIdoszakComponent = () => {

    const list = [
        { idoszak: '2023/Q1', adat: true, bevetel: true, folyoszamla: true, osszevonva: '', status: '' },
        { idoszak: '2022/Q4', adat: true, bevetel: true, folyoszamla: true, osszevonva: '', status: '' },
        { idoszak: '2022/Q3', adat: true, bevetel: true, folyoszamla: true, osszevonva: '', status: '' },
        { idoszak: '2022/Q2', adat: true, bevetel: true, folyoszamla: true, osszevonva: '', status: '' },        
        { idoszak: '2022/Q1', adat: true, bevetel: true, folyoszamla: true, osszevonva: '', status: '' },
        { idoszak: '2021/Q4', adat: true, bevetel: true, folyoszamla: false, osszevonva: '', status: '' },
        { idoszak: '2021/Q3', adat: true, bevetel: true, folyoszamla: true, osszevonva: '', status: 'Érkezett játszási lista' },
        { idoszak: '2021/Q2', adat: true, bevetel: true, folyoszamla: true, osszevonva: '2021/Q1', status: '' },
        { idoszak: '2021/Q1', adat: false, bevetel: false, folyoszamla: false, osszevonva: '', status: '' }
    ];

    return <Segment basic>
        <h3>Adatszolgáltatási időszak</h3>
        <Segment>
            <Form>
                <Grid columns='2'>
                    <Grid.Column>
                        <h4>Jogdíj</h4>
                        <span style={{ paddingTop: '12px' }}>Sugárzási jogdíj</span>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Dropdown selection value={1} width={10} label='Adaszolgáltatási időszakok létrehozásának kezdete' options={[{ key: 1, value: 1, text: '2020' }, { key: 2, value: 2, text: '2021' }, { key: 3, value: 3, text: '2022' }]} />
                    </Grid.Column>
                </Grid>
                <Divider />
                <Form.Button primary>Hozzáadás</Form.Button>
            </Form>
        </Segment>
        <h3>Időszakok láthatósága</h3>
        <Segment>
            <Table striped selectable>
                <Table.Header>
                    <Table.HeaderCell>Időszak</Table.HeaderCell>
                    <Table.HeaderCell>Játszási lista</Table.HeaderCell>
                    <Table.HeaderCell>Díjszámítási adatok</Table.HeaderCell>
                    <Table.HeaderCell>Folyószámla</Table.HeaderCell>
                    <Table.HeaderCell>Összevonva</Table.HeaderCell>
                    <Table.HeaderCell>Állapot</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {
                        list.map((item, index) => <Table.Row>
                            <Table.Cell><b>{item.idoszak}</b></Table.Cell>
                            <Table.Cell>
                                <Checkbox toggle defaultChecked={item.adat} />
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox toggle defaultChecked={item.bevetel} />
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox toggle defaultChecked={item.folyoszamla} />
                            </Table.Cell>
                            <Table.Cell>{item.osszevonva}</Table.Cell>
                            <Table.Cell>
                                {
                                    item.status !== ''
                                        ?
                                        <Label color='yellow' compact><Icon name='warning sign' />{item.status}</Label>
                                        :
                                        ''
                                }
                            </Table.Cell>
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
            <NavLink to='/ismetlesi/felhasznalok/1'>
                <Button onClick={() => alert('Mockup verzióban nem elérhető.')} icon primary>Mentés</Button>
            </NavLink>
            <NavLink to='/ismetlesi/felhasznalok/1'>
                <Button floated='right'>Vissza</Button>
            </NavLink>
        </Segment>
        <Message info>
            <Icon name='info circle' />Felhasználó számára adott negyedév akkor jelenik meg, ha legalább az egyik adatszolgáltatás látható az adott évben.
        </Message>
    </Segment>

};

export default UjIdoszakComponent;