import React, { useContext, useState } from 'react';
import { Button, Table, Form, Label, Icon, Dropdown, Input, Popup, Segment, Tab, Grid, Divider, Checkbox, Header, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import WannabeInvoicesList from './subcomponents/WannabeInvoicesList'
import ExportImportSettings from './subcomponents/ExportSettings'
import ExportHistory from './subcomponents/ExportHistory'
/**
 * Szamla export komponens.
 */
const SzamlaExportComponent = () => {

    const panes = [
        {
            menuItem: <Menu.Item><Icon name='angle double right' /> Export</Menu.Item>,
            render: () => <WannabeInvoicesList />,
        },
        {
            menuItem: <Menu.Item><Icon name='history' /> Napló</Menu.Item>,
            render: () => <ExportHistory/>
        },        
        {
            menuItem: <Menu.Item><Icon name='cogs' /> Beállítások</Menu.Item>,
            render: () => <ExportImportSettings/>
        }
    ] 

    return <>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: '0px' }} />
    </>
};

export default SzamlaExportComponent;