import React, { useContext } from 'react';
import { Button, Table, Menu, Label, Icon, Dropdown, Input, Popup, Grid, Segment, Header, Message, Divider, Tab, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

/**
 * Naplo komponens.
 */
const NaploComponent = () => {

    return <Table striped selectable>
    <Table.Header>
        <Table.HeaderCell>Adminisztrátor/felhasználó</Table.HeaderCell>
        <Table.HeaderCell>Megjegyzés</Table.HeaderCell>
        <Table.HeaderCell>Dátum</Table.HeaderCell>
        <Table.HeaderCell>Státusz</Table.HeaderCell>
    </Table.Header>
    <Table.Body>
        <Table.Row>
            <Table.Cell>
                Kovácsné Lajos
            </Table.Cell>
            <Table.Cell style={{ color: '#888' }}>
                
            </Table.Cell>
            <Table.Cell>
                2022.03.12 12:44:01
            </Table.Cell>
            <Table.Cell>
                <Label basic color='green'>Díjszámítási adatok rögzítése</Label>
            </Table.Cell>
        </Table.Row>
    </Table.Body>
</Table>

};

export default NaploComponent;